import { ComponentsLandingProps } from "pages/landing-page/model";
import style from "../../style/landing-page.module.scss";
import Logo from "components/logo/fcdt-logo";
import LogoText from "assets/logo-text.webp";
import Title from "components/fcdt-title/fcdt-title";
import BanerAbout from "assets/baner_about.webp";
import Button from "components/buttons/fcdt-button";
import { Link as ScrollLink } from "react-scroll";

const About = (props: ComponentsLandingProps) => {
  return (
    <div id={props.id} className={style.container_about}>
      <div className={style.container_content_about}>
        <Logo urlImae={LogoText} width={300} />
        <Title width="35" className={style.title}>
          Optimiza tu tiempo y potencia la productividad de tus reuniones con{" "}
          <strong>Rykwa</strong>
        </Title>

        <img src={BanerAbout} width="60%" alt="" />

        <ScrollLink
          to="section5"
          smooth={true}
          duration={500}
          className={style.link_to}
        >
          <Button
            width="100"
            className={`fcdt_btn_secondary ${style.button_start}`}
            fontWeight={700}
          >
            Inicia hoy
          </Button>
        </ScrollLink>
      </div>
    </div>
  );
};

export default About;
