import { useState } from "react";
import { FCDTToogleProps } from "./model";
import style from "./style/fcdt-toggle.module.scss";
import { Stack, Switch, Typography } from "@mui/material";

const FCDTToggle = (props: FCDTToogleProps) => {
  const { handleChange, width, height } = props;
  const { title, titleActive, active = true } = props.data;

  const sizeCircle = height && height - 4;

  const [isActive, setisActive] = useState(active);

  const handleChangeToggle = (e: any) => {
    setisActive(!isActive);
    handleChange(e);
  };
  return (<div>
    {/* <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Typography sx={{ fontSize: "20px", fontFamily: 'Poppins', color: !isActive ? '#2F0084' : '#333333' }}>Sin revisar</Typography>
      <Switch
        sx={{
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#00DE97',
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#00DE97',
          },
        }}
        checked={isActive}
        onChange={(e) => handleChangeToggle(e)} name="antoine" />
      <Typography sx={{ fontSize: "20px", fontFamily: 'Poppins', color: !isActive ? '#333333' : '#00DE97' }}>Revisados</Typography>
    </Stack> */}
    <div
      className={`${style.toggle_container} ${props.className}`}
      style={{ height, width }}
    >
      <input
        type="checkbox"
        id="btn"
        checked={isActive}
        onChange={(e) => handleChangeToggle(e)}
      />

      <label htmlFor="btn" style={{ height: sizeCircle, width: sizeCircle }}>
        <span className={`${isActive && style.active}`}>
          {isActive ? titleActive : title}
        </span>
      </label>
    </div>
  </div>
  );
};

export default FCDTToggle;
