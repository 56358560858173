import React from "react";
import FilterContainer from "components/filter/filter-container";
import style from "./styles/document.module.scss";
import { TableDocumentsProps } from "pages/document-management/model";
import { IoCreateOutline, IoEyeOutline } from "react-icons/io5";
import FCDTToggle from "components/fcdy-toggle/fcdt-toggle";
import { Link } from "react-router-dom";
import Title from "components/fcdt-title/fcdt-title";

const Documents = (props: TableDocumentsProps) => {
  const { data, dataToggle, handleChangeToggle, handleOpenModal } = props;
  let data_ = [...data];
  let datalist = data_.reverse();
  return (
    <section className={style.container_document_table}>
      <div className={`${style.controls_document_table} fcdt-flex-box`}>
        {/* <div className={style.toggle_document_table}> */}
          <FCDTToggle
            data={dataToggle}
            handleChange={handleChangeToggle}
            width={150}
            height={40}
          />
        {/* </div> */}
        <FilterContainer category={"acta"} />
      </div>
      <Title fontSize={18} fontWeight={600} className={style.title_table}>
        {`Lista de actas ${!dataToggle.active ? "sin revisar" : "revisadas"}`}
      </Title>

      <div className={style.content}>
        <table>
          <thead>
            <tr>
              <th>Nombre de reunión asociada al discurso</th>
              <th>Fecha del evento</th>
              <th className={style.th_level_action}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {datalist.map((event) => (
              <tr key={event.id}>
                <td>
                  <div>{event.document_name}</div>
                </td>
                <td>
                  <div> {event.document_date}</div>
                </td>

                <td>
                  <IoEyeOutline
                    className={`fcdt-icon fcdt-gray ${style.td_icon_edit}`}
                    onClick={() => handleOpenModal(1, event.uid)}
                  />
                  <Link
                    className="fcdt-link-to"
                    to={`${event.uid}/acciones-recomendadas/${event.id_process_goal}/${event.id_meeting}`}
                  >
                    <IoCreateOutline className="fcdt-icon fcdt-gray" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Documents;
