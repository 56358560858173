export const ROUTES = {
  LANDING_PAGE: "/",
  HOME: "/home",
  REGISTER_COMPANY: "/registro/empresa",
  REGISTER: "/registro/empresa/:id_company",
  PLANS: "/:id_company/planes",
  PAYMENT: "/:id_company/planes/:id_plan/pago",
  STATUS_PAYMENT: "/license/payment/:id_payment",
  LOGIN: "/login",
  AUTH: "/usuarios/verify_token/:token",
  PASSWORDRESET: "/api/usuarios/password_recovery/:token",
  APP: "/app",
  DASHBOARD: {
    HOME: "inicio",
    PLAN_MANAGER: "gestor_planes",
    NOTIFICATION: "notificaciones",
    USERS: "usuarios",
    PROCESS: "procesos",
    INDICATORS: "indicadores",
    COMPANIES: "empresas",
    PAYMENTS: "pagos",
    ACCOUNTS: "cuentas",
    DATES_FOLLOWUP: "fechas-seguimiento",
    KNOWLEDGE: "base-conocimiento",
    FOLLOWUP: "seguimiento",
  },
  ADMINISTRATOR: "/administrador/*",
  COLLABORATOR: {
    PARENT: "/usuario/*",
    CHILDRENS: {
      GOALS: "/:id_process/metas",
      TAKS: "/:id_goal/tareas",
    },
  },
  SUPER_ADMINISTRATOR: "/super_administrador/*",
  DOCUMENT_MANAGEMENT: {
    REF: "gestion-documental",
    PARENT: "gestion-documental/*",
    CHILDRENS: {
      DOCUMENTS: "actas",
      RECOMMENDED_TASKS:
        "actas/:id_minute/acciones-recomendadas/:id_process_goal/:id_meeting",
      DOCUMENTS_EDIT:
        "actas/:id_minute/acciones-recomendadas/:id_process_goal/:id_meeting/editar",
      SPEECH_TREE: "arbol-discurso",
      SPEECH_TREE_ID: "arbol-discurso/:id_tree",
      PROCESS_REPORT: "informes/procesos",
      GOALS: "informes/procesos/:id_process/metas",
      REPORT: "informes/procesos/:id_process/metas/:id_goal/informe",
    },
  },
  CALENDAR: {
    PARENT: "calendario/*",
    CHILDRENS: {
      EVENTS: "eventos",
      CREATE_EVENT: "crear-evento",
      EDIT_EVENT: "editar-evento/:id_meeting",
    },
  },
};
