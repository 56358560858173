import axios from "axios";
// import { APIURL, PLANS_PORT } from "config/APIURL";
import { APIURL } from "config/APIURL";

import { FormNewPlan, FormNewPlanFunction } from "components/modals/model";

export const getPlans = async (token: string) => {
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "GET",
      url: `${APIURL}/api/planes`,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getSpecificPlan = async (id: number, token: string) => {
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "GET",
      url: `${APIURL}/api/planes/${id}`,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
  }
};
export const createPlan = async (form: FormNewPlan, token: string) => {
    const data = JSON.stringify({
    plan_name: form.plan_name,
    plan_price: form.plan_price.toString(),
    plan_description: form.plan_description,
    functionalities: form.functionalitiesPlan,
    credit_quantities: form.credit_quantities,
  });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      url: `${APIURL}/api/planes`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
export const updatePlan = async (
  id_plan: any,
  form: FormNewPlan,
  token: string
) => {
  const data = JSON.stringify({
    plan_name: form.plan_name,
    plan_price: `${form.plan_price}`,
    plan_description: form.plan_description,
    functionalities: form.functionalitiesPlan,
    credit_quantities: form.credit_quantities,
  });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "PUT",
      url: `${APIURL}/api/planes/${id_plan}`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getSpecificFuncionality = async (
  id_funcionality: any,
  token: string
) => {
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "GET",
      url: `${APIURL}/api/functionalities/${id_funcionality}`,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
export const getFunctionalities = async (token: string) => {
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "GET",
      url: `${APIURL}/api/functionalities`,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const createfunctionality = async (
  form: FormNewPlanFunction,
  token: string
) => {
  const data = JSON.stringify({
    functionality_name: form.functionality_name,
    functionality_description: form.functionality_description,
  });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      url: `${APIURL}/api/functionalities`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const updateFunctionality = async (
  form: FormNewPlanFunction,
  token: string
) => {
  const data = JSON.stringify({
    functionality_name: form.functionality_name,
    functionality_description: form.functionality_description,
  });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "PUT",
      url: `${APIURL}/api/functionalities/${form.functionalities[0]}`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const deleteFunctionality = async (
  form: FormNewPlanFunction,
  token: string
) => {
  const data =
    form.functionalities.length === 24
      ? JSON.stringify({ functionalities: [form.functionalities] })
      : JSON.stringify({ functionalities: form.functionalities });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "DELETE",
      url: `${APIURL}/api/functionalities`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getGoals = async (token: any) => {
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "GET",
      url: `${APIURL}:8033/api/planes`,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
