import FCDTModal from "components/modal-notification/fcdt-modal";
import React from "react";
import style from "./style/modal-view-document.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { IoDownloadOutline } from "react-icons/io5";
import Title from "components/fcdt-title/fcdt-title";
import Button from "components/buttons/fcdt-button";
import { ModalReportProps } from "../../model";
import GanttChartContainer from "../gantt-chart/gantt-chart-container";

const ModalReport = (props: ModalReportProps) => {
  const { openModal, handleCloseModal, dataReport } = props;

  const children = (
    <div className={style.container_principal}>
      <Paragraph className={style.paragrahp_download}>
        <div className={style.div}>
          <IoDownloadOutline size={25} /> Descargar
        </div>
      </Paragraph>

      <Title fontSize={25} fontWeight={700}>
      Reporte consolidado
      </Title>

      <section className={style.section_dashed}>
        <div className={style.container_flex}>
          <Title fontSize={17}>Actividad:</Title>
          <Paragraph>{dataReport.header.goal_title}</Paragraph>
        </div>
      </section>

      <section className={style.section_dashed}>
        <Title fontSize={17}>Periodo de consulta:</Title>
        <div className={`${style.container_flex} ${style.container_gap_dates}`}>
          <div>
            <Title fontSize={16} fontWeight={300}>
              Inicio
            </Title>
            <Paragraph fontWeight={300}>{dataReport.start_date}</Paragraph>
          </div>
          <div>
            <Title fontSize={16} fontWeight={300}>
              Finalización
            </Title>
            <Paragraph fontWeight={300}>{dataReport.end_date}</Paragraph>
          </div>
        </div>
      </section>

      <section className={style.section_dashed}>
        <Title fontSize={17}>Descripción de la actividad:</Title>
        <Paragraph fontWeight={300}>
          {dataReport.header.goal_description}
        </Paragraph>
      </section>

      <section className={style.section_dashed}>
        <Title fontSize={17}>Información sobre reuniones:</Title>
        <Title fontSize={14} fontWeight={300}>
          (Durante el periodo de interes)
        </Title>
        <Paragraph fontWeight={300}>
          {dataReport.info_from_minutes.content}
        </Paragraph>
      </section>

      <div className={style.container_gant_chart}>
        <Title fontSize={17}>Temas tratados:</Title>
        {dataReport.info_from_tasks.length > 0 ? (
          <div>
            <GanttChartContainer />
          </div>
        ) : (
          <Paragraph fontWeight={300}>
            No se encontraron temas tratados en el periodo seleccionado
          </Paragraph>
        )}
      </div>

      <section className={style.section_btn}>
        <Button
          className="fcdt_btn_without_background_primary"
          onClick={handleCloseModal}
        >
          Atrás
        </Button>
        <Button onClick={handleCloseModal}>Cerrar</Button>
      </section>
    </div>
  );

  return (
    <FCDTModal
      open={openModal}
      children={children}
      handleClose={handleCloseModal}
    />
  );
};

export default ModalReport;
