import React, { useState } from "react";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Title from "components/fcdt-title/fcdt-title";
import { IoHelpCircleSharp, IoTrashOutline } from "react-icons/io5";
import style from "./style/recommended-task.module.scss";
import IconAddTask from "assets/icons/icon_add_task.webp";
import IconAddSubTask from "assets/icons/icon_add_subtask.webp";
import Button from "components/buttons/fcdt-button";
import { RecommendedTaskProps } from "./model";
import { Link } from "react-router-dom";

const RecomendedTask = (props: RecommendedTaskProps) => {
  const { handleNavigate, handleClickService, taskRecomended } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipText, setShowTooltipText] = useState(false);
  const [showTooltipText1, setShowTooltipText1] = useState(false);
  const [showTooltipText2, setShowTooltipText2] = useState(false);
  const [showTooltipText3, setShowTooltipText3] = useState(false);
  return (
    <div className={style.container_principal}>
      <div style={{ padding: '5px 1px', }}>
        <Title fontSize={20} fontWeight={600}>
          Acciones recomendadas
          <IoHelpCircleSharp
            className={style.icon_help}
            onMouseEnter={() => { setShowTooltip(true); setShowTooltipText(true); }}
            size={45}
          />
        </Title>
      </div>

      <div style={{ position: 'relative', display: 'inline-block' }} onMouseLeave={() => {
        setShowTooltip(false);
        setShowTooltipText1(false);
        setShowTooltipText2(false);
        setShowTooltipText3(false);
      }}>
        {showTooltip && (
          <div
            style={{
              position: showTooltipText1 || showTooltipText2 || showTooltipText3 ? 'absolute' : 'inherit',
              top: '120%',
              width: showTooltipText1 || showTooltipText2 || showTooltipText3 ? '' : '80%',
              left: '50%',
              background: "#fff",
              transform: 'translateX(-50%)',
              padding: '5px 10px',
              color: '#2F0084',
              border: '1px solid #2F0084',
              borderRadius: '4px',
              whiteSpace: showTooltipText1 || showTooltipText2 || showTooltipText3 ? 'nowrap' : '',

            }}
          >
            <span style={{ fontSize: "16px" }}>
              {showTooltipText ? ' En esta sección puedes gestionar el acta seleccionando una acción: elimina acciones innecesarias, añade nuevas tareas principales o crea subtareas para organizar mejor la información.' :
                showTooltipText1 ? 'Puedes eliminar las acciones que no veas correspondiente' :
                  showTooltipText2 ? 'Puedes añadir una tarea nueva' :
                    showTooltipText3 ? 'Puedes añadir una subtarea nueva' :
                      ""}</span>
            <br />
          </div>
        )}
      </div>

      <div style={!showTooltip ? { display: "none" } : {}}>
      </div>

      <Paragraph onMouseEnter={() => { setShowTooltip(false); setShowTooltipText(false); }} className={style.paragrap_gray}>
        Te presentamos sugerencias de acciones extraídas de la reunión, elige una acción
      </Paragraph>

      <section className={style.section_titles}>
        <div className={style.container_action_selection}>
          <Title fontSize={18}>Sugerencias</Title>
        </div>
        <div
          className={`${style.container_icons} ${style.container_add_task_text}`}
        >
          <span className={style.span_title_icon}>Eliminar</span>
          <span className={style.span_title_icon}>Añadir tarea</span>
          <span className={style.span_title_icon}>Añadir subtarea</span>
        </div>
      </section>

      <section
        onMouseEnter={() => {
          setShowTooltip(false);
          setShowTooltipText(false);
          setShowTooltipText1(false);
          setShowTooltipText2(false);
          setShowTooltipText3(false);
        }} className={style.section_box}>
        {taskRecomended.map((item) => {
          return (
            <div className={style.container_content} key={item.id}>
              <div onMouseEnter={() => {
                setShowTooltip(false);
                setShowTooltipText(false);
                setShowTooltipText1(false);
                setShowTooltipText2(false);
                setShowTooltipText3(false);
              }}
                className={style.container_action_selection}>
                <li>{item.task_name}</li>
              </div>
              <div className={style.container_icons}>
                <IoTrashOutline
                  onMouseEnter={() => {
                    setShowTooltip(true);
                    setShowTooltipText1(true);
                    setShowTooltipText2(false);
                    setShowTooltipText3(false);
                  }}
                  size={30}
                  className={`${style.paragrap_gray} ${style.icon}`}
                  onClick={() => handleClickService(1, item.id)}
                />
                <img
                  onMouseEnter={() => {
                    setShowTooltip(true);
                    setShowTooltipText1(false);
                    setShowTooltipText2(true);
                    setShowTooltipText3(false);
                  }}
                  src={IconAddTask}
                  alt=""
                  className={style.icon}
                  onClick={() => handleClickService(2, item.id)}
                />
                <img
                  onMouseEnter={() => {
                    setShowTooltip(true);
                    setShowTooltipText1(false);
                    setShowTooltipText2(false);
                    setShowTooltipText3(true);
                  }}
                  src={IconAddSubTask}
                  alt=""
                  className={style.icon}
                  onClick={() => handleClickService(3, item.id)}
                />
              </div>
            </div>
          );
        })}
      </section>

      <section className={style.section_buttons}>
        <Button
          className="fcdt_btn_without_background_primary"
          type="button"
          onClick={() => handleNavigate(1)}
        >
          Atrás
        </Button>

        <Link className={`fcdt-link-to_white ${style.link}`} to={"editar"}>
          <Button type="submit">Siguiente</Button>
        </Link>
      </section>
    </div>
  );
};

export default RecomendedTask;
