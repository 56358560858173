import {
  IoNotificationsOutline,
  IoPeopleOutline,
  IoLayersOutline,
  IoCalendarNumberOutline,
  IoSpeedometerOutline,
  IoAlbumsOutline,
  IoDocumentOutline,
  IoEaselOutline,
  IoColorFilterOutline,
} from "react-icons/io5";
import { BiSearchAlt } from "react-icons/bi";

import { SideBarLink } from "components/sidebar/model";
import { ROUTES } from "routes/constants";
import { mappingRoute } from "helpers/formatRoute";

const { NOTIFICATION, USERS, PROCESS, KNOWLEDGE, FOLLOWUP } = ROUTES.DASHBOARD;
const { DOCUMENTS, SPEECH_TREE, PROCESS_REPORT } =
  ROUTES.DOCUMENT_MANAGEMENT.CHILDRENS;
const { EVENTS } = ROUTES.CALENDAR.CHILDRENS;

export const SIDEBAR_LINKS_COLABORATOR: SideBarLink[] = [
  {
    url: mappingRoute(ROUTES.COLLABORATOR.PARENT, NOTIFICATION),
    icon: IoNotificationsOutline,
    label: "Notificaciones",
    isDisabled: false,
  },
  {
    url: mappingRoute(ROUTES.COLLABORATOR.PARENT, USERS),
    icon: IoPeopleOutline,
    label: "Usuarios",
    isDisabled: false,
  },
  {
    url: mappingRoute(ROUTES.COLLABORATOR.PARENT, PROCESS),
    icon: IoLayersOutline,
    label: "Procesos",
    isDisabled: false,
  },
  {
    url: mappingRoute(
      ROUTES.COLLABORATOR.PARENT,
      mappingRoute(ROUTES.CALENDAR.PARENT, EVENTS)
    ),
    icon: IoCalendarNumberOutline,
    label: "Calendario",
    isDisabled: false,
  },

  {
    url: mappingRoute(ROUTES.COLLABORATOR.PARENT, FOLLOWUP),
    icon: IoSpeedometerOutline,
    label: "Seguimiento",
    isDisabled: false,
  },
  {
    url: "",
    icon: IoAlbumsOutline,
    label: "Documentación",
    isDisabled: false,
    isSelect: true,
    dataSelect: [
      {
        url: mappingRoute(
          ROUTES.COLLABORATOR.PARENT,
          mappingRoute(ROUTES.DOCUMENT_MANAGEMENT.PARENT, DOCUMENTS)
        ),
        icon: IoDocumentOutline,
        label: "Actas",
        isDisabled: false,
      },
      {
        url: mappingRoute(
          ROUTES.COLLABORATOR.PARENT,
          mappingRoute(ROUTES.DOCUMENT_MANAGEMENT.PARENT, SPEECH_TREE)
        ),
        icon: IoColorFilterOutline,
        label: "Árbol de discurso",
        isDisabled: false,
      },
      {
        url: mappingRoute(
          ROUTES.COLLABORATOR.PARENT,
          mappingRoute(ROUTES.DOCUMENT_MANAGEMENT.PARENT, PROCESS_REPORT)
        ),
        icon: IoEaselOutline,
        label: "Reportes",
        isDisabled: false,
      },
    ],
  },
  {
    url: mappingRoute(ROUTES.COLLABORATOR.PARENT, KNOWLEDGE),
    icon: BiSearchAlt,
    label: "Base de conocimiento",
    isDisabled: false,
  },
];

export const listOfFeatures = [
  { id: 1, text: "Generación de actas de reunión" },
  { id: 2, text: "Generación de árbol de discurso" },
  { id: 3, text: "Seguimiento de personas" },
  { id: 4, text: "Seguimiento de procesos" },
  { id: 5, text: "Modulo de gestión de tareas" },
  { id: 6, text: "Modulo de programación de eventos" },
  { id: 7, text: "Estimador de rendimiento grupal e individual" },
  { id: 8, text: "Análisis de sentimiento" },
  { id: 9, text: "Estimador de clima laboral" },
  { id: 10, text: "Generación de reportes trimestrales" },
  {
    id: 11,
    text: "Generador de dashboard ejecutivo con métricas de comportamiento y rendimiento organizacional",
  },
  { id: 12, text: "Diagnóstico de personas" },
  { id: 13, text: "Diagnóstico de procesos" },
  { id: 14, text: "Generador de recomendaciones organizacionales" },
  { id: 15, text: "Generador de recomendaciones colaboradores" },
];
