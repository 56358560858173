import style from "../../../../style/landing-page.module.scss";
import Logo from "components/logo/fcdt-logo";
import LogoText from "assets/logo-text.webp";
import Title from "components/fcdt-title/fcdt-title";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Button from "components/buttons/fcdt-button";
import { useDispatch } from "react-redux";
import {
  getDemoInformation,
  setIsModalSpeechTree,
  setIsModalViewMinute,
} from "store/slices/landingPage";
import toast from "react-hot-toast";

const DemoGenerate = () => {
  const dispatch: any = useDispatch();

  // const [isrecapcha, setisrecapcha] = useState<string>("");

  const handleGenerateDemo = async (idService: number) => {
    // if (isrecapcha.length === 0) {
    //   toast.error("Complete el recapcha");
    //   return;
    // }
    const res = await dispatch(getDemoInformation());
    if (!res.data) {
      toast.error("Error al generar información");
      return;
    }

    // dispatch(setIdRenderDemo(1));

    if (idService === 1) {
      dispatch(setIsModalViewMinute(true));
    } else {
      dispatch(setIsModalSpeechTree(true));
    }
  };

  return (
    <div className={style.container_demo_start_generated}>
      <Logo urlImae={LogoText} />

      <section className={style.section_input}>
        <Title fontSize={20} fontWeight={700}>
          Generación actas y árbol
        </Title>

        <Paragraph className={style.paragraph_demo_generated} fontSize={15}>
          Te invitamos a generar el acta de reunión y el árbol de dicurso
        </Paragraph>

        <section className={style.section_buttons}>
          <Button
            className={`fcdt_btn_secondary ${style.button}`}
            onClick={() => handleGenerateDemo(1)}
          >
            Generar acta
          </Button>
          <Button
            className={`fcdt_btn_secondary ${style.button}`}
            onClick={() => handleGenerateDemo(2)}
          >
            Generar Árbol
          </Button>
        </section>

        {/* <section className={style.section_recapcha}>
          <ReCAPTCHA
            sitekey={APIKEY_RECAPCHA}
            className={style.recapcha}
            onChange={(value) => setisrecapcha(value ? value : "")}
          />
        </section> */}
      </section>
    </div>
  );
};

export default DemoGenerate;
