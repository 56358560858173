import { FCDTButtonProps } from "./model";
import style from "./style/fcdt-button.module.scss";

const Button = (props: FCDTButtonProps) => {
  return (
    <button
      type={props.type}
      className={`fcdt_btn_primary ${style.button} ${props.className}`}
      style={{
        ...props.style,
        fontWeight: props.fontWeight ? props.fontWeight : 600,
        fontSize: props.fontSize,
        width: `${props.width}%`,
        opacity: props.disabled ? 0.5 : 1,
      }}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
