import { GoPlusCircle } from "react-icons/go";
import style from "./style/add-user.module.scss";
import { AddUserProps } from "./model";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export const AddUser = (props: AddUserProps) => {
  const { handleClick } = props;

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  return (
    <button
      type="button"
      className={style.user_button}
      onClick={handleClick}
      style={{display:!dataUser?.collaborator.is_admin?"none":"block"}}
      disabled={dataUser && !dataUser.collaborator.is_admin}
    >
      <GoPlusCircle size={30} color="#00de97" /> Añadir usuarios
    </button>
  );
};
