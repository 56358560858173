import Title from "components/fcdt-title/fcdt-title";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import style from "./styles/staked-bar-char.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";

// Definición de los tipos de datos
export interface DataPointSnackedChart {
  date: string;
  [key: string]: number | string;
}

interface BarChartStackedProps {
  data: DataPointSnackedChart[];
  colors: string[];
}

const ChildrenChart: React.FC<BarChartStackedProps> = ({ data, colors }) => {
  if (data.length === 0) {
    return <div>No data available</div>; // Mensaje en caso de no haber datos
  }

  // Extraer las claves de las categorías dinámicas
  const keys = Object.keys(data[0]).filter((key) => key !== "date");

  return (
    <ResponsiveContainer width="100%" height={150}>
      <BarChart data={data} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tick={{ fontSize: 12, fill: "#898989", fontFamily: "Poppins" }}
        />
        <YAxis
          tick={{ fontSize: 12, fill: "#898989", fontFamily: "Poppins" }}
        />

        {/* Barras apiladas para cada categoría dinámica */}
        {keys.map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            stackId="a"
            fill={colors[index] || "#00DE97"}
          >
            <LabelList
              dataKey={key}
              position="center"
              fontSize={11}
              fill="#fff"
              fontFamily="Poppins"
              formatter={(value: number) => (value > 0 ? value : "")}
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

type StackedBarChartProps = {
  data: DataPointSnackedChart[];
  colors: string[];
  legend: string[];
};

// Uso del componente con datos de ejemplo y colores
const StackedBarChart: React.FC<StackedBarChartProps> = ({
  data,
  legend,
  colors,
}) => {
  return (
    <div className={style.container_principal}>
      <div className={style.container_chart}>
        <Title fontSize={12} className={style.text_y_axis}>
          Número de tareas
        </Title>

        <div className={style.box_chart}>
          <ChildrenChart data={data} colors={colors} />
          <Title fontSize={12} className={style.text_x_axis}>
            Fecha de corte
          </Title>
        </div>
      </div>

      <section className={style.container_legend}>
        <div className={style.container_inter_legend}>
          {legend.map((item, index) => {
            return (
              <div key={index} className={style.container_circle_point}>
                <div
                  className={style.box}
                  style={{
                    background: colors[index],
                  }}
                ></div>
                <span>
                  <Paragraph fontWeight={300} fontSize={13}>
                    {item}
                  </Paragraph>
                </span>
              </div>
            );
          })}
        </div>

        <Title fontSize={12} className={style.title_legend}>
          Prioridad de tareas
        </Title>
      </section>
    </div>
  );
};

export default StackedBarChart;
