import React from "react";
import CardSuscription from "components/card-suscription/card-suscription";
import style from "./style/plans.module.scss";
import { IoCloseCircleOutline } from "react-icons/io5";
import { PlansProps } from "./model";
import { Link } from "react-router-dom";
import { ROUTES } from "routes/constants";

const Plans = (props: PlansProps) => {
  const { data } = props;

  return (
    <div className={style.container_principal}>
      <Link to={ROUTES.LOGIN} className={`fcdt-link-to ${style.close_modal}`}>
        <IoCloseCircleOutline size={26} />
      </Link>
      <div className={style.modal_content}>
        {data.map((plan, index) => (
          <CardSuscription key={index} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default Plans;
