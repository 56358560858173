import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Title from "components/fcdt-title/fcdt-title";

import { IoCalendarNumberOutline } from "react-icons/io5";
import style from "../styles/body-knowledge.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { formatDate } from "helpers/formatDate";
import { setSelectedInformationMeeting } from "store/slices/knowledge";
import { MeetingKnowledge } from "pages/knowledge/model";

const MeetingsList = () => {
  const dispatch = useDispatch();

  const { meetingsInformation } = useSelector((state: RootState) => {
    return state.knowledge;
  });

  const handleSelectMeeting = (meeting: MeetingKnowledge) => {
    dispatch(setSelectedInformationMeeting(meeting));
  };

  return (
    <div>
      {meetingsInformation.length === 0 ? (
        <Paragraph fontWeight={300} className={style.container_not_results}>No se encontraron resultados</Paragraph>
      ) : (
        <>
          {meetingsInformation.map((meeting) => (
            <div
              key={meeting.id_meeting}
              className={style.container_children_accordion}
              onClick={() => handleSelectMeeting(meeting)}
              style={{
                backgroundColor: meeting.isSelected
                  ? "rgba(0, 222, 151, 0.2)"
                  : "",
              }}
            >
              <Title fontSize={16}>{meeting.meeting_name}</Title>
              <Paragraph
                fontSize={14}
                fontWeight={300}
                className={style.paragrap_calendar}
              >
                <IoCalendarNumberOutline size={25} className={style.icon} />{" "}
                {formatDate(meeting.meeting_start_date)}
              </Paragraph>
              <Paragraph fontSize={14}>
                {meeting.meeting_description.slice(0, 150)}...
              </Paragraph>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default MeetingsList;
