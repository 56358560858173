import FilterContainer from "components/filter/filter-container";
import style from "./style/plan-manager.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useEffect, useState } from "react";
import { Pagination } from "components/pagination/pagination";
import Dialog from "components/modals/dialog";
import { NewPlan } from "components/modals/components/new-plan";
import {
  setOpenDeleteFunction,
  setOpenNewFunction,
  setOpenNewPlan,
} from "store/slices/superAdmin";
import { GoPlusCircle } from "react-icons/go";
import { IoAppsOutline, IoCreateOutline } from "react-icons/io5";
import { NotificationProps } from "./model";
import { NewFunction } from "components/modals/components/new-function";
import { peso } from "helpers/formatCurrency";

export const PlanManager = (props: NotificationProps) => {
  const { plans, updatePlan } = props;
  const dispatch = useDispatch<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpenNewPlan, isOpenNewFunction } = useSelector(
    (state: RootState) => {
      return state.superadmin;
    }
  );
  const totalPages = plans ? Math.ceil(plans.length / 3) : 1;

  const startIndex = (currentPage - 1) * 3;
  const endIndex = Math.min(startIndex + 3, plans?.length || 0);
  const itemsToShow = plans ? plans.slice(startIndex, endIndex) : [];
  useEffect(() => {
    setCurrentPage(1);
  }, [plans]);
  const handleOpenDialogPlan = () => {
    dispatch(setOpenNewPlan(!isOpenNewPlan));
    dispatch(setOpenDeleteFunction(false));
  };

  const handleOpenDialogFunction = () => {
    dispatch(setOpenNewFunction(!isOpenNewFunction));
  };
  return (
    <>
      <Dialog
        isOpen={isOpenNewPlan}
        onClose={handleOpenDialogPlan}
        styleClose={false}
      >
        <NewPlan />
      </Dialog>
      <Dialog
        isOpen={isOpenNewFunction}
        onClose={handleOpenDialogFunction}
        styleClose={false}
      >
        <NewFunction />
      </Dialog>
      <section className={style.notification_container}>
        <div className={style.controls}>
          <div className={style.toggle}></div>
          <FilterContainer />
        </div>
        <h2>Planes</h2>
        <div className={style.content}>
          <div className={style.head}>
            <button type="button" onClick={handleOpenDialogPlan}>
              {" "}
              <GoPlusCircle size={38} color="#00de97" /> Añadir plan
            </button>
            <button type="button" onClick={handleOpenDialogFunction}>
              <IoAppsOutline size={38} color="#00de97" />
              Funcionalidades
            </button>
          </div>
          {itemsToShow?.length === 0 ? (
            <p>No hay coincidencias con tu búsqueda</p>
          ) : (
            itemsToShow?.map((item) => (
              <div className={style.plans} key={item.plan.id}>
                <div>
                  <h3>{item.plan.plan_name}</h3>
                  <p>
                    Actualización:{" "}
                    <span>
                      {new Date(item.plan.updatedAt).toLocaleDateString(
                        "en-US"
                      )}
                    </span>{" "}
                    Estado:{" "}
                    <span>
                      {item.plan.plan_status ? "Activo" : "Desactivado"}
                    </span>
                  </p>
                  <p className={style.price}>
                    {peso.format(parseInt(item.plan.plan_price))}
                    <span>/MES</span>{" "}
                  </p>
                </div>
                <div className={style.content_buttons}>
                  {/* <button type="button">
                    <IoEyeOutline size={30} className="fcdt-icon fcdt-gray" />
                  </button> */}
                  <button
                    type="button"
                    onClick={() => {
                      updatePlan(item);
                    }}
                  >
                    <IoCreateOutline
                      size={30}
                      className="fcdt-icon fcdt-gray"
                    />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
        <Pagination
          plans={plans}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </section>
    </>
  );
};
