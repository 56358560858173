import Title from "components/fcdt-title/fcdt-title";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import style from "./styles/dates-follow-up.module.scss";
import Button from "components/buttons/fcdt-button";
import { DatesFollowUpProps } from "./model";

const DatesFollowUp = (props: DatesFollowUpProps) => {
  const {register, handleSubmit, onSubmit, handleNavigate, values, isEdit } =
    props;

  return (
    <div className={style.container_principal}>
      <Title fontSize={20} fontWeight={700}>
        Gestión de fechas para seguimiento
      </Title>
      <Paragraph
        fontSize={16}
        fontWeight={300}
        width="90"
        className={style.paragraph}
      >
        Para poder continuar con la visualización del dashboard, es necesario
        que ingreses y guardes tres fechas clave. Estas fechas nos permitirán
        mostrarte la información más relevante y personalizada. Por favor,
        asegúrate de completar todos los campos de fecha antes de proceder.
      </Paragraph>

      <form className={style.container_form} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.container_date_inputs}>
          <div className={`btn_cancel ${style.container_box_date}`}>1</div>

          <div className={style.container_label_input}>
            <div className={style.container_label}>
              <Paragraph fontWeight={300} fontSize={14}>
                Fecha inicial
              </Paragraph>
            </div>
            <input
              type="date"
              className="fcdt-input"
              required
              {...register("date1_start")}
            />
          </div>

          <div className={style.container_label_input}>
            <div className={style.container_label}>
              <Paragraph fontWeight={300} fontSize={14}>
                Fecha final
              </Paragraph>
            </div>
            <input
              type="date"
              className="fcdt-input"
              required
              min={values().date1_start}
              disabled={!values().date1_start ? true : false}
              {...register("date1_end")}
            />
          </div>
        </div>
        <div className={style.container_date_inputs}>
          <div className={`btn_cancel ${style.container_box_date}`}>2</div>

          <div className={style.container_label_input}>
            <div className={style.container_label}>
              <Paragraph fontWeight={300} fontSize={14}>
                Fecha inicial
              </Paragraph>
            </div>
            <input
              type="date"
              className="fcdt-input"
              {...register("date2_start")}
            />
          </div>

          <div className={style.container_label_input}>
            <div className={style.container_label}>
              <Paragraph fontWeight={300} fontSize={14}>
                Fecha final
              </Paragraph>
            </div>
            <input
              type="date"
              className="fcdt-input"
              min={values().date2_start}
              disabled={!values().date2_start ? true : false}
              {...register("date2_end")}
            />
          </div>
        </div>

        <div className={style.container_date_inputs}>
          <div className={`btn_cancel ${style.container_box_date}`}>3</div>

          <div className={style.container_label_input}>
            <div className={style.container_label}>
              <Paragraph fontWeight={300} fontSize={14}>
                Fecha inicial
              </Paragraph>
            </div>
            <input
              type="date"
              className="fcdt-input"
              {...register("date3_start")}
            />
          </div>

          <div className={style.container_label_input}>
            <div className={style.container_label}>
              <Paragraph fontWeight={300} fontSize={14}>
                Fecha final
              </Paragraph>
            </div>
            <input
              type="date"
              className="fcdt-input"
              min={values().date3_start}
              disabled={!values().date3_start ? true : false}
              {...register("date3_end")}
            />
          </div>
        </div>

        <div className={style.container_buttons}>
          <Button width="40" type="submit">
            {!isEdit ? "Generar fechas" : "Editar fechas"}
          </Button>
          <Button
            width="40"
            className="fcdt_btn_secondary"
            type="button"
            disabled={!isEdit}
            onClick={handleNavigate}
          >
            Ir a dashboard
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DatesFollowUp;
