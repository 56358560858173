import React from "react";
import Selectors from "./selectors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { DataMultiSelect } from "components/fcdt-multiselect/model";
import {
  dataCollaboratorSelected,
  dataGoalsSelected,
  getDependencesKnowledgeFilter,
  setDataEventSelected,
} from "store/slices/knowledge";
import { handleMappinValuesKnowledge } from "pages/knowledge/components/helper/mapping-data-knowledge";

const SelectorsContainer = () => {
  const dispatch: any = useDispatch();

  const { processes, goals, collaborators, events } = useSelector(
    (state: RootState) => {
      return state.knowledge;
    }
  );

  const handleChangeProcesses = (values: DataMultiSelect[]) => {
    if (values.length === 0) return;
    const processSelected = handleMappinValuesKnowledge(values);
    dispatch(getDependencesKnowledgeFilter(processSelected, null));
  };

  const handleChangeGoals = (values: DataMultiSelect[]) => {
    if (values.length === 0) return;
    const goalsSelected = handleMappinValuesKnowledge(values);
    dispatch(dataGoalsSelected(goalsSelected));
    dispatch(getDependencesKnowledgeFilter(null, goalsSelected));
  };

  const handleChangeCollaborator = (values: DataMultiSelect[]) => {
    dispatch(dataCollaboratorSelected(handleMappinValuesKnowledge(values)));
  };

  const handleChangeEvents = (values: DataMultiSelect[]) => {
    dispatch(setDataEventSelected(values));
  };

  return (
    <Selectors
      processes={processes}
      goals={goals}
      collaborators={collaborators}
      events={events}
      handleChangeProcess={handleChangeProcesses}
      handleChangeGoals={handleChangeGoals}
      handleChangeCollaborator={handleChangeCollaborator}
      handleChangeEvents={handleChangeEvents}
    />
  );
};

export default SelectorsContainer;
