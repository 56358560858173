import FilterContainer from "components/filter/filter-container";
import { CompaniesProps } from "./model";
import style from "./style/companies.module.scss";
import { IoEyeOutline } from "react-icons/io5";
import Title from "components/fcdt-title/fcdt-title";

const Companies = (props: CompaniesProps) => {
  const { data, handleOpenModal } = props;
  return (
    <section className={style.payments_container}>
      <div className={style.controls}>
        <div className={style.toggle}></div>
        <FilterContainer />
      </div>
      <Title fontSize={18} fontWeight={600} className={style.title_table}>
        Lista de licencias
      </Title>
      <div className={style.content}>
        <table>
          <thead>
            <tr>
              <th>Empresa</th>
              <th>Número de usuarios</th>
              <th>Correo</th>
              <th>Número de procesos</th>
              <th>Fecha de vencimiento</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((event) => (
              <tr key={event.id}>
                <td>{event.company_name}</td>
                <td className={style.td_color}>{event.number_users}</td>
                <td>{event.company_email}</td>
                <td>{event.company_number_process}</td>
                <td>{event.company_date_expiration}</td>
                <td>
                  <IoEyeOutline
                    className={`${style.icon_width} fcdt-icon-gray`}
                    onClick={() => handleOpenModal(event)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Companies;
