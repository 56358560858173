import axios from "axios";
import { APIURL, PAYMENTS_PORT } from "config/APIURL";

export const getStatusPayment = async (
  idPayment: string,
  statusPayment: string,
  epaycoRef: string
) => {
  const body = {
    gateway_payment_id: epaycoRef,
    id_purchase_status: parseInt(statusPayment),
  };

  try {
    const response = await axios({
      method: "PUT",
      url: `${APIURL}/api/payments/${idPayment}`,
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
