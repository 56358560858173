import React from "react";
import style from "../nav-bar/styles/nav-bar.module.scss";
import SelectorsContainer from "./components/selectors/selectors-container";

const NavFollowUp = () => {
  return (
    <div className={style.principal_nav_bar}>
      <SelectorsContainer />
    </div>
  );
};

export default NavFollowUp;
