import React from "react";
import style from "./style/first-form-document.module.scss";
import Button from "components/buttons/fcdt-button";
import { FirstFormDocumentProps } from "../model";

const FirstFormDocument = (props: FirstFormDocumentProps) => {
  const { register, handleSubmit, onSubmit, handleNavigate } = props;

  return (
    <form className={style.container_form} onSubmit={handleSubmit(onSubmit)}>
      <section>
        <label>Nombre</label>
        <input
          type="text"
          placeholder=""
          required
          disabled
          {...register("minuteName")}
        />
      </section>

      <section>
        <label>Fecha de la reunión</label>
        <input
          type="date"
          placeholder=""
          disabled
          required
          {...register("date")}
        />
      </section>

      {/* <section>
        <label>Creador del evento</label>
        <input
          type="text"
          placeholder=""
          required
          disabled
          {...register("minuteCreated")}
        />
      </section> */}

      <section>
        <label>Tema de discusión</label>
        <textarea
          required
          minLength={6}
          maxLength={10000}
          {...register("minuteDiscution")}
        ></textarea>
      </section>

      <section className={style.section_btn}>
        <Button
          className={`fcdt_btn_without_background_primary ${style.buttons}`}
          type="button"
          onClick={() => handleNavigate(1)}
        >
          Cancelar
        </Button>
        <Button className={style.buttons} type="submit">
          Continuar
        </Button>
      </section>
    </form>
  );
};

export default FirstFormDocument;
