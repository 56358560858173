import React from "react";
import style from "../../style/landing-page.module.scss";
import { ComponentsLandingProps } from "pages/landing-page/model";
import SuperAdminGif from "assets/gifs/super_admin.gif";
import LeaderProcessGif from "assets/gifs/leader_process.gif";
import CollaboratorGif from "assets/gifs/collaborator.gif";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";

const Services = (props: ComponentsLandingProps) => {
  return (
    <div id={props.id} className={style.container_services}>
      <section>
        <img
          src={SuperAdminGif}
          alt="super administratos Gif"
          className={style.iframe}
        />
        <div className={style.card}>
          <Paragraph className={style.container_paragraph} fontSize={20}>
            <span>
              <strong>Rykwa</strong> (Record your knowledge and work advances)
            </span>{" "}
            es un software como servicio que ayuda en procesos de gestión de
            conocimiento con base en los datos de encuentros virtuales.
          </Paragraph>
        </div>
      </section>

      <section>
        <div className={style.card}>
          <Paragraph className={style.container_paragraph_green} fontSize={20}>
            <strong>
              Rykwa esta diseñado para el seguimiento de procesos
            </strong>{" "}
            <p>
              que implican trabajo en equipo y gestión de tareas a corto y
              mediano plazo
            </p>
          </Paragraph>
        </div>

        <img
          src={LeaderProcessGif}
          alt="super administratos Gif"
          className={style.iframe}
        />
      </section>

      <section>
        <img
          src={CollaboratorGif}
          alt="super administratos Gif"
          className={style.iframe}
        />
        <div className={style.card}>
          <Paragraph className={style.container_paragraph} fontSize={20}>
            <span>
              <strong>Rykwa recopila datos </strong>
            </span>
            <p>
              audiovisuales, los transforma, almacena y estructura para
              construir conocimiento fundamental para la tomar decisiones.
            </p>
          </Paragraph>
        </div>
      </section>
    </div>
  );
};

export default Services;
