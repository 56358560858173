import Indicators from "./indicator";
import { BarChartData, CircleChartData } from "./model";

const IndicatorSuperAdminContainer = () => {
  const numberUsers = 2000;

  const barCharData: BarChartData = {
    xAxis: ["Enero", "Febrero", "Marzo", "Abril"],
    data: [
      { data: [2, 3, 7, 8], color: "#2f0084" },
      { data: [6, 2, 1, 5], color: "#00de97" },
    ],
  };

  const circleChartData: CircleChartData[] = [
    {
      data: [
        { id: 0, value: 10, label: "Activas" },
        { id: 1, value: 15, label: "Vencidas" },
        { id: 2, value: 30, label: "Pendientes" },
      ],
    },
  ];

  return (
    <Indicators
      numberUsers={numberUsers}
      barChartData={barCharData}
      circleChartData={circleChartData}
    />
  );
};

export default IndicatorSuperAdminContainer;
