import React from "react";
import Button from "components/buttons/fcdt-button";
import style from "../first-form/style/first-form-document.module.scss";
import { SecondFormDocumentProps } from "../model";

const SecondFormDocument = (props: SecondFormDocumentProps) => {
  const { register, handleSubmit, onSubmit, handleComeBack } = props;

  return (
    <form className={style.container_form} onSubmit={handleSubmit(onSubmit)}>
      <section>
        <label>Resumen</label>
        <textarea
          required
          minLength={6}
          maxLength={10000}
          {...register("minutesSummay")}
        ></textarea>
      </section>

      <section>
        <label>compromisos</label>
        <textarea
          className={style.box_area}
          required
          minLength={6}
          maxLength={10000}
          {...register("minutescommitment")}
        ></textarea>
      </section>

      <section>
        <label>Conclusiones</label>
        <textarea
          required
          minLength={6}
          maxLength={10000}
          {...register("minuteConclusion")}
        ></textarea>
      </section>

      <section className={style.section_btn}>
        <Button
          className={`fcdt_btn_without_background_primary ${style.buttons}`}
          type="button"
          onClick={handleComeBack}
        >
          Atrás
        </Button>
        <Button className={style.buttons} type="submit">
          Guardar
        </Button>
      </section>
    </form>
  );
};

export default SecondFormDocument;
