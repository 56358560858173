import React, { useState } from "react";
import { AccordionProps } from "./model";
import style from "./style/accordion.module.scss";

const Accordion = (props: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={style.container_accordion}>
      <div className={style.select_box} onClick={() => setIsOpen(!isOpen)}>
        <span className="fcdt-title-responsive">{props.label}</span>
        <span className={`${style.arrow} ${isOpen ? style.open : ""}`}></span>
      </div>
      <div className="animate__animated animate__fadeIn" hidden={!isOpen}>
        {props.children}
      </div>
    </div>
  );
};

export default Accordion;
