import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  Companies,
  DataCompaniesTable,
  Responsecompanies,
} from "pages/superAdmin/components/companies/model";
import { AppThunk } from "store/store";
import * as API from "pages/superAdmin/components/companies/api/api";
import { displayLoader, removeProcess } from "store/slices/loading";

type InitialState = {
  isModalMoreInformation: boolean;
  dataCompanies: Companies[];
  dataCompaniesTable: DataCompaniesTable[];
  companySelected?: DataCompaniesTable;
  isFeching: boolean;
  isReloadNeeded: boolean;
};
const initialState: InitialState = {
  isModalMoreInformation: false,
  dataCompanies: [],
  dataCompaniesTable: [],
  companySelected: undefined,
  isFeching: false,
  isReloadNeeded: true,
};
export const slice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompaniesInit: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = true;
    },
    setReloadCompanies: (state) => {
      state.isReloadNeeded = true;
    },
    setCompaniesSuccess: (state) => {
      state.isFeching = false;
      state.isReloadNeeded = false;
    },
    setDataCompanies: (state, actions: PayloadAction<Companies[]>) => {
      state.dataCompanies = actions.payload;
      const newData: DataCompaniesTable[] = [];
      actions.payload.forEach((item, index) => {
        const purchaseDate: string = item.plan_info.plan
          ? item.plan_info.plan.updatedAt
            ? new Date(item.plan_info.plan.updatedAt)
                .toISOString()
                .split("T")[0]
            : "N/A"
          : "N/A";

        newData.push({
          id: index + 1,
          id_company: item.companie.uid,
          company_name: item.companie.company_name,          
          company_date_expiration: item.plan_info.expired_date?
          new Date(item.plan_info.expired_date)
                .toISOString()
                .split("T")[0]
            : "N/A",
          company_amount: `${item.plan_info.ammount}`,
          company_plan: item.plan_info.plan
            ? item.plan_info.plan.plan_name
            : "N/A",
          company_status: item.companie.company_status,
          company_email: item.companie.company_contact_email,
          number_users: item.num_users,
          company_number_process: item.num_process,
        });
      });
      state.dataCompaniesTable = newData;
    },
    setCompanySelected: (state, actions: PayloadAction<DataCompaniesTable>) => {
      state.companySelected = actions.payload;
    },
    setUpdateCompany: (state, actions: PayloadAction<string>) => {
      state.dataCompaniesTable = state.dataCompaniesTable.map((item) => {
        if (item.id_company === actions.payload) {
          return { ...item, company_status: !item.company_status };
        }
        return { ...item };
      });
    },
    setIsModalMoreInformation: (state, actions: PayloadAction<boolean>) => {
      if (!actions.payload) {
        state.companySelected = undefined;
      }
      state.isModalMoreInformation = actions.payload;
    },
  },
});
export const {
  setIsModalMoreInformation,
  setReloadCompanies,
  setDataCompanies,
  setCompanySelected,
} = slice.actions;
export default slice.reducer;

export const getCompanies = (): AppThunk => async (dispatch, getstate) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    dispatch(slice.actions.setCompaniesInit());
    const { token } = getstate().auth.dataSuperAdmin!;
    const response: Responsecompanies = await API.getCompanies(token);
    dispatch(slice.actions.setDataCompanies(response.companies.reverse()));
    dispatch(slice.actions.setCompaniesSuccess());
    dispatch(removeProcess(idProcess));
    return true;
  } catch (error: any) {
    console.log({ error });
    dispatch(removeProcess(idProcess));
    return false;
  }
};

export const updateCompany =
  (id: string, status: boolean): AppThunk =>
  async (dispatch, getstate) => {
    try {
      const { token } = getstate().auth.dataSuperAdmin!;
      const response = await API.updateCompany(id, status, token);
      dispatch(slice.actions.setUpdateCompany(id));
      return response;
    } catch (error: any) {
      return false;
    }
  };
