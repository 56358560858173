import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ArrayPayments,
  PaymentsTable,
  ResponsePayments,
} from "pages/superAdmin/components/payments/model";
import { displayLoader, removeProcess } from "store/slices/loading";
import { AppThunk } from "store/store";
import * as API from "pages/superAdmin/components/payments/api/api";

type InitialState = {
  isModalMoreInformation: boolean;
  dataPayments: ArrayPayments[];
  dataPaymentsTable: PaymentsTable[];
  paymentSelected?: PaymentsTable;
  isFeching: boolean;
  isReloadNeeded: boolean;
};
const initialState: InitialState = {
  isModalMoreInformation: false,
  dataPayments: [],
  dataPaymentsTable: [],
  paymentSelected: undefined,
  isFeching: false,
  isReloadNeeded: true,
};
export const slice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setPaymentsInit: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = true;
    },
    setPaymentsSuccess: (state) => {
      state.isFeching = false;
      state.isReloadNeeded = false;
    },
    setReloadPayments: (state) => {
      state.isReloadNeeded = true;
    },
    setIsModalMoreInformation: (state, actions: PayloadAction<boolean>) => {
      if (!actions.payload) {
        state.paymentSelected = undefined;
      }
      state.isModalMoreInformation = actions.payload;
    },
    setDataPayments: (state, actions: PayloadAction<ArrayPayments[]>) => {
      state.dataPayments = actions.payload;
      const newData: PaymentsTable[] = [];
      actions.payload.forEach((item, index) => {
        const purchaseDate: string = new Date(item.payment.purchase_date)
          .toISOString()
          .split("T")[0];
        newData.push({
          id: index + 1,
          id_payment: item.payment._id,
          id_company: item.company_info.uid,
          company_name: item.company_info.company_name,
          purchase_date: `${purchaseDate}`,
          price: `$${item.payment.ammount}`,
          type_licence: item.company_info.plan
            ? item.company_info.plan.plan_name
            : "N/A",
          status: item.payment.purchase_status,
        });
      });
      state.dataPaymentsTable = newData;
    },
    setPaymentSelected: (state, actions: PayloadAction<PaymentsTable>) => {
      state.paymentSelected = actions.payload;
    },
    setUpdatePayment: (state, actions: PayloadAction<string>) => {
      state.dataPaymentsTable = state.dataPaymentsTable.map((item) => {
        if (item.id_payment === actions.payload) {
          return { ...item, status: !item.status };
        }
        return { ...item };
      });
    },
  },
});
export const {
  setIsModalMoreInformation,
  setDataPayments,
  setPaymentSelected,
  setReloadPayments,
} = slice.actions;
export default slice.reducer;

export const getPayments = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    dispatch(slice.actions.setPaymentsInit());
    const { token } = getState().auth.dataSuperAdmin!;
    const response: ResponsePayments = await API.getPayments(token);
    dispatch(slice.actions.setDataPayments(response.arr));
    dispatch(slice.actions.setPaymentsSuccess());
    dispatch(removeProcess(idProcess));
    return true;
  } catch (error: any) {
    console.log({ error });
    dispatch(removeProcess(idProcess));
    return false;
  }
};

export const updatePayment =
  (id: string, status: number): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataSuperAdmin!;
      const response = await API.updatePayment(id, status, token);
      dispatch(slice.actions.setUpdatePayment(id));
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
