import React, { useEffect, useState } from "react";
import ModalInformationCompanies from "./modal-information";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  setIsModalMoreInformation,
  updateCompany,
} from "pages/superAdmin/store/slices/companies";
import toast from "react-hot-toast";
import { DataToggle } from "components/fcdy-toggle/model";

const ModalInformationCompaniesContainer = () => {
  const dispatch: any = useDispatch();

  const { companySelected } = useSelector((state: RootState) => {
    return state.superAdmin.companies;
  });
  const [ statusCompany, setStatuscompany ] = useState(companySelected?.company_status?companySelected?.company_status:false);
  const [isToggle, setisToggle] = useState<DataToggle>({
    title: "Inactivo",
    titleActive: "Activo",
    active: false,
  });

  useEffect(() => {    
    setStatuscompany(companySelected?.company_status?true:false)
    setisToggle({
      ...isToggle,
      active:statusCompany,
    });
  }, [companySelected, dispatch]);

  const handleCloseModal = () => {
    dispatch(setIsModalMoreInformation(false));
  };

  const handleChangeToggle = async () => {
    setisToggle({ ...isToggle, active: !isToggle.active });
    if (!companySelected) return;
    const res: any = await dispatch(
      updateCompany(companySelected.id_company, !isToggle.active)
    );
    if (!res?.ok) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }
  };

  if (!companySelected) return null;

  return (
    <ModalInformationCompanies
      data={companySelected}
      dataToggle={isToggle}
      handleChangeToggle={handleChangeToggle}
      handleCloseModal={handleCloseModal}
    />
  );
};

export default ModalInformationCompaniesContainer;
