import React from "react";
import ResponsivePagination from "react-responsive-pagination";
import "./style/pagination.scss";
import { NotificationProps } from "pages/notifications/model";

export const Pagination = (props: NotificationProps) => {
  const { totalPages, currentPage, setCurrentPage } = props;

  const handlePageChange = (page: number) => {
    if (setCurrentPage) {
      setCurrentPage(page);
    }
  };
  return (
    <ResponsivePagination
      total={totalPages ? totalPages : 1}
      current={currentPage ? currentPage : 1}
      onPageChange={(page) => handlePageChange(page)}
      maxWidth={100}
    />
  );
};
