import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDependencesSelectors } from "store/slices/follow-up";
import { RootState } from "store/store";
import NavFollowUp from "./components/nav-bar/nav-bar-follow-up";
import BodyFollowUpContainer from "./components/body/body-follow-up-container";
import FloatingButton from "components/fcdt-floating-button/fcdt-floating-button";
import { getDatesFollowUp } from "store/slices/date-follow-up";

const FollowUpContainer = () => {
  const dispatch: any = useDispatch();

  const { isReloadNeeded } = useSelector((state: RootState) => {
    return state.followup;
  });

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    if (isReloadNeeded && dataUser) {
      dispatch(getDatesFollowUp());
      dispatch(getDependencesSelectors());
    }
  }, [isReloadNeeded, dataUser]);

  return (
    <div>
      <NavFollowUp />
      <BodyFollowUpContainer />
      <FloatingButton />
    </div>
  );
};

export default FollowUpContainer;
