import React, { ChangeEvent, useEffect, useState } from "react";
import ModalDates from "./modal-dates";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setDatesCalendar,
  setIsModalDates,
} from "pages/collaborator/store/slices/process";
import { useForm } from "react-hook-form";
import { FormAddDates } from "../../model";

const ModalDatesContainer = () => {
  const dispatch = useDispatch();

  const { isModalDates, datesCalendar } = useSelector((state: RootState) => {
    return state.colaborator.process;
  });

  const { register, handleSubmit, setValue, reset } = useForm<FormAddDates>();

  const [limitDate, setlimitDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (datesCalendar) {
      setValue("date_limit", datesCalendar.date_limit);
      setValue("date_optimistic", datesCalendar.date_optimistic);
      setValue("date_likely", datesCalendar.date_likely);
      setValue("date_follow_up", datesCalendar.date_follow_up);
      setValue("number_tries", datesCalendar.number_tries);
      setlimitDate(datesCalendar.date_limit);
    } else {
      reset();
      setlimitDate(undefined);
    }
  }, [datesCalendar, setValue, reset]);

  const handleCloseModal = () => {
    dispatch(setIsModalDates(false));
  };

  const onSubmit = (values: FormAddDates) => {
    dispatch(setDatesCalendar(values));
    dispatch(setIsModalDates(false));
  };

  const handleChangeLimitDate = (e: ChangeEvent<HTMLInputElement>) => {
    setlimitDate(e.target.value);
  };

  if (!isModalDates) return null;
  return (
    <ModalDates
      handleCloseModal={handleCloseModal}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      handleChangeLimitDate={handleChangeLimitDate}
      limitDate={limitDate}
      oldDateCreation={datesCalendar ? datesCalendar.date_follow_up : null}
    />
  );
};

export default ModalDatesContainer;
