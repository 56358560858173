import { Autocomplete, TextField } from "@mui/material";
import Title from "components/fcdt-title/fcdt-title";
import React from "react";
import style from "../../style/create-event.module.scss";
import { IoCloseOutline } from "react-icons/io5";
import Button from "components/buttons/fcdt-button";
import { AddPersonsProps } from "../../model";

const AddPersons = (props: AddPersonsProps) => {
  const {
    handleNavigate,
    handleSubmit,
    customHandleSubmit,
    onSubmit,
    register,
    dataColaborator,
    dataColaboratorSelected,
    handleSelectReviwer,
    handleChangeAutocompleted,
    handleDeleteColaborator,
    handleKeyPress,
    dataExternalColaboratorSelected,
  } = props;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={style.container_add_person}
    >
      <br />
      <Title fontSize={18}>Agregar invitados</Title>

      <section className={style.container_sections}>
        <div>
          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            className={style.input_autocompleted}
            options={dataColaborator}
            sx={{ width: 350 }}
            renderInput={(params) => (
              <TextField {...params} label="Añadir participantes" />
            )}
            value={{ label: "", uid: "", isReviwer: false }}
            onSelect={handleChangeAutocompleted}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          /> */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            className={style.input_autocompleted}
            options={dataColaborator}
            sx={{ width: 350 }}
              renderInput={(params) => (
                <TextField {...params} label="Añadir participantes" />
              )}
              onChange={handleChangeAutocompleted} 
              value={{ label: "", uid: "", isReviwer: false }}
            />
          <ul>
            {dataColaboratorSelected.map((colaborator, index) => {
              return (
                <li key={index}>
                  <span className="fcdt-title-responsive">
                    {colaborator.label}
                  </span>
                  <span
                    className={style.span_review}
                    style={{ color: colaborator.isReviwer ? "#00DE97" : "" }}
                    onClick={() => handleSelectReviwer(colaborator.uid)}
                  >
                    Revisor de acta
                  </span>
                  <IoCloseOutline
                    className="fcdt-icon"
                    onClick={() =>
                      handleDeleteColaborator(colaborator.uid, false)
                    }
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <input
            type="email"
            className={`fcdt-input ${style.input_add_person}`}
            placeholder="Añadir invitados"
            onKeyDownCapture={(e) => handleKeyPress(e)}
            {...register("email")}
          />
          <ul>
            {dataExternalColaboratorSelected.map((colaborator, index) => {
              return (
                <li key={index}>
                  <span
                    className={`fcdt-title-responsive ${style.span_external}`}
                  >
                    {colaborator}
                  </span>
                  <IoCloseOutline
                    className="fcdt-icon"
                    onClick={() => handleDeleteColaborator(colaborator, true)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      <section className={style.section_buttons}>
        <Button
          className="fcdt_btn_without_background_primary"
          type="button"
          onClick={handleNavigate}
        >
          Atrás
        </Button>
        <Button type="button" onClick={customHandleSubmit}>
          Siguiente
        </Button>
      </section>
    </form>
  );
};

export default AddPersons;
