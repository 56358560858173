import React from "react";
import { setIsModalSpeechTree } from "store/slices/landingPage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import ModalSpeechTree from "./modal-speech-tree";
import { SpeechTreeValue } from "pages/landing-page/model";
import toast from "react-hot-toast";

const ModalSpeechTreeContainer = (propos: { data?: SpeechTreeValue[] }) => {
  const dispatch: any = useDispatch();

  const { isModalSpeechTree, dataSpeechTree } = useSelector(
    (state: RootState) => {
      return state.landingPage;
    }
  );

  const handleCloseModal = () => {
    dispatch(setIsModalSpeechTree(false));
  };

  if (isModalSpeechTree && dataSpeechTree?.length === 0) {
    toast.error("No se pudo generar el arbol de discurso, intentalo más tarde", { duration: 7000, position: "top-center" });
    handleCloseModal();
  }

  
  if (!isModalSpeechTree || dataSpeechTree?.length === 0) return null;

  return (
    <div>
      {dataSpeechTree ?
        <div>    <ModalSpeechTree
          open={isModalSpeechTree}
          handleCloseModal={handleCloseModal}
          dataSpeechTree={dataSpeechTree}
        /></div> :""
      
      }
    </div>
  );
};

export default ModalSpeechTreeContainer;
