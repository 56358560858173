import { FCDTTitleProps } from "./model";
import style from "./style/fcdt-title.module.scss";

const Title = (props: FCDTTitleProps) => {  
  const width_title=!props.typeTitle?`${props.width}%`:"100%"
  return (
    <p
      className={`fcdt-title-primary ${style.container_title} ${props.className}`}
      style={{
        ...props.style,
        fontWeight: props.fontWeight,
        fontSize: props.fontSize,
        width: width_title,
        color: props.color,
      }}
    >
      {props.children}
    </p>
  );
};

export default Title;
