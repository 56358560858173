import Layout from "components/layout/Layout";
import { NotificationContainer } from "pages/notifications/notification-container";
import { UserContainer } from "pages/users/user-container";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { ProcessContainer } from "../components/process/process-container";
import CalendarRoutes from "pages/calendar/routes/calendar.routes";
import { SIDEBAR_LINKS } from "components/sidebar/constants";
import DocumentManagementRoutes from "pages/document-management/routes/document-management-routes";
import DatesFollowUpContainer from "../components/dates-follow-up/dates-follow-up-container";
import FollowUpContainer from "pages/follow-up/follow-up-container";
import KnowLedgeContainer from "pages/knowledge/knowledge-container";

const AdminRoutes = () => {
  const { NOTIFICATION, USERS, PROCESS, DATES_FOLLOWUP, FOLLOWUP, KNOWLEDGE } =
    ROUTES.DASHBOARD;

  return (
    <Routes>
      <Route
        path={NOTIFICATION}
        element={
          <Layout>
            <NotificationContainer />
          </Layout>
        }
      />
      <Route
        path={USERS}
        element={
          <Layout>
            <UserContainer />
          </Layout>
        }
      />
      <Route
        path={PROCESS}
        element={
          <Layout>
            <ProcessContainer />
          </Layout>
        }
      />

      <Route
        path={ROUTES.CALENDAR.PARENT}
        element={<CalendarRoutes routes={SIDEBAR_LINKS} />}
      ></Route>

      <Route
        path={DATES_FOLLOWUP}
        element={
          <Layout>
            <DatesFollowUpContainer />
          </Layout>
        }
      />

      <Route
        path={DATES_FOLLOWUP + "/" + FOLLOWUP}
        element={
          <Layout>
            <FollowUpContainer />
          </Layout>
        }
      />

      <Route
        path={ROUTES.DOCUMENT_MANAGEMENT.PARENT}
        element={<DocumentManagementRoutes routes={SIDEBAR_LINKS} />}
      ></Route>

      <Route
        path={KNOWLEDGE}
        element={
          <Layout>
            <KnowLedgeContainer />
          </Layout>
        }
      />
    </Routes>
  );
};

export default AdminRoutes;
