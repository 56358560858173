import Layout from "components/layout/Layout";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "routes/constants";
import DocumentsContainer from "../components/documents/documents-container";
import EditDocumentContainer from "../components/documents/components/edit-document/edit-document-container";
import SpeechTreeContainer from "../components/speech-tree/speech-tree-container";
import SpeechTreeByIdContainer from "../components/speech-tree/components/speech-tree-by-Id/speech-tree-container";
import RecomendedTaskContainer from "../components/documents/components/recommended-task/recomended-task-container";
import TableProcessContainer from "pages/collaborator/components/process/components/table-process/table-process-container";
import TableObjetivesContainer from "pages/collaborator/components/process/components/table-objetives/table-objetives-container";
import ReportsContainer from "../components/reports/reports-container";
import { SideBarLink } from "components/sidebar/model";

const DocumentManagementRoutes = (props: { routes: SideBarLink[] }) => {
  const {
    DOCUMENTS,
    RECOMMENDED_TASKS,
    DOCUMENTS_EDIT,
    SPEECH_TREE,
    SPEECH_TREE_ID,
    PROCESS_REPORT,
    GOALS,
    REPORT,
  } = ROUTES.DOCUMENT_MANAGEMENT.CHILDRENS;

  return (
    <Routes>
      {/* Minutes */}
      <Route
        path={DOCUMENTS}
        element={
          <Layout routes={props.routes}>
            <DocumentsContainer />
          </Layout>
        }
      />
      <Route
        path={RECOMMENDED_TASKS}
        element={
          <Layout routes={props.routes}>
            <RecomendedTaskContainer />
          </Layout>
        }
      />
      <Route
        path={DOCUMENTS_EDIT}
        element={
          <Layout routes={props.routes}>
            <EditDocumentContainer />
          </Layout>
        }
      />

      {/* Speech Tree */}

      <Route
        path={SPEECH_TREE}
        element={
          <Layout routes={props.routes}>
            <SpeechTreeContainer />
          </Layout>
        }
      />

      <Route
        path={SPEECH_TREE_ID}
        element={
          <Layout routes={props.routes}>
            <SpeechTreeByIdContainer />
          </Layout>
        }
      />

      {/* Reports */}

      <Route
        path={PROCESS_REPORT}
        element={
          <Layout routes={props.routes}>
            <TableProcessContainer />
          </Layout>
        }
      />

      <Route
        path={GOALS}
        element={
          <Layout routes={props.routes}>
            <TableObjetivesContainer />
          </Layout>
        }
      />

      <Route
        path={REPORT}
        element={
          <Layout routes={props.routes}>
            <ReportsContainer />
          </Layout>
        }
      />
    </Routes>
  );
};

export default DocumentManagementRoutes;
