import { useSelector } from "react-redux";
import { RootState } from "store/store";
import AddEventContainer from "./components/add-event/add-event-container";
import AddPersonsContainer from "./components/add-persons/add-persons-container";
import style from "./style/create-event.module.scss";
import Title from "components/fcdt-title/fcdt-title";

const CreateEventContainer = () => {
  const { currentIdEvent } = useSelector((state: RootState) => {
    return state.calendar.calendar;
  });

  const Render = () => {
    switch (currentIdEvent) {
      case 1:
        return <AddEventContainer />;
      case 2:
        return <AddPersonsContainer />;
      default:
        return <AddEventContainer />;
    }
  };

  return (
    <>
      <Title className={style.title_create_event} color="#fff">
        Crear reunión
      </Title>
      <Render />
    </>
  );
};

export default CreateEventContainer;
