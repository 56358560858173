import { useEffect } from "react";
import DatesFollowUp from "./dates-follow-up";
import { useForm } from "react-hook-form";
import { FormDatesFollowUp } from "./model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  getDatesFollowUp,
  setDatesFollowUp,
} from "store/slices/date-follow-up";
import toast from "react-hot-toast";
import { formatDate } from "helpers/formatDate";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constants";

const DatesFollowUpContainer = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { isReloadNeeded, dataDates } = useSelector((state: RootState) => {
    return state.datesFollowUp;
  });

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { register, handleSubmit, watch, setValue } =
    useForm<FormDatesFollowUp>();

  useEffect(() => {
    if (dataDates.length > 0) {
      setValue("date1_start", formatDate(dataDates[0].start_date));
      setValue("date1_end", formatDate(dataDates[0].end_date));
      setValue("date2_start", formatDate(dataDates[1].start_date));
      setValue("date2_end", formatDate(dataDates[1].end_date));
      setValue("date3_start", formatDate(dataDates[2].start_date));
      setValue("date3_end", formatDate(dataDates[2].end_date));
    }
  }, [dataDates]);

  useEffect(() => {
    if (isReloadNeeded && dataUser) {
      dispatch(getDatesFollowUp());
    }
  }, [isReloadNeeded, dataUser]);

  const onSubmit = async (values: FormDatesFollowUp) => {
    const res = await dispatch(
      setDatesFollowUp(values, dataDates.length > 0 ? true : false)
    );
    if (!res.ok) {
      toast.error(res.msg, {duration: 7000, position:"top-center"});
      return;
    }
    dispatch(getDatesFollowUp());
    toast.success(res.msg, {duration: 7000, position:"top-center"});
  };

  const handleNavigate = () => {
    navigate(ROUTES.DASHBOARD.FOLLOWUP);
  };

  return (
    <DatesFollowUp
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      handleNavigate={handleNavigate}
      values={watch}
      isEdit={dataDates.length > 0 ? true : false}
    />
  );
};

export default DatesFollowUpContainer;
