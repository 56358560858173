import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Title from "components/fcdt-title/fcdt-title";

import { IoCalendarNumberOutline } from "react-icons/io5";
import style from "../styles/body-knowledge.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { formatDate } from "helpers/formatDate";
import { TaskKnowledge } from "pages/knowledge/model";
import { setSelectedInformationTask } from "store/slices/knowledge";

const TasksList = () => {
  const dispatch = useDispatch();

  const { tasksInformation } = useSelector((state: RootState) => {
    return state.knowledge;
  });

  const handleSelectMeeting = (task: TaskKnowledge) => {
    dispatch(setSelectedInformationTask(task));
  };

  return (
    <div>
      {tasksInformation.length === 0 ? (
        <Paragraph fontWeight={300} className={style.container_not_results}>
          No se encontraron resultados
        </Paragraph>
      ) : (
        <>
          {tasksInformation.map((task) => (
            <div
              key={task.id_task}
              className={style.container_children_accordion}
              onClick={() => handleSelectMeeting(task)}
              style={{
                backgroundColor: task.isSelected
                  ? "rgba(0, 222, 151, 0.2)"
                  : "",
              }}
            >
              <Title fontSize={16}>{task.task_name}</Title>
              <Paragraph
                fontSize={14}
                fontWeight={300}
                className={style.paragrap_calendar}
              >
                <IoCalendarNumberOutline size={25} className={style.icon} />
                <div>
                  <strong>Inicio: </strong>
                  {formatDate(task.task_start_date)}
                </div>
                <div>
                  <strong>Vencimiento: </strong>
                  {formatDate(task.task_due_date)}
                </div>

                <strong>{task.execution_status}</strong>
              </Paragraph>
              <Paragraph fontSize={14}>
                {task.task_description.slice(0, 150)}...
              </Paragraph>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default TasksList;
