import React from "react";
import ButtonFloat from "./button-float";
import { ChartRecommendation } from "pages/follow-up/models";
import { useDispatch } from "react-redux";
import {
  setDataDashboardRecomendationSelected,
  setShowModalButtonRecomendations,
} from "store/slices/follow-up";

const ButtonFloatContainer = (props: { data: ChartRecommendation }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setShowModalButtonRecomendations(true));
    dispatch(setDataDashboardRecomendationSelected(props.data));
  };

  if (!props.data) return null;

  return <ButtonFloat data={props.data} handleClick={handleClick} />;
};

export default ButtonFloatContainer;
