import FCDTModal from "components/modal-notification/fcdt-modal";
import Button from "components/buttons/fcdt-button";
import style from "./style/modal-delete-meeting.module.scss";
import { ModalDeleteMeetingProps } from "../calendar/model";

const ModalDeleteMeetineg = (props: ModalDeleteMeetingProps) => {
  const { openModal, handleCloseModal, register, handleSubmit, onSubmit } =
    props;

  const children = (
    <form className={style.process_container} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.content}>
        <h1>Motivo de rechazo</h1>

        <div className={style.content_left}>
          <textarea
            placeholder="Describa el motivo de porque rechazo la invitación"
            pattern="^(?!.*(\d){5,})[a-zA-Z0-9áéíóúÁÉÍÓÚüÜ.:%!¡¿'?ñÑ,\s]*$"
            required
            minLength={6}
            maxLength={150}
            {...register("description")}
          ></textarea>
        </div>
      </div>
      <div className={style.content_buttons}>
        <Button
          className="btn_cancel"
          children="Cancelar"
          width="40"
          type="button"
          onClick={handleCloseModal}
        />
        <Button children="Confirmar" width="40" type="submit" />
      </div>
    </form>
  );

  return (
    <FCDTModal
      open={openModal}
      children={children}
      handleClose={handleCloseModal}
    />
  );
};

export default ModalDeleteMeetineg;
