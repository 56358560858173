import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlans } from "store/slices/plans";
import { RootState } from "store/store";
import Plans from "./plans";

const PlansContainer = () => {
  const dispatch: any = useDispatch();

  const { isRealoadNeeded, allPlans } = useSelector((state: RootState) => {
    return state.plans;
  });

  useEffect(() => {
    if (isRealoadNeeded) {
      dispatch(getAllPlans());
    }
  }, [isRealoadNeeded, dispatch]);

  if(!allPlans) return null;

  return <Plans data={allPlans} />;
};

export default PlansContainer;
