import Register from "./register-company";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  registerCompany,
  setshowModalTermConditions,
  setvalueCondition,
} from "store/slices/register";
import { FormRegisterCompany } from "./model";
import { useNavigate } from "react-router-dom";
import generateRoute from "common/helpers/generate-route";
import ModalTermConditionContainer from "./components/modal-term-condition-container";
import { RootState } from "store/store";

const RegisterCompanyContainer = () => {
  const { valueCondition } = useSelector((state: RootState) => {
    return state.register;
  });

  const { register, handleSubmit } = useForm<FormRegisterCompany>();

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const handleOpenModal = () => {
    dispatch(setshowModalTermConditions(true));
  };

  const handleChange = () => {
    dispatch(setvalueCondition(!valueCondition));
  };

  const onSubmit = async (values: FormRegisterCompany) => {
    const response: any = await dispatch(registerCompany(values));
    if (!response?.ok) {
      toast.error(response?.msg, {duration: 7000, position:"top-center"});
      return;
    }
    navigate(generateRoute(response.id_company));
  };

  return (
    <>
      <Register
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        handleOpenModal={handleOpenModal}
        valueCheck={valueCondition}
        handleChange={handleChange}
      />
      <ModalTermConditionContainer />
    </>
  );
};

export default RegisterCompanyContainer;
