import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { verifyToken } from "store/slices/auth";

export const EmailVerification = () => {
  const [isVerified, setIsverified] = useState(0);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const token = params.token;

  useEffect(() => {
    if (!token) return;

    const verificationToken = async () => {
      const response: any = await dispatch(verifyToken(token));
      if (!response?.ok) {
        setIsverified(2);
        return;
      }

      setIsverified(1);
      navigate(ROUTES.LOGIN);
      toast.success(response.msg, {duration: 7000, position:"top-center"});
    };

    verificationToken();
  }, [token, navigate, dispatch]);
  return (
    <>
      <h1>{isVerified === 2 && "No autorizado"}</h1>
    </>
  );
};
