import Title from "components/fcdt-title/fcdt-title";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import style from "./style/custom-bar-chart.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";

export interface DataItemBarChart {
  name: string;
  value: number;
  fill: string;
}

type CustomBarChartProps = {
  data: DataItemBarChart[];
};

const CustomBarChart = (props: CustomBarChartProps) => {
  const childrenChart = (
    <ResponsiveContainer width="100%" height={170}>
      <BarChart
        data={props.data}
        margin={{ top: 0, right: 0, left: -30, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={false} axisLine={false} />
        <YAxis tick={{ fill: "#898989", fontSize: 14, fontFamily: "Arial" }} />
        <Bar dataKey="value" isAnimationActive={false} />
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <div className={style.container_principal}>
      <div className={style.container_chart}>
        <Title fontSize={14} className={style.y_label}>
          Dias
        </Title>
        {childrenChart}
        <Title fontSize={14} className={style.x_label}>
          Prioridad tareas
        </Title>
      </div>
      <div className={style.container_legend}>
        {props.data.map((item, index) => {
          return (
            <div key={index} className={style.container_circle_point}>
              <div
                className={style.box}
                style={{
                  background: item.fill,
                }}
              ></div>
              <span>
                <Paragraph fontWeight={300} fontSize={13}>
                  {item.name}
                </Paragraph>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomBarChart;
