import axios from "axios";
// import { APIURL, PROCESSES_PORT, PERSONS_PORT } from "config/APIURL";
import { APIURL } from "config/APIURL";

import { FormRegisterType } from "pages/register/model";
import { FormNewGoal } from "components/modals/model";
import { ProcessData } from "../models";

export const getLeaders = async (token: string) => {
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "GET",
      url: `${APIURL}/api/usuarios/get_all_users_from_company`,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error?.response?.data);
    return error?.response?.data;
  }
};

export const getProcess = async (token: string) => {
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "GET",
      url: `${APIURL}/api/process/get_company_processes`,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const createProcess = async (form: ProcessData, token: string) => {
  const data = JSON.stringify({
    process_name: form?.process_name,
    process_leader: form?.process_leader,
    descriptive_paragraph: form.descriptive_paragraph,
  });

  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      url: `${APIURL}/api/process`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const updateProcess = async (
  form: ProcessData | undefined,
  uid: any,
  token: string
) => {
  const data = JSON.stringify({
    process_name: form?.process_name,
    process_leader: form?.process_leader,
    descriptive_paragraph: form?.descriptive_paragraph,
  });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "PUT",
      url: `${APIURL}/api/process/update_process/${uid}`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response.data;
  }
};

export const getGoals = async (uid: any, token: string) => {
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "GET",
      url: `${APIURL}/api/process/${uid}`,
    });
    return response.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response.data;
  }
};

export const getGoal = async (token: string) => {
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "GET",
      url: `${APIURL}/process`,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
export const createGoal = async (
  form: FormNewGoal,
  collaborator: string[],
  id_process: string,
  token: string
) => {
  const data = JSON.stringify({
    id_process: id_process,
    goal_title: form.goal_title,
    goal_description: form.goal_description,
    start_date: form.expiration_date,
    expiration_date: form.expiration_date,
    id_goal_status: 1,
    id_goal_priority: form.id_goal_priority,
    collaborators: collaborator,
  });

  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      url: `${APIURL}/api/process/add_goal`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const updateGoal = async (
  form: FormNewGoal,
  collaborator: string[],
  token: string
) => {
  const data = JSON.stringify({
    goal_title: form.goal_title,
    goal_description: form.goal_description,
    expiration_date: form.expiration_date,
    start_date: form.expiration_date,
    id_goal_status: 1,    
    id_process:form.id_process,
    id_goal_priority: form.id_goal_priority,
    collaborators: collaborator,
  });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "PUT",
      url: `${APIURL}/api/process/update_goal/${form.id}`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const deleteGoal = async (form: FormNewGoal, token: string) => {
  const data = JSON.stringify({
    goals: form.goalsFromProcess,
  });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "DELETE",
      url: `${APIURL}/api/process/delete_goal`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error);
    return error.response.data;
  }
};

export const registerLeader = async (
  form: FormRegisterType,
  idCompany: string,
  token: string
) => {
  const data = JSON.stringify({
    name: form.name,
    id_identification_type: Number(form.id_identification_type),
    identification_number: form.identification_number,
    name_in_meeting_platform: form.name,
    id_company: idCompany,
    phone_number: form.phone_number,
    email: form.email,
  });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      url: `${APIURL}/api/usuarios/create`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const updatePerson = async (
  idPerson: string,
  form: FormRegisterType,
  token: string
) => {
  const data = JSON.stringify({
    name: form.name,
    id_identification_type: Number(form.id_identification_type),
    identification_number: form.identification_number,
    phone_number: form.phone_number,
    email: form.email,
    status: form.status,
  });
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "PUT",
      url: `${APIURL}/api/usuarios/update_person/${idPerson}`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
