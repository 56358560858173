import { NUMBER_ITEMS_PAGINATION } from "common/contants";
import { useState } from "react";

type usePaginationProps = {
  currentPage: number;
  filterData: any;
  numberItems: number;
};

const usePagination = (props: usePaginationProps) => {
  const { filterData, numberItems } = props;

  const [currentPage, setCurrentPage] = useState(props.currentPage);
  const totalPages = Math.ceil(filterData.length / numberItems);
  const startIndex = (currentPage - 1) * numberItems;
  const endIndex = Math.min(startIndex + numberItems, filterData?.length || 0);
  const itemsToShow = filterData
    ? filterData.length > NUMBER_ITEMS_PAGINATION
      ? filterData.slice(startIndex, endIndex)
      : filterData
    : [];

  return {
    currentPage,
    totalPages,
    setCurrentPage,
    itemsToShow,
  };
};

export default usePagination;
