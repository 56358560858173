import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import { ProcessData } from "pages/admin/components/process/models";
import * as API from "pages/admin/components/process/api/api";
import { FormRegisterType } from "pages/register/model";
import { FormNewGoal, FormNewProcess } from "components/modals/model";
import { displayLoader, removeProcess } from "store/slices/loading";
import { Colaborator } from "pages/collaborator/components/process/model";

type InitialState = {
  process?: ProcessData[];
  selectedProcess?: ProcessData;
  isUpdateProcess: boolean;
  processId?: string;
  leaders: any[];
  allColaborators: Colaborator[];
  isDeleteGoal: boolean;
  isOpenNewGoal?: boolean;
  isOpenNewLeader?: boolean;
  isOpenNewCollaborator: boolean;
  isOpenNewProcess: boolean;
  formProcess?: FormNewProcess;
};
const initialState: InitialState = {
  process: undefined,
  selectedProcess: undefined,
  isUpdateProcess: false,
  isDeleteGoal: false,
  processId: undefined,
  leaders: [],
  allColaborators: [],
  isOpenNewGoal: false,
  isOpenNewLeader: false,
  isOpenNewCollaborator: false,
  isOpenNewProcess: false,
  formProcess: undefined,
};

export const slice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setProcess: (state, actions) => {
      state.process = actions.payload;
    },
    setUpdateProcess: (state, actions) => {
      state.isUpdateProcess = actions.payload;
    },
    setSelectedProcess: (state, actions) => {
      state.selectedProcess = actions.payload;
    },
    setDeleteGoal: (state, actions) => {
      state.isDeleteGoal = actions.payload;
    },
    setProcessId: (state, actions) => {
      state.processId = actions.payload;
    },
    setLeaders: (state, actions) => {
      state.leaders = actions.payload;
      state.allColaborators = actions.payload.map((item: any) => {
        return { uid: item.uid, label: item.name_in_meeting_platform };
      });
    },
    setOpenNewGoal: (state, action) => {
      state.isOpenNewGoal = action.payload;
    },
    setOpenNewLeader: (state, action) => {
      state.isOpenNewLeader = action.payload;
    },
    setOpenNewCollaborator: (state, action) => {
      state.isOpenNewCollaborator = action.payload;
    },
    setisOpenNewProcess: (state, action) => {
      state.isOpenNewProcess = action.payload;
    },
    setFormProcess: (state, action) => {
      state.formProcess = action.payload;
    },
  },
});
export const {
  setProcess,
  setUpdateProcess,
  setSelectedProcess,
  setDeleteGoal,
  setProcessId,
  setLeaders,
  setOpenNewGoal,
  setOpenNewLeader,
  setOpenNewCollaborator,
  setisOpenNewProcess,
  setFormProcess,
  // setProcess,
} = slice.actions;
export default slice.reducer;

export const getLeaders = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const { token } = getState().auth.dataUser!;
    const response = await API.getLeaders(token);
    dispatch(removeProcess(idProcess));
    return response;
  } catch (error) {
    console.log(error);
    dispatch(removeProcess(idProcess));
  }
};

export const getProcess = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const { token } = getState().auth.dataUser!;
    const response: any = await API.getProcess(token);
    dispatch(slice.actions.setProcess(response));
    dispatch(removeProcess(idProcess));
    return response;
  } catch (error) {
    console.log(error);
    dispatch(removeProcess(idProcess));
  }
};
export const createProcess =
  (form: ProcessData): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const response: any = await API.createProcess(form, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log(error);
      dispatch(removeProcess(idProcess));
    }
  };
export const updateProcess =
  (form: ProcessData | undefined): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const uid = getState().admin.process.selectedProcess?.proceso?.uid;
      const response: any = await API.updateProcess(form, uid, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log(error);
      dispatch(removeProcess(idProcess));
    }
  };

export const getGoals = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const { token } = getState().auth.dataUser!;
    const uid = getState().admin.process.isUpdateProcess
      ? getState().admin.process.selectedProcess?.proceso?.uid
      : getState().admin.process.processId;
    const response: any = await API.getGoals(uid, token);
    dispatch(removeProcess(idProcess));
    return response;
  } catch (error) {
    console.log(error);
    dispatch(removeProcess(idProcess));
  }
};
export const createGoal =
  (form: FormNewGoal, collaborator: string[]): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const id_process: any = getState().admin.process.isUpdateProcess
        ? getState().admin.process.selectedProcess?.proceso?.uid
        : getState().admin.process.processId;
      const response: any = await API.createGoal(
        form,
        collaborator,
        id_process,
        token
      );
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log(error);
      dispatch(removeProcess(idProcess));
    }
  };

export const updateGoal =
  (form: FormNewGoal, collaborator: string[]): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const response: any = await API.updateGoal(form, collaborator, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log(error);
      dispatch(removeProcess(idProcess));
    }
  };

export const deleteGoal =
  (form: FormNewGoal): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const response: any = await API.deleteGoal(form, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log(error);
      dispatch(removeProcess(idProcess));
    }
  };

export const registerLeader =
  (form: FormRegisterType): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token, collaborator } = getState().auth.dataUser!;
      const { userSelected, userPersonSelected } = getState().users;
      const idCompany = collaborator.id_company;
      const response: any = !userSelected
        ? await API.registerLeader(form, idCompany, token)
        : await API.updatePerson(
            userPersonSelected?.id_person._id!,
            form,
            token
          );
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };
