import React from "react";
import { Chart } from "react-google-charts";
import { columnsGantt } from "./constants";
import { GanttChartProps } from "./model";
import { color } from "d3";

const GanttChart = (props: GanttChartProps) => {
  const { rows } = props;

  const options = {
    height: rows.length * 50,
    gantt: {
      trackHeight: 30,
      labelStyle: {
        fontName: "Poppins",
        fontSize: 14,
        color:"#2F0084"
      },
    palette: [
      {
        "color": "#2F0084", 
        "dark": "#2F0084", 
        "light": "#2F0084" 
      },
    ]

  }
};

  const data = [columnsGantt, ...rows];

  return (
    <div style={{ height: "300px", overflowY: "auto", width: "100%" }}>
      <Chart chartType="Gantt" width="100%" data={data} options={options} />
    </div>
  );
};

export default GanttChart;
