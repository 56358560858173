import { Controller } from "react-hook-form";
import { reverseFormat, peso } from "../../helpers/formatCurrency";
import { MoneyFieldProps } from "./models";

export const MoneyField = (props: MoneyFieldProps) => {
  const { name, placeholder, rules, control, error } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={rules}
      render={({ field }) => (
        <>
          <input
            type="text"
            placeholder={placeholder}
            minLength={5}
            maxLength={15}
            {...field}
            onChange={(e) => {
              const numericValue =
                e.target.value.length === 1
                  ? parseInt(e.target.value)
                  : parseInt(reverseFormat(e.target.value));
              field.onChange(numericValue);
            }}
            value={
              isNaN(field.value) ? peso.format(0) : peso.format(field.value)
            }
          />
          {(error || isNaN(field.value)) && (
            <p className="message_error">El campo es requerido</p>
          )}
        </>
      )}
    />
  );
};
