import React from "react";
import Layout from "components/layout/Layout";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { SIDEBAR_LINKS_SUPERADMIN } from "../constants";
import UsersSuperAdminContainer from "../components/users/users-container";
import IndicatorSuperAdminContainer from "../components/indicator/indicator-container";
import CompaniesContainer from "../components/companies/companies-container";
import { PlanManagerContainer } from "../components/plan-manager/plan-manager-container";

const SuperAdminRoutes = () => {
  const { PLAN_MANAGER, USERS, INDICATORS, COMPANIES } = ROUTES.DASHBOARD;

  return (
    <Routes>
      <Route
        path={PLAN_MANAGER}
        element={
          <Layout routes={SIDEBAR_LINKS_SUPERADMIN}>
            <PlanManagerContainer />
          </Layout>
        }
      />

      <Route
        path={USERS}
        element={
          <Layout routes={SIDEBAR_LINKS_SUPERADMIN}>
            <UsersSuperAdminContainer />
          </Layout>
        }
      />

      <Route
        path={INDICATORS}
        element={
          <Layout routes={SIDEBAR_LINKS_SUPERADMIN}>
            <IndicatorSuperAdminContainer />
          </Layout>
        }
      />

      <Route
        path={COMPANIES}
        element={
          <Layout routes={SIDEBAR_LINKS_SUPERADMIN}>
            <CompaniesContainer />
          </Layout>
        }
      />

      {/* <Route
        path={PAYMENTS}
        element={
          <Layout routes={SIDEBAR_LINKS_SUPERADMIN}>
            <ParymentsSuperAdminContainer />
          </Layout>
        }
      />

      <Route
        path={ACCOUNTS}
        element={
          <Layout routes={SIDEBAR_LINKS_SUPERADMIN}>
            <AccountContainer />
          </Layout>
        }
      /> */}
    </Routes>
  );
};

export default SuperAdminRoutes;
