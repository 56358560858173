import axios from "axios";
import { APIURL, PAYMENTS_PORT, PLANS_PORT } from "config/APIURL";
import { RequestPayment } from "../model";

export const getAllPlans = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/planes`,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getPlanByID = async (idPlan: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/planes/${idPlan}`,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getPayment = async (
  idCompaby: string,
  idPlan: string,
  numbersMonth: string,
  totalPrice: string
) => {
  const date = new Date();

  const body: RequestPayment = {
    id_company: idCompaby,
    id_plan: parseInt(idPlan),
    num_months: parseInt(numbersMonth),
    ammount: totalPrice,
    purchase_date: `${date.toISOString()}`,
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/payments`,
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
