import React from "react";
import { ButtonFloatProps } from "./model";
import style from "./style/button-float.module.scss";
import {
  IoAlertCircleSharp,
  IoCheckmarkCircleSharp,
  IoSadSharp,
} from "react-icons/io5";

const ButtonFloat = (props: ButtonFloatProps) => {
  const { data, handleClick } = props;

  return (
    <div className={style.container_button_float}>
      <button type="button" onClick={handleClick}>
        {data.type === "good" ? (
          <IoCheckmarkCircleSharp
            className={`${style.icon} ${style.icon_shadown_good}`}
            color={data.icon_color}
          />
        ) : data.type === "warning" ? (
          <IoAlertCircleSharp
            className={`${style.icon} ${style.icon_shadown_warning}`}
            color={data.icon_color}
          />
        ) : (
          <IoSadSharp
            className={`${style.icon} ${style.icon_shadown_bad}`}
            color={data.icon_color}
          />
        )}
      </button>
    </div>
  );
};

export default ButtonFloat;
