import style from "../styles/body-follow-up.module.scss";
import CustomBarChart, {
  DataItemBarChart,
} from "../../custom-charts/bar-chart/cutom-bar-chart";
import { useState } from "react";
import TitleCharts from "../title-chars/title-charts";
import Title from "components/fcdt-title/fcdt-title";
import GaugeStateChart from "../../custom-charts/gauge-state-chart/gauge-state-chart";
import ScatterPlot, {
  DataScatter,
} from "../../custom-charts/scatter-chart/scatter-chart";
import BarChartStacked, {
  DataPointSnackedChart,
} from "../../custom-charts/stacked-bar-chart/stacked-bar-chart";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { formatDate } from "helpers/formatDate";
import { getDataFollowUp } from "store/slices/follow-up";
import ButtonFloatContainer from "../../button_float/button-float-container";

const TaskPeopleCharts = () => {
  const dispatch: any = useDispatch();

  const {
    dataDashboard,
    goalSelected,
    colaboratorSelected,
    dataDashboardRecomendation,
  } = useSelector((state: RootState) => {
    return state.followup;
  });

  const [isPriorityTask, setisPriorityTask] = useState<boolean>(true);

  if (!dataDashboard || !colaboratorSelected) return null;

  const dataBarChart: DataItemBarChart[] =
    dataDashboard.data_tasks_mean_completion_times_chart
      ? dataDashboard.data_tasks_mean_completion_times_chart.map((item) => {
          return { name: item.label, value: item.value, fill: item.color };
        })
      : [];

  const dataScatter: DataScatter[] =
    dataDashboard.data_tasks_completion_times_chart
      ? dataDashboard.data_tasks_completion_times_chart
      : [];

  const asymptotes: number = 3;

  // Priority task

  const dataSnacked: DataPointSnackedChart[] =
    dataDashboard.data_stacked_bar_chart
      ? dataDashboard.data_stacked_bar_chart.map((item) => {
          return { ...item, date: formatDate(item.date) };
        })
      : [];

  const colors = ["#1DAEFF", "#FF891D", "#FFC21D", "#EE3537"];
  const legend = ["Baja", "Importante", "Media", "Urgente"];

  // Execution status

  const colorsStatus = [
    "#9747FF",
    "#1DAEFF",
    "#FFC21D",
    "#FF891D",
    "#00DE97",
    "#EE3537",
  ];
  const legendStatus = [
    "Atrasada",
    "Cancelada",
    "Completada",
    "En curso",
    "En validación",
    "No iniciada",
  ];

  const handleclickStrongJob = (event: boolean) => {
    if (goalSelected && colaboratorSelected) {
      dispatch(
        getDataFollowUp(
          null,
          goalSelected.uid,
          colaboratorSelected.uid,
          !isPriorityTask
        )
      );
    }
    setisPriorityTask(event);
  };

  return (
    <div className={style.container_process_charts}>
      <TitleCharts
        title="Progreso de tareas"
        subtitle={colaboratorSelected.label}
      />

      <section className={style.section_process}>
        <div className={style.container_two_chats_not_title}>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.tasks_uncertainty_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.tasks_uncertainty_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Estados de cumplimiento
            </Title>

            {dataDashboard.data_tasks_uncertainty_chart ? (
              <GaugeStateChart
                percentage={
                  dataDashboard.data_tasks_uncertainty_chart.precent / 100
                }
              />
            ) : (
              <Paragraph>Sin datos</Paragraph>
            )}
          </div>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.tasks_completion_times_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.tasks_completion_times_chart_recommendation
                }
              />
            )}
            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Prioridad
            </Title>
            {dataScatter.length > 0 ? (
              <ScatterPlot data={dataScatter} asymptote={asymptotes} />
            ) : (
              <Paragraph>Sin datos</Paragraph>
            )}
          </div>
        </div>
      </section>
      <section className={style.section_process}>
        <div className={style.container_two_chats_not_title}>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.tasks_mean_completion_times_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.tasks_mean_completion_times_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Tiempos promedio de entregas
            </Title>
            {dataBarChart.length > 0 ? (
              <CustomBarChart data={dataBarChart} />
            ) : (
              <Paragraph>Sin datos</Paragraph>
            )}
          </div>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.stacked_bar_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.stacked_bar_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Carga de trabajo
            </Title>
            <div>
              <div className={style.execution_status}>
                <div className={style.box_text}>
                  <div
                    className={style.box}
                    onClick={() => handleclickStrongJob(true)}
                    style={{
                      backgroundColor: isPriorityTask
                        ? "var(--secondary_color)"
                        : "#fff",
                    }}
                  ></div>
                  <Title fontSize={14}>Prioridad tareas</Title>
                </div>
                <div className={style.box_text}>
                  <div
                    className={style.box}
                    onClick={() => handleclickStrongJob(false)}
                    style={{
                      backgroundColor: !isPriorityTask
                        ? "var(--secondary_color)"
                        : "#fff",
                    }}
                  ></div>
                  <Title fontSize={14}>Estados de ejecución</Title>
                </div>
              </div>
            </div>
            {dataSnacked.length > 0 ? (
              <>
                {isPriorityTask ? (
                  <BarChartStacked
                    data={dataSnacked}
                    colors={colors}
                    legend={legend}
                  />
                ) : (
                  <BarChartStacked
                    data={dataSnacked}
                    colors={colorsStatus}
                    legend={legendStatus}
                  />
                )}
              </>
            ) : (
              <Paragraph>Sin datos</Paragraph>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TaskPeopleCharts;
