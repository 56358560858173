import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import * as Api from "pages/admin/components/dates-follow-up/api/api";
import { displayLoader, removeProcess } from "./loading";
import {
  DashboardDate,
  FormDatesFollowUp,
  ResponseDatesFollowUp,
} from "pages/admin/components/dates-follow-up/model";
import { DataMultiSelect } from "components/fcdt-multiselect/model";
import { formatDate } from "helpers/formatDate";

type InitialState = {
  isReloadNeeded: boolean;
  dataDates: DashboardDate[];
  dateDatesSelector: DataMultiSelect[];
  dataDateSelected?: DashboardDate;
  idDashboardDate: string;
};
const initialState: InitialState = {
  isReloadNeeded: true,
  dataDates: [],
  dateDatesSelector: [],
  dataDateSelected: undefined,
  idDashboardDate: "",
};
export const slice = createSlice({
  name: "dates-follow-up",
  initialState,
  reducers: {
    setDatesInit: (state) => {
      state.isReloadNeeded = true;
    },
    setDatesSucess: (state, action: PayloadAction<DashboardDate[]>) => {
      state.isReloadNeeded = false;
      state.dataDates = action.payload;
      state.dateDatesSelector = action.payload.map((item) => {
        return {
          uid: item.uid,
          label: `${formatDate(item.start_date)} al ${formatDate(
            item.end_date
          )}`,
        };
      });
      state.dataDateSelected = state.dataDates[0];
    },
    setDatesError: (state) => {
      state.isReloadNeeded = true;
    },
    setSelectdDateRange: (state, action: PayloadAction<DataMultiSelect>) => {
      state.dataDateSelected = state.dataDates.find(
        (item) => item.uid === action.payload.uid
      );
    },
  },
});
export const { setSelectdDateRange } = slice.actions;

export default slice.reducer;

export const getDatesFollowUp = (): AppThunk => async (dispatch, getstate) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    dispatch(slice.actions.setDatesInit());
    const { token } = getstate().auth.dataUser!;
    const response: ResponseDatesFollowUp = await Api.getDatesFollowUp(token);
    dispatch(slice.actions.setDatesSucess(response.dashboardDates));
    dispatch(removeProcess(idProcess));
    return true;
  } catch (error) {
    dispatch(removeProcess(idProcess));
    dispatch(slice.actions.setDatesError());
    console.log({ error });
  }
};

export const setDatesFollowUp =
  (form: FormDatesFollowUp, isEdit?: boolean): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token, collaborator } = getstate().auth.dataUser!;
      const { dataDates } = getstate().datesFollowUp;
      const idCompany = collaborator.id_company;
      const response = !isEdit
        ? await Api.setDatesFollowUp(idCompany, form, token)
        : await Api.updateDatesFollowUp(idCompany, form, dataDates, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log({ error });
    }
  };
