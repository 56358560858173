import React, { useEffect } from "react";
import Reports from "./reports";
import ModalReportContainer from "./components/modal-report/modal-report-container";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormReport } from "./model";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getGoalByID,
  getServiceDocumentReport,
  setshowModalReport,
} from "pages/document-management/store/slices/reports";
import { ROUTES } from "routes/constants";
import { RootState } from "store/store";
import { mappingRoute } from "helpers/formatRoute";

const ReportsContainer = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<FormReport>();

  const idGoal = useParams().id_goal;

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { dataGoal } = useSelector((state: RootState) => {
    return state.documentsManagement.reports;
  });

  useEffect(() => {
    if (idGoal && dataUser) {
      dispatch(getGoalByID(idGoal!));
    }
  }, [idGoal, dataUser]);

  const handleNavigate = (id: number) => {
    if (id === 1) {
      navigate(-1);
      return;
    }

    navigate(
      mappingRoute(
        ROUTES.COLLABORATOR.PARENT,
        mappingRoute(
          ROUTES.DOCUMENT_MANAGEMENT.PARENT,
          ROUTES.DOCUMENT_MANAGEMENT.CHILDRENS.PROCESS_REPORT
        )
      )
    );
  };

  const onSubmit = async (values: FormReport) => {
    if (values.start_date > values.end_date) {
      toast.error("La fecha de finalización debe ser mayor a la de inicio", {duration: 7000, position:"top-center"});
      return;
    }

    const res = await dispatch(
      getServiceDocumentReport(idGoal!, values.start_date, values.end_date)
    );

    if (!res?.ok) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    if (!res.data.report_data) {
      toast.error(
        "Has excedido la cantidad de reportes que puedes generar para el mes actual"
        , {duration: 7000, position:"top-center"} );
      return;
    }

    dispatch(setshowModalReport(true));
  };

  if (!dataGoal) return null;

  return (
    <>
      <Reports
        data={dataGoal}
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        handleNavigate={handleNavigate}
      />
      <ModalReportContainer />
    </>
  );
};

export default ReportsContainer;
