import React from "react";
import LogoCreatic from "assets/logo_creatic.svg";
import LogoTeamPulsa from "assets/logo_teampulsa.webp";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoYoutube,
} from "react-icons/io5";
import style from "../../style/landing-page.module.scss";

const Footer = () => {
  return (
    <footer className={style.container_principal_footer}>
      <section className={style.section_logos}>
        <div className={style.container_logos_creatic_teampulsa}>
          <a href="https://cdtcreatic.com/" target="_blank" rel="noreferrer">
            <img src={LogoCreatic} alt="Logo Creatic" />
          </a>
          <a
            href="https://teampulsa.com/es/home"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LogoTeamPulsa} alt="Logo TeamPulsa" />
          </a>
        </div>
        <div className={style.container_icon_sn_home}>
      
            <a
              href="https://www.instagram.com/cdtcreatic/"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoInstagram color="#fff" size={30} />
            </a>

            <a
              href="https://www.facebook.com/cdtcreatic/"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoFacebook color="#fff" size={30} />
            </a>
            <a
              href="https://www.linkedin.com/company/cdtcreatic/"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoLinkedin color="#fff" size={30} />
            </a>
            <a
              href="https://www.youtube.com/@cdtcreatic390/"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoYoutube color="#fff" size={30} />
            </a>
     
        </div>
      </section>

      <section className={style.container_copy_rigth}>
        <p>
          Copyright © {new Date().getFullYear()} / CDT Creatic
        </p>
      </section>
    </footer>
  );
};

export default Footer;
