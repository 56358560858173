import React, { useEffect, useRef, useState } from "react";
import style from "../../styles/nav-bar.module.scss";
import { FormKeyWords } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { setKeywords } from "store/slices/knowledge";
import { RootState } from "store/store";

const KeyWords = () => {
  const dispatch = useDispatch();

  const [dataKeyWords, setdataKeyWords] = useState<FormKeyWords>({
    keywords: "",
  });

  const { isReloadNeeded } = useSelector((state: RootState) => {
    return state.knowledge;
  });

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>(null);

  const toggleDate = (value: string) => {
    setdataKeyWords({ ...dataKeyWords, keywords: value });
    dispatch(setKeywords(value));
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isReloadNeeded) {
      setdataKeyWords({ keywords: "" });
    }
  }, [isReloadNeeded]);

  return (
    <div ref={ref} className={style.select_dates}>
      <div
        className={style.container_date_keywords_butn}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="fcdt-title-responsive">Palabras claves</span>
      </div>
      {isOpen ? (
        <div
          className={`${style.options_container} ${style.options_container_keyboard}`}
        >
          <div>
            <input
              type="text"
              className={`fcdt-input ${style.input_keyboard}`}
              placeholder="Ej: Compromiso, finalización,..."
              value={dataKeyWords.keywords || ""}
              onChange={(e) => toggleDate(e.target.value)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default KeyWords;
