import React from "react";
import style from "../../style/landing-page.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import { ContactProps } from "pages/landing-page/model";
import Button from "components/buttons/fcdt-button";
import {
  IoCallOutline,
  IoLocationOutline,
  IoMailOutline,
} from "react-icons/io5";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";

const Contact = (props: ContactProps) => {
  const { register, handleSubmit, onSubmit } = props;

  return (
    <div className={style.container_contact}>
      <Title>Contacto</Title>

      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title fontSize={21}>
            ¿Quieres armar tu plan? Contáctanos o Cotiza
          </Title>
          <div className={style.container_form_50}>
            <input
              type="text"
              className="fcdt-input"
              placeholder="Nombre completo"
              required
              minLength={6}
              maxLength={50}
              pattern={REG_EXP.FULL_NAME}
              title={TITLE_REG_EXP.FULL_NAME}
              {...register("name")}
            />

            <input
              type="email"
              className="fcdt-input"
              placeholder="Correo"
              required
              minLength={6}
              maxLength={50}
              pattern={REG_EXP.EMAIL}
              title={TITLE_REG_EXP.EMAIL}
              {...register("email")}
            />
          </div>

          <input
            type="text"
            className="fcdt-input"
            placeholder="Asunto"
            required
            minLength={6}
            maxLength={50}
            pattern={`${REG_EXP.FULL_TEXT_MESSAGE}`}
            title={TITLE_REG_EXP.FULL_TEXT_MESSAGE}
            {...register("subject")}
          />

          <textarea
            placeholder="Mensaje"
            required
            className="fcdt-input"
            minLength={6}
            maxLength={100}
            {...register("message")}
          ></textarea>

          <Button width="40" type="submit">
            Enviar mensaje
          </Button>
        </form>

        <div>
          <Title fontSize={21}>Ponte en contacto</Title>
          <br />
          <div className={style.container_icon_text}>
            <div className={style.box_icon}>
              <IoCallOutline className={style.icon} size={30} />
            </div>
            <Paragraph className={style.paragrap_contant}>
              +57 301 234 5678
            </Paragraph>
          </div>

          <div className={style.container_icon_text}>
            <div className={style.box_icon}>
              <IoMailOutline className={style.icon} size={30} />
            </div>
            <Paragraph className={style.paragrap_contant}>
              rykwa@cdtcreatic.com
            </Paragraph>
          </div>

          <div className={style.container_icon_text}>
            <div className={style.box_icon}>
              <IoLocationOutline className={style.icon} size={30} />
            </div>
            <Paragraph className={style.paragrap_contant}>
              Vereda el Cofre, Parcelación la Margarita, Finca Santa Maria, Casa
              1, Popayán, Cauca 190002, CO
            </Paragraph>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
