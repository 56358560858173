import React from "react";
import FCDTModal from "components/modal-notification/fcdt-modal";
import { ModalTermConditionProps } from "../model";
import style from "../style/register-company.module.scss";
import Button from "components/buttons/fcdt-button";

const ModalTermCondition = (props: ModalTermConditionProps) => {
  const { openModal, handleClose, handleSubmit } = props;

  const children = (
    <div className={style.container_modal_term_condition}>
      <object
        data={`${process.env.PUBLIC_URL}/PoliticadeCookies.pdf`}
        type="application/pdf"
        width="100%"
        height="100%"
        className={style.object}
      >
        {""}
      </object>
      <div className={style.container_buttons}>
        <Button
          className="fcdt_btn_without_background_primary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button className="fcdt-btn_update" onClick={handleSubmit}>
          Aceptar
        </Button>
      </div>
    </div>
  );

  return (
    <FCDTModal
      open={openModal}
      children={children}
      handleClose={handleClose}
      width="45%"
      heigth="90%"
    />
  );
};

export default ModalTermCondition;
