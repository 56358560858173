import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DataMinuteSpeechTree,
  FormContact,
  ResponseDemo,
  SpeechTreeValue,
} from "pages/landing-page/model";
import { AppThunk } from "store/store";
import { displayLoader, removeProcess } from "./loading";
import * as Api from "pages/landing-page/api/api";

type InitialState = {
  idRenderDemo: number;
  showModalDemo: boolean;
  dataDemo?: DataMinuteSpeechTree;
  dataSpeechTree: SpeechTreeValue[];
  dataFileTranscription: any;
  isModalViewMinute: boolean;
  isModalSpeechTree: boolean;
};
const initialState: InitialState = {
  idRenderDemo: 1,
  showModalDemo: false,
  dataDemo: undefined,
  dataSpeechTree: [],
  dataFileTranscription: undefined,
  isModalViewMinute: false,
  isModalSpeechTree: false,
};
export const slice = createSlice({
  name: "landingPage",
  initialState,
  reducers: {
    setIdRenderDemo: (state, action: PayloadAction<number>) => {
      state.idRenderDemo = action.payload;
    },
    setModalDemo: (state, action: PayloadAction<boolean>) => {
      state.showModalDemo = action.payload;
    },
    setdataFileTranscription: (state, action: PayloadAction<any>) => {
      state.dataFileTranscription = action.payload;
    },
    setDataDemoSucess: (state, action: PayloadAction<DataMinuteSpeechTree>) => {
      state.dataDemo = action.payload;
      state.dataSpeechTree = action.payload.speech_tree_values;
    },
    setIsModalViewMinute: (state, actions: PayloadAction<boolean>) => {
      state.isModalViewMinute = actions.payload;
    },
    setIsModalSpeechTree: (state, actions: PayloadAction<boolean>) => {
      state.isModalSpeechTree = actions.payload;
    },
    setDataSpeechTreeSucess: (
      state,
      action: PayloadAction<SpeechTreeValue[]>
    ) => {
      state.dataSpeechTree = action.payload;
    },
  },
});
export const {
  setIdRenderDemo,
  setModalDemo,
  setdataFileTranscription,
  setIsModalViewMinute,
  setIsModalSpeechTree,
  setDataSpeechTreeSucess,
} = slice.actions;

export default slice.reducer;

export const getDemoInformation =
  (): AppThunk => async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { dataFileTranscription } = getState().landingPage;
      const response: ResponseDemo = await Api.getDemoInformation(
        dataFileTranscription
      );
      dispatch(slice.actions.setDataDemoSucess(response.data));
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
    }
  };

export const sendEmailContact =
  (form: FormContact): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response = await Api.sendEmailContact(form);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
    }
  };
