import { combineReducers } from "@reduxjs/toolkit";
import Documents from "./slices/documents";
import SpeechTree from "./slices/speech-tree";
import Recomendations from "./slices/recomendations";
import Reports from "./slices/reports";

export default combineReducers({
  documents: Documents,
  recomendations: Recomendations,
  speechTree: SpeechTree,
  reports: Reports,
});
