import React, { useEffect } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import UseDataFilter from "common/hooks/use-data-filter";
import { NUMBER_ITEMS_PAGINATION } from "common/contants";
import usePagination from "common/hooks/use-pagination";
import TableObjetives from "./table-objetives";
import {
  getColaboratorGolas,
  setReloadProcessUsers,
} from "pages/collaborator/store/slices/process";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const TableObjetivesContainer = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const path = useLocation().pathname.split("/");

  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });

  const { dataGolas, nameProcess } = useSelector((state: RootState) => {
    return state.colaborator.process;
  });

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const value = filterParams?.search;
  const searchTerm = value ? value : "";

  const idProcess = params.id_process!;

  useEffect(() => {
    if (idProcess && dataUser) {
      dispatch(getColaboratorGolas(idProcess));
    }
  }, [idProcess, dataUser, dispatch]);

  const handleNavigate = () => {
    navigate(-1);
    dispatch(setReloadProcessUsers());
  };

  const { filtered } = UseDataFilter({
    data: dataGolas,
    searchValue: searchTerm,
  });

  const { currentPage, totalPages, itemsToShow, setCurrentPage } =
    usePagination({
      currentPage: 1,
      filterData: filtered,
      numberItems: NUMBER_ITEMS_PAGINATION,
    });

  return (
    <div className="fcdt-center">
      <TableObjetives
        data={itemsToShow}
        nameProcess={nameProcess}
        handleNavigate={handleNavigate}
        pathRef={path[2]}
      />
      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        maxWidth={100}
      />
    </div>
  );
};

export default TableObjetivesContainer;
