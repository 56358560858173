export const APIURL = process.env.REACT_APP_BASE_DOMAIN; // Develpment server
// export const APIURL = process.env.REACT_APP_BASE_URL; // Develpment server
// export const APIURL = process.env.REACT_APP_BASE_URL_LOCAL; //Local server
export const PERSONS_PORT = process.env.REACT_APP_PERSONS;
export const AUTH_PORT = process.env.REACT_APP_LOGIN_PORT;
export const PLANS_PORT = process.env.REACT_APP_PLANS_PORT;
export const COMPANIES_PORT = process.env.REACT_APP_COMPANIES_PORT;
export const PAYMENTS_PORT = process.env.REACT_APP_PAYMENTS;
export const PROCESSES_PORT = process.env.REACT_APP_PROCESSES;
export const EVENTS_PORT = process.env.REACT_APP_EVENTS;
export const REACT_APP_DEPLOYMENT_DEV = process.env.REACT_APP_DEPLOYMENT_DEV;

