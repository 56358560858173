import React from "react";
import style from "../nav-bar/styles/nav-bar.module.scss";
import SelectorsContainer from "./components/selectors/selectors-container";
import FormSearchContainer from "./components/form-searh/form-search-container";

const NavBarKnowledge = () => {
  return (
    <div className={style.principal_nav_bar}>
      <SelectorsContainer />
      <FormSearchContainer />
    </div>
  );
};

export default NavBarKnowledge;
