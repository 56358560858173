import Title from "components/fcdt-title/fcdt-title";
import style from "./style/register-company.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Button from "components/buttons/fcdt-button";
import { RegisterProps } from "./model";
import Link from "components/fcdt-link/fcdt-link";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { APIKEY_RECAPCHA } from "pages/login/constants";
import { useState } from "react";
import { handleKeyDownPhone } from "helpers/keyDownNumber";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";

const RegisterCompany = (props: RegisterProps) => {
  const {
    register,
    handleSubmit,
    onSubmit,
    handleOpenModal,
    valueCheck,
    handleChange,
  } = props;
  const [isCaptcha, setIsCaptcha] = useState<any>("");
  const navigate = useNavigate();
  return (
    <div className={style.container_register}>
      <section className={style.container_title_form}>
        <Title
          children="Completa el registro de tu empresa"
          fontWeight={700}
          fontSize={25}
        />
        <Paragraph
          children="Para asociar tu cuenta, necesitas suministrar 
          los datos básicos de tu empresa."
          className={style.paragrap}
          fontWeight={300}
          width="30"
        />

        <form
          className={style.container_form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="text"
            className="fcdt-input"
            placeholder="Nombre de la empresa"
            required
            minLength={3}
            maxLength={50}
            pattern={REG_EXP.COMPANY_NAME}
            title={TITLE_REG_EXP.COMPANY_NAME}
            {...register("name")}
          />

          <input
            type="email"
            className="fcdt-input"
            placeholder="Correo de la empresa"
            required
            maxLength={90}
            minLength={6}
            title={TITLE_REG_EXP.EMAIL}
            {...register("email")}
          />

          <input
            type="tel"
            className="fcdt-input"
            placeholder="Número de contacto"
            required
            maxLength={15}
            minLength={8}
            onKeyDown={(e) => handleKeyDownPhone(e)}
            {...register("phone_number")}
          />

          <select className="fcdt-select" required {...register("platform")}>
            <option value="" hidden>
              Plataforma de comunicación y colaboración
            </option>
            <option value="1">Microsoft Teams</option>
            <option value="2">Google Meet</option>
          </select>

          <ReCAPTCHA
            sitekey={APIKEY_RECAPCHA}
            onChange={(value) => setIsCaptcha(value)}
          />
          {isCaptcha === "" && (
            <p className="message_error">El captcha es requerido</p>
          )}

          <div className={style.container_term_conditions}>
            <input
              type="checkbox"
              required
              checked={valueCheck}
              onChange={handleChange}
            />
            <div className={style.container_text}>
              <Paragraph fontSize={12} fontWeight={300}>
                Al seleccionar, significa que estas de acuerdo con nuestros
              </Paragraph>
              <Paragraph fontSize={12}>
                <strong onClick={handleOpenModal}>
                  terminos de servicio y politicas de privacidad
                </strong>{" "}
              </Paragraph>
            </div>
          </div>

          <Button
            children="Continuar"
            type="submit"
            disabled={isCaptcha === "" ? false : false}
          />

          <Paragraph className={style.text_cancel_process}>
            Desea{" "}
            <Link
              children="Cancelar"
              onClick={() => navigate("/home")}
              color="#EE3537"
              fontWeight={500}
            />{" "}
            el registro
          </Paragraph>
        </form>
      </section>
    </div>
  );
};

export default RegisterCompany;
