import { combineReducers } from "@reduxjs/toolkit";
import Users from "./slices/users";
import Companies from "./slices/companies";
import Payments from "./slices/payments";

export default combineReducers({
  users: Users,
  companies: Companies,
  payments: Payments,
});
