import Title from "components/fcdt-title/fcdt-title";
import GaugeChart from "react-gauge-chart";
import style from "./style/gauge-state-chart.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";

type GaugeChartSpeedProps = {
  percentage: number;
  notLegend?: boolean;
};

const GaugeStateChart = (props: GaugeChartSpeedProps) => {
  return (
    <div className={style.container_gauge}>
      <section className={style.container_chart}>
        <GaugeChart
          id="gauge-chart5"
          arcsLength={[0.5, 0.5]}
          colors={["#EE3537", "#00DE97"]}
          percent={props.percentage}
          arcPadding={0}
          hideText={true}
          cornerRadius={0}
          needleBaseColor="#2F0084"
          needleColor="#2F0084"
          arcWidth={0.4}
        />
      </section>

      <div className={style.container_legend}>
        <Title fontSize={12}>Pesimista</Title>
        <Title fontSize={12}>Optimista</Title>
      </div>

      <Paragraph
        fontSize={12}
        fontWeight={300}
        className={style.paragraph_description}
      >
        Cuanto más inclinada esté la flecha hacia el lado optimista, mayor es la
        posibilidad de que la tarea se entregue a tiempo.
      </Paragraph>
    </div>
  );
};

export default GaugeStateChart;
