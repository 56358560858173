import Title from "components/fcdt-title/fcdt-title";
import FCDTModal from "components/modal-notification/fcdt-modal";
import GanttChart from "pages/document-management/components/reports/components/gantt-chart/gantt-charts";
import { InfoFromTask } from "pages/document-management/components/reports/model";
import React from "react";
import { useDispatch } from "react-redux";
import { setShowGantt } from "store/slices/knowledge";

const GanttDiagram = (props: { data: InfoFromTask[] }) => {
  const dispatch = useDispatch();

  const rows: any = props.data.map((item) => {
    const data = [
      item.task_name.replace(/\s/g, ""),
      item.task_name,
      null,
      new Date(item.task_start_date),
      new Date(item.task_due_date),
      null,
      100,
      null,
    ];

    return data;
  });

  const handleCloseModal = () => {
    dispatch(setShowGantt(false));
  };

  const children = (
    <div>
      <Title fontSize={20} style={{ marginBottom: 10 }}>
        Temas tratados
      </Title>
      <GanttChart rows={rows} />
    </div>
  );

  return (
    <FCDTModal
      open={true}
      children={children}
      handleClose={handleCloseModal}
      width="50%"
    />
  );
};

export default GanttDiagram;
