import style from "../styles/body-follow-up.module.scss";
import TitleCharts from "../title-chars/title-charts";
import GanttChart from "pages/document-management/components/reports/components/gantt-chart/gantt-charts";
import { InfoFromTask } from "pages/document-management/components/reports/model";
import { formatGanttData } from "helpers/formatGanttData";
import Title from "components/fcdt-title/fcdt-title";
import PieChartBar from "../../custom-charts/pie-bar/pie-bar";
import { Data } from "react-minimal-pie-chart/types/commonTypes";
import FunnelChart from "../../custom-charts/funnel/funnel-chart";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import ButtonFloatContainer from "../../button_float/button-float-container";

const EventsTasksChars = () => {
  const { dataDashboard, collaborators, dataDashboardRecomendation } =
    useSelector((state: RootState) => {
      return state.followup;
    });

  if (!dataDashboard) return null;

  const dataGantt: InfoFromTask[] = dataDashboard.data_gantt_chart
    ? dataDashboard.data_gantt_chart.map((item) => {
        return {
          task_name: item.task_name,
          task_start_date: item.task_start_date,
          task_due_date: item.task_due_date,
        };
      })
    : [];

  const rows = formatGanttData(dataGantt);

  const dataFunnel = dataDashboard.data_tasks_execution_statuses_chart
    ? dataDashboard.data_tasks_execution_statuses_chart
    : [];

  const dataPie: Data = dataDashboard.data_tasks_priorities_chart
    ? dataDashboard.data_tasks_priorities_chart.map((item) => {
        return {
          title: item.label,
          value: item.value * 100,
          color: item.color,
        };
      })
    : [];

  return (
    <div className={style.container_goal_charts}>
      <TitleCharts
        title="Progreso de tareas"
        subtitle={`Todos los participantes (${collaborators.length})`}
      />
      <div
        className={`${style.box_graph_shadown} ${style.box_graph_shadown_100}`}
      >
        {dataDashboardRecomendation?.gantt_chart_recommendation && (
          <ButtonFloatContainer
            data={dataDashboardRecomendation.gantt_chart_recommendation}
          />
        )}
        <Title fontSize={17} fontWeight={700} className={style.title_box}>
          Diagrama de proyecto
        </Title>
        {rows.length > 0 ? (
          <GanttChart rows={rows} />
        ) : (
          <Paragraph>No hay tareas asociadas a este evento</Paragraph>
        )}
      </div>
      <div className={style.container_two_chats_not_title}>
        <div className={style.box_graph_shadown}>
          {dataDashboardRecomendation?.tasks_execution_statuses_chart_recommendation && (
            <ButtonFloatContainer
              data={
                dataDashboardRecomendation.tasks_execution_statuses_chart_recommendation
              }
            />
          )}

          <Title fontSize={17} fontWeight={700} className={style.title_box}>
            Estados de ejecución
          </Title>
          {dataFunnel.length > 0 ? (
            <FunnelChart data={dataFunnel} />
          ) : (
            <Paragraph>No hay tareas asociadas a este evento</Paragraph>
          )}
        </div>
        <div className={style.box_graph_shadown}>
          {dataDashboardRecomendation?.tasks_priorities_chart_recommendation && (
            <ButtonFloatContainer
              data={
                dataDashboardRecomendation.tasks_priorities_chart_recommendation
              }
            />
          )}
          <Title fontSize={17} fontWeight={700} className={style.title_box}>
            Prioridad
          </Title>
          <PieChartBar data={dataPie} legend="Prioridad de tareas" />
        </div>
      </div>
    </div>
  );
};

export default EventsTasksChars;
