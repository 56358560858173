import Title from "components/fcdt-title/fcdt-title";
import { useEffect } from "react";
import style from "./style/edit-document-container.module.scss";
import FirstFormDocumentContainer from "./components/first-form/first-form-document-container";
import SecondFormDocumentContainer from "./components/second-form/second-form-document-container";
import ModalViewDocumentContainer from "../modal-view-document/modal-view-document-container";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useParams } from "react-router-dom";
import { getDocumentById } from "pages/document-management/store/slices/documents";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";

const EditDocumentContainer = () => {
  const dispatch: any = useDispatch();
  const params = useParams();

  const { actualIdFormDocument } = useSelector((state: RootState) => {
    return state.documentsManagement.documents;
  });
  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const render = () => {
    switch (actualIdFormDocument) {
      case 1:
        return <FirstFormDocumentContainer />;
      case 2:
        return <SecondFormDocumentContainer />;
    }
  };

  const idMunutes: string | undefined = params.id_minute;

  useEffect(() => {
    if (dataUser && idMunutes) {
      dispatch(getDocumentById(idMunutes!, true));
    }
  }, [dataUser, idMunutes, dispatch]);

  return (
    <div className={style.container_principal}>
      <Title fontSize={20} fontWeight={600}>
      Acta generada
      </Title>

      <Paragraph fontWeight={300} fontSize={12}>
        Editar únicamente los campos habilitados
      </Paragraph>
      {/* <DownloadViewDocumentContainer /> */}
      {/* <div className={style.minute_information}>Información acta</div> */}

      {render()}
      <ModalViewDocumentContainer />
    </div>
  );
};

export default EditDocumentContainer;
