import { AppThunk } from "store/store";
import { displayLoader, removeProcess } from "./loading";
import * as Api from "pages/status-payment/api/api";
import { createSlice } from "@reduxjs/toolkit";

type InitialState = {};
const initialState: InitialState = {};
export const slice = createSlice({
  name: "suscription",
  initialState,
  reducers: {},
});

export default slice.reducer;

export const getStatusPayment =
  (idPayment: string, idStatus: string, ePaycoRef: string): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response = await Api.getStatusPayment(
        idPayment,
        idStatus,
        ePaycoRef
      );

      dispatch(removeProcess(idProcess));
      return response;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
