import axios from "axios";
import { FormLoginType } from "../model";
// import { APIURL, AUTH_PORT, PERSONS_PORT } from "config/APIURL";
import { APIURL } from "config/APIURL";

import toast from "react-hot-toast";

export const loginRequestPost = async (form: FormLoginType) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/auth`,
      data: form,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const verifyToken = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/usuarios/verify_token/${token}`,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkTokenExpiration = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/auth/${token}`,
    });
    return response?.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      toast.error("Tu sesión ha expirado, inicia nuevamente", {duration: 7000, position:"top-center"});
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  }
};

export const verifyTokenRestorePassword = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/usuarios/password_recovery/${token}`,
    });
    return response?.data;
  } catch (error: any) {
    return error.response?.data;
  }
};
