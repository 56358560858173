import React from "react";
import ModalTermCondition from "./modal-term-condition";
import { useDispatch, useSelector } from "react-redux";
import {
  setshowModalTermConditions,
  setvalueCondition,
} from "store/slices/register";
import { RootState } from "store/store";

const ModalTermConditionContainer = () => {
  const dispatch = useDispatch();

  const { showModalTermConditions } = useSelector((state: RootState) => {
    return state.register;
  });

  const handleCloseModal = () => {
    dispatch(setvalueCondition(false));
    dispatch(setshowModalTermConditions(false));
  };

  const handleSubmit = () => {
    dispatch(setvalueCondition(true));
    dispatch(setshowModalTermConditions(false));
  };

  return (
    <ModalTermCondition
      openModal={showModalTermConditions}
      handleClose={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalTermConditionContainer;
