import Title from "components/fcdt-title/fcdt-title";
import style from "../styles/body-follow-up.module.scss";

type TitleChartsProps = {
  title: string;
  subtitle: string;
};

const TitleCharts = (props: TitleChartsProps) => {
  return (
    <div className={style.container_title_body}>
      <Title fontSize={18} fontWeight={600}>
        {props.title}
      </Title>
      <Title fontSize={20} fontWeight={700} color="var(--secondary_color)">
        {props.subtitle}
      </Title>
    </div>
  );
};

export default TitleCharts;
