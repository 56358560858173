import React from "react";
import Menu from "./components/menu/menu";
import About from "./components/about/about";
import style from "./style/landing-page.module.scss";
import Services from "./components/services/services";
import Characteristics from "./components/characteristics/characteristic";
import Plans from "./components/plans/plans";
import Footer from "./components/footer/footer";
import DemoContainer from "./components/demo/demo-container";
import ContactContainer from "./components/contact/contact-container";

const LandingPage = () => {
  return (
    <>
      <Menu />
      <div className={style.margin_sections}></div>
      <About id="section1" />
      <Services id="section2" />
      <DemoContainer id="section3" />
      <Characteristics id="section4" />
      <Plans id="section5" />
      <ContactContainer id="section6" />
      <Footer />
    </>
  );
};

export default LandingPage;
