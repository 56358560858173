import Button from "components/buttons/fcdt-button";
import {
  IoArrowBackOutline,
  IoCalendarNumberOutline,
  IoCloseOutline,
  IoCreateOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { AddObjetivesProps } from "../../model";
import FCDTToggle from "components/fcdy-toggle/fcdt-toggle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Title from "components/fcdt-title/fcdt-title";
import style from "./style/add-objetive.module.scss";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import toast from "react-hot-toast";
import { useState } from "react";

import { formatDate } from "helpers/formatDate";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const AddTaks = (props: AddObjetivesProps) => {

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const is_admin = dataUser?.collaborator.is_admin;
  const is_leader = dataUser?.collaborator.is_leader;
  const disabled_autentications = is_admin || is_leader;
  const [useStates, setuseStates] = useState(true)


  if (!disabled_autentications && useStates) {
    setuseStates(false);
    toast.success("No tienes los permisos necesarios para crear nuevas tareas. Actualmente solo tienes permiso para editar las tareas de las que eres responsable.", { duration: 7000, position: "top-center" });
  }


  const {
    handleComeBack,
    dataToggle,
    handleChangeToggle,
    handleShowModalDates,
    dataAllTasks,
    taskSelected,
    dataAllColaborator,
    handleSelectTask,
    handleChangeAutocompleted,
    dataColaboratorSelected,
    handleDeleteColaborator,
    handleSubmit,
    onSubmit,
    register,
    handleResetDataAddTask,
    subTasks,
    handleKeyPress,
    handleChangeTask,
    handleEditCloseDelete,
    handleGeneralKeyPress,
  } = props;

  return (
    <div className={style.container_principal}>
      <div className={style.toggle}>
        <span onClick={handleComeBack}>
          <IoArrowBackOutline className={style.icon} fontSize={25} /> Atrás
        </span>
      </div>
      <form
        className={style.process_container}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={style.content}>
          <section>
            <h1>{`${taskSelected ? "Actualizar" : "Crear"} tarea`}</h1>
            <p style={{fontSize:"15px", marginTop:"-3%"}}>Completa todos los campos para añadir una tarea nueva</p>
            <div>
              <div className={style.content_left}>
                <div className={!disabled_autentications ? style.disabled : style.content_left}>
                  <textarea
                    placeholder="Nombre de tarea"
                    className="fcdt-input"
                    pattern="^(?!.*(\d){5,})[a-zA-Z0-9áéíóúÁÉÍÓÚüÜ.:%!¡¿'?ñÑ,\s]*$"
                    required
                    minLength={6}
                    maxLength={150}
                    disabled={!dataToggle.active}
                    {...register("taks_name")}
                  />
                  <textarea
                    placeholder="Descripción"
                    className="fcdt-input"
                    pattern="^(?!.*(\d){5,})[a-zA-Z0-9áéíóúÁÉÍÓÚüÜ.:%!¡¿'?ñÑ,\s]*$"
                    required
                    minLength={6}
                    maxLength={400}
                    disabled={!dataToggle.active}
                    {...register("task_description")}
                  />

                  <select
                    {...register("task_priority")}
                    required
                    className="fcdt-select"
                    disabled={taskSelected?.execution_status === 6 ? true : false}
                  >
                    <option hidden value="">
                      Nivel de prioridad
                    </option>
                    <option value="1">Urgente</option>
                    <option value="2">Importante</option>
                    <option value="3">Media</option>
                    <option value="4">Baja</option>
                  </select>
                </div>
              </div>
              <div className={style.content_center}>

                <div className={!disabled_autentications ? style.disabled : style.content_left}>
                  
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    disabled={!dataToggle.active}
                    options={dataAllColaborator}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField sx={{ fontSize: "5px", }} {...params} label="Añadir responsables" />
                    )}
                    onChange={handleChangeAutocompleted}
                    value={{ label: "", uid: "" }}
                  />
                </div>
                <ul>
                  {!taskSelected ? dataColaboratorSelected.map((colaborator, index) => {
                    return (
                      <li key={index}>
                        <span>{colaborator.label}</span>{" "}
                        <IoCloseOutline
                          className="fcdt-icon"
                          onClick={() => handleDeleteColaborator(colaborator)}
                        />
                      </li>
                    );
                  }) : dataColaboratorSelected.map((colaborator, index) => {
                    return (
                      <li key={index}>
                        <span>{colaborator.label}</span>{" "}
                        <IoCloseOutline
                          className="fcdt-icon"
                          style={{ display: !disabled_autentications ? "none" : "block" }}
                          onClick={() => handleDeleteColaborator(colaborator)}
                        />
                      </li>
                    );
                  })
                  }
                </ul>


                {/* <div className={!disabled_autentications ? style.disabled : style.content_left} style={{ width: "100%" }}></div> */}
                <div className={style.content_left} style={{ width: "100%" }}>
                  {/* <label htmlFor="calendar" className={style.label_calendar}>
                    Fechas importantes
                  </label> */}
                  {disabled_autentications ?
                    <button
                      className={style.button_calendar}
                      type="button"
                      onClick={handleShowModalDates}
                      disabled={!dataToggle.active}
                    >
                      <IoCalendarNumberOutline className={style.icon} /> Fechas de seguimiento
                    </button> :
                    <div style={{

                      display: taskSelected ? "flex" : "none",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}>
                      <span>Fechas de seguimiento</span>
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton>
                            Inicio: {taskSelected?.task_dates[0]?.task_start_date ? formatDate(taskSelected?.task_dates[0]?.task_start_date) : ""}
                          </ListItemButton>
                        </ListItem><ListItem disablePadding>
                          <ListItemButton>
                            Probable: {taskSelected?.task_dates[0]?.task_probable_date ? formatDate(taskSelected?.task_dates[0]?.task_start_date) : ""}
                          </ListItemButton>
                        </ListItem><ListItem disablePadding>
                          <ListItemButton>
                            Optimista: {taskSelected?.task_dates[0]?.task_optimistic_date ? formatDate(taskSelected?.task_dates[0]?.task_optimistic_date) : ""}
                          </ListItemButton>
                        </ListItem><ListItem disablePadding>
                          <ListItemButton>
                            Vencimiento: {taskSelected?.task_dates[0]?.task_due_date ? formatDate(taskSelected?.task_dates[0]?.task_due_date) : ""}
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </div>}
                  <div style={{ display: disabled_autentications ? "block" : "none" }}>
                    {taskSelected && (
                      <FCDTToggle
                        data={dataToggle}
                        handleChange={handleChangeToggle}
                        width={150}
                        className={style.toggle_switch}
                      />
                    )}
                  </div>

                </div>
              </div>
            </div>
          </section>
          <div className={style.content_right}>
            <h1>Tareas creadas</h1>
            <p style={{fontSize:"15px", marginTop:"-3%"}}>Selecciona la tarea para ver los detalles</p>
            <ul className={style.content_checkbox}>
              {dataAllTasks.length
                ? dataAllTasks?.map((task, index) => {
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        onClick={() => handleSelectTask(task)}
                        checked={task.isSelected}
                      />

                      {task?.task_name}
                    </li>
                  );
                })
                : null}
            </ul>

            <select
              {...register("task_state")}
              required
              className="fcdt-select"
              disabled={taskSelected?.execution_status === 6 || taskSelected === undefined ? true : false}
            >
              <option hidden value="">
                Estado de la tarea
              </option>
              <option value="1">No iniciada</option>
              <option value="2">En curso</option>
              <option value="3">Atrasada</option>
              <option value="4">En validación</option>
              <option value="5">Completada</option>
              <option value="6">Cancelada</option>
            </select>
            <h1 style={{ display: disabled_autentications || taskSelected === undefined ? "none" : "block" }} className={style.flash_text}>Actualiza el estado de la tarea👆</h1>
          </div>

        </div>

        <div className={style.container_subtask} style={taskSelected ? { width: "920px", marginLeft: "5px" } : {}}>
          <Title fontSize={18}>Subtareas</Title>
          <div className={style.container_show_sub_task}>
            {subTasks.map((item, index) => {
              return (
                <div key={index}>
                  {item.isEdit ? (
                    <input
                      type="text"
                      className="fcdt-input"
                      placeholder="Subtarea"
                      minLength={6}
                      maxLength={1000}
                      pattern={REG_EXP.PROCESS_DESCRIPTIONS}
                      title={TITLE_REG_EXP.SubTask_DESCRIPTIONS}
                      value={item.name}
                      onChange={(e) => handleChangeTask(e, item)}
                      onKeyDownCapture={(e) => handleGeneralKeyPress(e)}
                    />
                  ) : (
                    <li>{item.name}</li>
                  )}

                  <div className={style.container_icons}>
                    {!item.isEdit ? (
                      <IoCreateOutline
                        size={25}
                        className={style.icon}
                        onClick={() => handleEditCloseDelete(item, 3)}
                      />
                    ) : (
                      <IoCloseOutline
                        size={25}
                        className={style.icon}
                        onClick={() => handleEditCloseDelete(item, 3)}
                      />
                    )}

                    <IoTrashOutline
                      size={25}
                      className={style.icon}
                      onClick={() => handleEditCloseDelete(item, 4)}
                    />
                  </div>
                </div>
              );
            })}

            <div>
              <input
                type="text"
                className={`fcdt-input ${style.input_add_sub_task}`}
                placeholder="Agregar subtarea"
                {...register("subtask")}
                onKeyDownCapture={(e) => handleKeyPress(e)}
              />
            </div>
          </div>
        </div>

        <div className={style.content_buttons}>
          <Button
            className="btn_cancel"
            children={taskSelected ? "Cancelar" : "Atrás"}
            width="40"
            type="button"
            onClick={taskSelected ? handleResetDataAddTask : handleComeBack}
          />
          <Button
            children={`${taskSelected ? "Guardar" : "Añadir tarea"}`}
            width="40"
            className={
              taskSelected ? `fcdt_btn_secondary ${style.button_update}` : ""
            }
            disabled={
              taskSelected?.execution_status === 6 && !dataToggle.active ||
              !taskSelected && !disabled_autentications
            }
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default AddTaks;
