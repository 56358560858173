import axios from "axios";

// import { APIURL, PERSONS_PORT } from "config/APIURL";
import { APIURL } from "config/APIURL";

import { FormRegisterType } from "../model";

export const registerRequestPost = async (
  form: FormRegisterType,
  idCompany: string
) => {
  const data = {
    name: form.name,
    email: form.email,
    name_in_meeting_platform: form.meetingName,
    password: form.password,
    confirmPassword: form.confirmPassword,
    id_company: idCompany,
  };
  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/usuarios`,
      data: data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response);
    return error.response?.data;
  }
};
