import React from "react";
import LinkNavProps from "./model";
import { Link } from "react-router-dom";
import style from "./style/link-nav.module.scss";

const LinkNav: React.FC<LinkNavProps> = (props) => {
  const { children, url, isActive, isDisabled } = props;

  return (
    <Link
      to={url}
      className={`${style.link} ${isActive && style.active} ${
        isDisabled && style.disabled
      }`}
    >
      {children}
    </Link>
  );
};

export default LinkNav;
