import React, { useState } from "react";
import style from "./style/profile-nav.module.scss";
import { RootState } from "store/store";
import Icon from "assets/backgroud_points.webp";
import { Button, Menu, MenuItem } from "@mui/material";
import { setOpenNewPassword } from "store/slices/login";
import { useDispatch, useSelector } from "react-redux";
import { NewPassword } from "components/modals/components/new-password";
import Dialog from "components/modals/dialog";

const ProfileNav = () => {

  const dispatch = useDispatch();
  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { isOpenNewPassword } = useSelector((state: RootState) => {
    return state.login;
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {   
    dispatch(setOpenNewPassword(false));
  };

  const handleChangeLoadImage = async (e: any) => {
    handleClose();
    if (e.target.files[0].size < 1000000) {
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        // const res = await dispatch(
        //   // setPhotoProfileAdviser(
        //   //   e.target.files,
        //   //   pathProfileImageAdviser.length === 0 ? 1 : 2
        //   // )
        // );
        // if (!res) {
        //   // dispatch(
        //   //   getAlertError("Error al cambiar foto de perfil, intenta más tarde")
        //   // );
        //   return;
        // }
        localStorage.setItem(
          "@Credentials",
          JSON.stringify({ ...dataUser, is_photo_profile: true })
        );
        // dispatch(
        //   setdataUser({
        //     id_user: dataUser?.id_user!,
        //     access_token: dataUser?.access_token!,
        //     id_role: dataUser?.id_role!,
        //     user: dataUser?.user!,
        //     user_name: "",
        //     mail:cookies.get("email"),
        //     welcome: dataUser?.welcome!,
        //     is_photo_profile: true,
        //     id_service: dataUser?.id_service!,
        //   })
        // );
      } else {
        // dispatch(
        // getAlertError("Formato no permitido, cargue una imagen en png o jpg")
        // );
        return;
      }
    } else {
      // dispatch(getAlertError("El archivo pesa más de 3Mb"));
    }
  };

  const handleChangePassword = async () => {
    dispatch(setOpenNewPassword(true));
    handleClose();
  };
  return (
    <div>
      <header className={style.profile}>
        <p>
          {dataUser?.collaborator
            ? dataUser.collaborator.name_in_meeting_platform
            : ""}
        </p>
        <Button onClick={handleClick}>
          <img src={Icon} alt="Profile" width={52} height={52} />
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem>
            <div className="container-principal-items-photo-adviser">
              <div className="container-item-photo-adviser">Cargar Foto</div>
              <div className="container-item-photo-adviser">
                <input
                  type="file"
                  accept="image/jpeg,image/png"
                  name="load-photo-adviser"
                  className="container-input-item-photo-adviser"
                  onChange={(e) => {
                    handleChangeLoadImage(e);
                  }}
                />
              </div>
            </div>
          </MenuItem>
          <MenuItem onClick={handleChangePassword}>Cambiar contraseña</MenuItem>
        </Menu>
      </header><div style={{ padding: "10px" }}>
        <Dialog isOpen={isOpenNewPassword} onClose={handleCloseDialog}>
          <NewPassword isLogin={true}/>
        </Dialog>
      </div>
    </div>
  );
};

export default ProfileNav;
