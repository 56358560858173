import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import * as API from "pages/register/api/api";
import * as ApiCompany from "pages/register-company/api/api";
import { FormRegisterType } from "pages/register/model";
import { FormRegisterCompany } from "pages/register-company/model";
import { displayLoader, removeProcess } from "./loading";

type InitialState = {
  registerDataUser?: FormRegisterType;
  showModalTermConditions: boolean;
  valueCondition: boolean;
};
const initialState: InitialState = {
  registerDataUser: undefined,
  showModalTermConditions: false,
  valueCondition: false,
};

export const slice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setRegisterDataUser: (state, action) => {
      state.registerDataUser = action.payload;
    },
    setshowModalTermConditions: (state, action: PayloadAction<boolean>) => {
      state.showModalTermConditions = action.payload;
    },
    setvalueCondition: (state, action: PayloadAction<boolean>) => {
      state.valueCondition = action.payload;
    },
  },
});

export const {
  setRegisterDataUser,
  setshowModalTermConditions,
  setvalueCondition,
} = slice.actions;

export default slice.reducer;

export const registerCompany =
  (values: FormRegisterCompany): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response = await ApiCompany.registerCompany(values);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const registerRequestPost =
  (values: FormRegisterType, idCompany: string): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response = await API.registerRequestPost(values, idCompany);
      dispatch(slice.actions.setRegisterDataUser(response));
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
