import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getImageSpeechTree,
  getSpeechTreeByID,
} from "pages/document-management/store/slices/speech-tree";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store/store";
import Speech from "./speech";
import toast from "react-hot-toast";
import { setIsModalSpeechTree } from "store/slices/landingPage";
import { getSpeechTreeByIdMeeting } from "store/slices/knowledge";
import ModalSpeechTreeContainer from "pages/landing-page/components/demo/components/modal-speech-tree/modal-speech-tree-container";

const SpeechTreeByIdContainer = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { speechTreeSelected } = useSelector((state: RootState) => {
    return state.documentsManagement.speechTree;
  });

  const idTree: string | undefined = params.id_tree;

  useEffect(() => {
    if (dataUser && idTree) {
      dispatch(getSpeechTreeByID(idTree));
    }
  }, [idTree, dataUser, dispatch]);

  const handleNavigate = () => {
    navigate(-1);
  };

  const handleOpenDialog = async (idMeeting: string) => {
    const res = await dispatch(getSpeechTreeByIdMeeting(idMeeting));

    if (!res?.ok) {
      toast.error("No se encontraron transcipciones asociadas a esta reunión", {duration: 7000, position:"top-center"});
      return;
    }

    dispatch(setIsModalSpeechTree(true));
  };

  const handleDownLoadSpeechTree = () => {
    dispatch(getImageSpeechTree(true));
  };

  if (!speechTreeSelected) return null;

  return (
    <>
      <Speech
        dataSpeechTree={speechTreeSelected}
        handleNavigate={handleNavigate}
        handleOpenDialog={handleOpenDialog}
        handleDownLoadSpeechTree={handleDownLoadSpeechTree}
      />
      <ModalSpeechTreeContainer />
    </>
  );
};

export default SpeechTreeByIdContainer;
