import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import { displayLoader, removeProcess } from "./loading";
import {
  Plan,
  PlanSelected,
  ResponseGetPlanByID,
  ResponseGetPlans,
} from "pages/plans/model";
import * as Api from "pages/plans/api/api";

type InitialState = {
  isRealoadNeeded: boolean;
  allPlans: Plan[];
  planSelected?: PlanSelected;
};
const initialState: InitialState = {
  isRealoadNeeded: true,
  allPlans: [],
  planSelected: undefined,
};
export const slice = createSlice({
  name: "suscription",
  initialState,
  reducers: {
    setAllPlansSucess: (state, action: PayloadAction<Plan[]>) => {
      state.allPlans = action.payload;
      state.isRealoadNeeded = false;
    },
    setPlansSelectedSucess: (state, action: PayloadAction<PlanSelected>) => {
      state.planSelected = action.payload;
    },
  },
});

export default slice.reducer;

export const getAllPlans = (): AppThunk => async (dispatch) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const response: ResponseGetPlans = await Api.getAllPlans();
    dispatch(slice.actions.setAllPlansSucess(response.planes));
    dispatch(removeProcess(idProcess));
    return true;
  } catch (error: any) {
    dispatch(removeProcess(idProcess));
    return false;
  }
};

export const getPlanByID =
  (idPlan: string): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response: ResponseGetPlanByID = await Api.getPlanByID(idPlan);
      dispatch(
        slice.actions.setPlansSelectedSucess({
          plan: response.plan,
          functionalities: response.functionalities,
        })
      );
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getPayment =
  (
    idCompany: string,
    idPlan: string,
    numbersMonth: string,
    totalPrice: string
  ): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response = await Api.getPayment(
        idCompany,
        idPlan,
        numbersMonth,
        totalPrice
      );

      dispatch(removeProcess(idProcess));
      return response;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
