import { InfoFromTask } from "pages/document-management/components/reports/model";

export const formatGanttData = (data: InfoFromTask[]) => {
  const rows: any = data.map((item) => {
    const data = [
      item.task_name.replace(/\s/g, ""),
      item.task_name,
      null,
      new Date(item.task_start_date),
      new Date(item.task_due_date),
      null,
      100,
      null,
    ];
    return data;
  });

  return rows;
};
