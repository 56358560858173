export const daysOfWeek = ["D", "L", "M", "M", "J", "V", "S"];
export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const dataCalendar: string[] = [
  "2024-06-10",
  "2024-06-14",
  "2024-07-01",
  "2024-07-15",
];

export const hours: string[] = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const currentAllDate = new Date();

export const startOfMonth = new Date(
  currentAllDate.getUTCFullYear(),
  currentAllDate.getUTCMonth(),
  1
);
export const endOfMonth = new Date(
  currentAllDate.getUTCFullYear(),
  currentAllDate.getUTCMonth() + 1,
  0
);

export const startDay = startOfMonth.getUTCDay();
export const daysInMonth = endOfMonth.getUTCDate();
