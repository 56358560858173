import React, { useState } from "react";
import style from "../../style/landing-page.module.scss";
import { navBarLanding } from "pages/landing-page/constants";
import { Link } from "react-router-dom";
import { ROUTES } from "routes/constants";
import Button from "components/buttons/fcdt-button";
import { Link as ScrollLink } from "react-scroll";
import { setIdRenderDemo } from "store/slices/landingPage";
import { useDispatch } from "react-redux";

const Menu = () => {
  const dispatch = useDispatch();
  const [valueSelected, setvalueSelected] = useState(1);

  const handleChangeSelected = (id: number) => {
    setvalueSelected(id);
    dispatch(setIdRenderDemo(1));
  };

  return (
    <div className={style.container_menu}>
      <ul>
        {navBarLanding.map((service, index) => {
          return (
            <li key={index}>
              <ScrollLink
                to={`section${index + 1}`}
                smooth={true}
                duration={500}
                className={`${style.link} ${
                  valueSelected === index + 1 ? style.li_active : ""
                }
                    `}
                onClick={() => handleChangeSelected(index + 1)}
              >
                {service}
              </ScrollLink>
            </li>
          );
        })}
        <Button
          className={`fcdt_btn_without_background_secondary ${style.button}`}
        >
          <Link to={ROUTES.HOME} className="fcdt-link-to">
            Iniciar sesión
          </Link>
        </Button>
      </ul>
    </div>
  );
};

export default Menu;
