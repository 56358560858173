import axios from "axios";
// import { APIURL, PROCESSES_PORT } from "config/APIURL";
import { APIURL } from "config/APIURL";

import { DashboardDate, FormDatesFollowUp } from "../model";

export const getDatesFollowUp = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/dashboard-dates/get_company_dashboard_dates`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const setDatesFollowUp = async (
  idCompany: string,
  data: FormDatesFollowUp,
  token: string
) => {
  const body = {
    id_company: idCompany,
    date_ranges: [
      {
        start_date: data.date1_start + "T00:00:00.000Z",
        end_date: data.date1_end + "T00:00:00.000Z",
      },
      {
        start_date: data.date2_start + "T00:00:00.000Z",
        end_date: data.date2_end + "T00:00:00.000Z",
      },
      {
        start_date: data.date3_start + "T00:00:00.000Z",
        end_date: data.date3_end + "T00:00:00.000Z",
      },
    ],
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/dashboard-dates/crear`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const updateDatesFollowUp = async (
  idCompany: string,
  data: FormDatesFollowUp,
  dataDates: DashboardDate[],
  token: string
) => {
  const body = {
    id_company: idCompany,
    date_ranges: [
      {
        uid: dataDates[0].uid,
        start_date: data.date1_start + "T00:00:00.000Z",
        end_date: data.date1_end + "T00:00:00.000Z",
      },
      {
        uid: dataDates[1].uid,
        start_date: data.date2_start + "T00:00:00.000Z",
        end_date: data.date2_end + "T00:00:00.000Z",
      },
      {
        uid: dataDates[2].uid,
        start_date: data.date3_start + "T00:00:00.000Z",
        end_date: data.date3_end + "T00:00:00.000Z",
      },
    ],
  };

  try {
    const response = await axios({
      method: "PUT",
      url: `${APIURL}/api/dashboard-dates/bulk_update`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
