export const normalizeAndCompare = (
  text: string | undefined,
  searchTerm: string
): boolean => {
  const normalizedText = text
    ?.toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const normalizedSearchTerm = searchTerm
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  return (
    normalizedText?.includes(normalizedSearchTerm) ||
    normalizedText === normalizedSearchTerm
  );
};
