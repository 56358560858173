import axios from "axios";
import { APIURL, EVENTS_PORT } from "config/APIURL";

export const getNotifications = async (id_user: string, token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/notifications/obtener_notificaciones/${id_user}`,
      headers: {
        "x-token": localStorage.getItem("token"),
      },
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response.data;
  }
};

export const updateDatesTask = async (
  idDate: string,
  idTask: string,
  optimiticDate: string,
  probableDate: string,
  token: string
) => {
  const body = {
    id_task: idTask,
    task_optimistic_date: optimiticDate,
    task_probable_date: probableDate,
  };

  try {
    const response = await axios({
      method: "PUT",
      url: `${APIURL}/api/taskdates/actualizar/${idDate}`,
      headers: {
        "x-token": token,
      },
      data: body,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response.data;
  }
};
