import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import * as API from "pages/superAdmin/components/plan-manager/api";
import {
  FormNewPlan,
  FormNewPlanFunction,
  FormUpdateFuncionality,
  Functionality,
} from "components/modals/model";
import { Plan } from "pages/superAdmin/components/plan-manager/model";
import { displayLoader, removeProcess } from "./loading";

type InitialState = {
  isReloadNeeded: boolean;
  formPlan?: FormNewPlan;
  isOpenNewPlan?: boolean;
  isUpdatePlan: boolean;
  isOpenNewFunction?: boolean;
  isOpenDeleteFunction?: boolean;
  isUpdateFuncionality?: FormUpdateFuncionality;
  succesPlan?: boolean;
  plans: Plan[];
  selectedPlan?: Plan;
  functionalities?: Functionality[];
  isOpenConfirmationMessage: boolean;
};
const initialState: InitialState = {
  isReloadNeeded: true,
  formPlan: undefined,
  isOpenNewFunction: false,
  isOpenNewPlan: false,
  isUpdatePlan: false,
  isOpenDeleteFunction: false,
  isUpdateFuncionality: undefined,
  succesPlan: false,
  functionalities: [],
  plans: [],
  selectedPlan: undefined,
  isOpenConfirmationMessage: false,
};
export const slice = createSlice({
  name: "superadmin",
  initialState,
  reducers: {
    getPlanInit: (state) => {
      state.isReloadNeeded = true;
    },
    getPlanSucess: (state) => {
      state.isReloadNeeded = false;
    },
    getPlanError: (state) => {
      state.isReloadNeeded = false;
    },
    setOpenNewFunction: (state, action) => {
      state.isOpenNewFunction = action.payload;
    },
    setOpenNewPlan: (state, action) => {
      state.isOpenNewPlan = action.payload;
    },
    setUpdatePlan: (state, action) => {
      state.isUpdatePlan = action.payload;
    },
    setFormPlan: (state, action) => {
      state.formPlan = action.payload;
    },
    setOpenDeleteFunction: (state, action) => {
      state.isOpenDeleteFunction = action.payload;
    },
    setUpdateFuncionality: (state, action) => {
      state.isUpdateFuncionality = action.payload;
    },
    setSuccesPlan: (state, action) => {
      state.succesPlan = action.payload;
    },
    setFunctionalities: (state, action) => {
      state.functionalities = action.payload;
    },
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    setConfirmationMessage: (state, action) => {
      state.isOpenConfirmationMessage = action.payload;
    },
  },
});
export const {
  setOpenNewFunction,
  setOpenNewPlan,
  setFormPlan,
  setUpdatePlan,
  setOpenDeleteFunction,
  setUpdateFuncionality,
  setSuccesPlan,
  setFunctionalities,
  setPlans,
  setSelectedPlan,
  setConfirmationMessage,
} = slice.actions;

export const getPlansFuncionalities =
  (): AppThunk => async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataSuperAdmin!;
      dispatch(slice.actions.getPlanInit());
      const [response, resFuncionalities] = await Promise.all([
        API.getPlans(token),
        API.getFunctionalities(token),
      ]);
      dispatch(slice.actions.setPlans(response?.planes));
      dispatch(slice.actions.setFunctionalities(resFuncionalities));
      dispatch(slice.actions.getPlanSucess());
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      dispatch(slice.actions.getPlanError());
      console.log(error);
    }
  };

export const getPlans = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const { token } = getState().auth.dataSuperAdmin!;
    const response: any = await API.getPlans(token);
    dispatch(slice.actions.setPlans(response?.planes));
    dispatch(removeProcess(idProcess));
    return response;
  } catch (error) {
    dispatch(removeProcess(idProcess));
    console.log(error);
  }
};

export const createPlan =
  (form: FormNewPlan): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataSuperAdmin!;
      const response: any = await API.createPlan(form, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };
export const updatePlan =
  (form: FormNewPlan): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataSuperAdmin!;
      const id_plan = getState().superadmin.selectedPlan?.plan.id_plan;
      const response: any = await API.updatePlan(id_plan, form, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };

// Functionalities
export const getFunctionalities =
  (): AppThunk => async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataSuperAdmin!;
      const response: any = await API.getFunctionalities(token);
      dispatch(slice.actions.setFunctionalities(response));
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };

export const getSpecificFuncionality =
  (): AppThunk => async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataSuperAdmin!;
      const id_funcionality =
        getState().superadmin.isUpdateFuncionality?.id_funcionality;
      const response: any = await API.getSpecificFuncionality(
        id_funcionality,
        token
      );
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };

export const createfunctionality =
  (form: FormNewPlanFunction): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataSuperAdmin!;
      const response: any = await API.createfunctionality(form, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };

export const updateFunctionality =
  (form: FormNewPlanFunction): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataSuperAdmin!;
      const response: any = await API.updateFunctionality(form, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };

export const deleteFunctionality =
  (form: FormNewPlanFunction): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataSuperAdmin!;
      const response: any = await API.deleteFunctionality(form, token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };

export const getGoals = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const { token } = getState().auth.dataSuperAdmin!;
    const response: any = await API.getGoals(token);
    dispatch(removeProcess(idProcess));
    return response;
  } catch (error) {
    dispatch(removeProcess(idProcess));
    console.log(error);
  }
};

export default slice.reducer;
