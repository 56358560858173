import { createSlice } from "@reduxjs/toolkit";
import { FilterParams } from "components/filter/model";

type InitialState = {
  filterParams?: FilterParams;
};
const initialState: InitialState = {
  filterParams: undefined,
};
export const slice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterParams: (state, action) => {
      state.filterParams = action.payload;
    },
  },
});
export const { setFilterParams } = slice.actions;

export default slice.reducer;
