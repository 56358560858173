import React, { useEffect } from "react";
import SecondFormDocument from "./second-form-document";
import {
  EditDocument,
  setActualIdFormDocument,
  setReloadDocument,
} from "pages/document-management/store/slices/documents";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ValuesSecondFormDocument } from "../model";
import { RootState } from "store/store";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ROUTES } from "routes/constants";
import { mappingRoute } from "helpers/formatRoute";

const SecondFormDocumentContainer = () => {
  const dispatch: any = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { register, handleSubmit, setValue } =
    useForm<ValuesSecondFormDocument>();

  const handleComeBack = () => {
    dispatch(setActualIdFormDocument(1));
  };

  const { currentPath } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { dataSecondFormDocument } = useSelector((state: RootState) => {
    return state.documentsManagement.documents;
  });

  const idMunutes: string = params.id_minute!;

  useEffect(() => {
    if (dataSecondFormDocument) {
      setValue("minutesSummay", dataSecondFormDocument.minutesSummay);
      setValue("minutescommitment", dataSecondFormDocument.minutescommitment);
      setValue("minuteConclusion", dataSecondFormDocument.minuteConclusion);
    }
  }, [dataSecondFormDocument, setValue]);

  const onSubmit = async (values: ValuesSecondFormDocument) => {
    const res: any = await dispatch(EditDocument(values, idMunutes));

    if (!res?.ok) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    toast.success("Acta actualizada correctamente", {duration: 7000, position:"top-center"});

    navigate(
      mappingRoute(
        `/${currentPath}/*`,
        mappingRoute(
          ROUTES.DOCUMENT_MANAGEMENT.PARENT,
          ROUTES.DOCUMENT_MANAGEMENT.CHILDRENS.DOCUMENTS
        )
      )
    );
    handleComeBack();
    dispatch(setReloadDocument());
  };

  return (
    <SecondFormDocument
      handleComeBack={handleComeBack}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};

export default SecondFormDocumentContainer;
