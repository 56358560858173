import Title from "components/fcdt-title/fcdt-title";
import GaugeChart from "react-gauge-chart";
import style from "./style/gauge-chart.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";

type GaugeChartSpeedProps = {
  speed: number;
  percentage: number;
  notLegend?: boolean;
  widthLine?: number;
};

const GaugeChartSpeed = (props: GaugeChartSpeedProps) => {
  return (
    <div className={style.container_gauge}>
      <Title fontSize={40} fontWeight={700} className={style.title_count}>
        {props.speed}
      </Title>
      <section className={style.container_chart}>
        <GaugeChart
          id="gauge-chart5"
          arcsLength={[0.3, 0.5, 0.2]}
          colors={["#FFC21D", "#00DE97", "#EE3537"]}
          percent={props.percentage}
          arcPadding={0}
          hideText={true}
          cornerRadius={0}
          needleBaseColor="#2F0084"
          needleColor="#2F0084"
        />
      </section>

      <div
        className={style.container_legend}
        style={{ width: props.widthLine ? props.widthLine : "" }}
      >
        {!props.notLegend && (
          <>
            <section className={style.container_box_text}>
              <div
                className={style.box}
                style={{ background: "#FFC21D" }}
              ></div>{" "}
              <span>
                <Paragraph fontSize={14} fontWeight={300}>
                  Pausado
                </Paragraph>
              </span>
            </section>
            <section className={style.container_box_text}>
              <div
                className={style.box}
                style={{ background: "#EE3537" }}
              ></div>{" "}
              <span>
                <Paragraph fontSize={14} fontWeight={300}>
                  Rápido
                </Paragraph>
              </span>
            </section>
            <section className={style.container_box_text}>
              <div
                className={style.box}
                style={{ background: "#00DE97" }}
              ></div>{" "}
              <span>
                <Paragraph fontSize={14} fontWeight={300}>
                  Normal
                </Paragraph>
              </span>
            </section>
          </>
        )}
      </div>
    </div>
  );
};

export default GaugeChartSpeed;
