import ModalViewDocument from "./modal-view-minute";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { setIsModalViewMinute } from "store/slices/landingPage";

const ModalViewMinuteContainer = () => {
  const dispatch: any = useDispatch();

  const { isModalViewMinute, dataDemo } = useSelector((state: RootState) => {
    return state.landingPage;
  });

  const handleCloseModal = () => {
    dispatch(setIsModalViewMinute(false));
  };

  if (!isModalViewMinute || !dataDemo) return null;

  return (
    <ModalViewDocument
      open={isModalViewMinute}
      handleCloseModal={handleCloseModal}
      data={dataDemo.minute_content}
    />
  );
};

export default ModalViewMinuteContainer;
