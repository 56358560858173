import Filter from "./filter";
import { FilterParams } from "./model";
import { setFilterParams } from "store/slices/filter";
import { useDispatch } from "react-redux";

const FilterContainer = (notificaciones: any) => {
  const dispatch = useDispatch();
  const initialState: FilterParams = {
    search: "",
  };
  const setFilter = (e: any) => {
    dispatch(setFilterParams({ search: e.target.value }));
  };
  const onSubmit = () => { };
  return (
    <Filter category={notificaciones.category} filter={initialState} setFilter={setFilter} onSubmit={onSubmit} />
  );
};

export default FilterContainer;
