import style from "./style/home.module.scss";
import { HOME_CONSTANTS } from "./constants";
import LogoText from "assets/logo-text.webp";
import Title from "components/fcdt-title/fcdt-title";
import Logo from "components/logo/fcdt-logo";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Button from "components/buttons/fcdt-button";
import { HomeProps } from "./model";
import { ROUTES } from "routes/constants";

const Home = (props: HomeProps) => {
  const { handleNavigate } = props;

  return (
    <div className={style.container_home}>
      <section className={style.container_title_logo_buttons}>
        <Title
          children={HOME_CONSTANTS.title}
          className="fcdt-title-secondary"
          width="30"
        />
        <Logo
          urlImae={LogoText}
          className={style.container_logo}
          urlNavigate="/"
        />
        <Paragraph children={HOME_CONSTANTS.description} />

        <section className={style.container_buttons}>
          <Button
            children="Regístrate ahora"
            width="20"
            onClick={() => handleNavigate(ROUTES.REGISTER_COMPANY)}
          />
          <Button
            children="Iniciar sesión"
            className="fcdt_btn_without_background_secondary"
            width="20"
            onClick={() => handleNavigate(ROUTES.LOGIN)}
          />
        </section>

        <Paragraph
          className="text_paragraph_bold"
          fontWeight={600}
          width="40"
          children={HOME_CONSTANTS.description_bold}
        />
      </section>
    </div>
  );
};

export default Home;
