import style from "./styles/body-follow-up.module.scss";
import ProcessCharts from "./process-charts/process-charts";
import EventsMeetingsCharts from "./events-meetings/events-meetings";
import EventsTasksChars from "./events-tasks/events-tasks";
import MeetingPeopleCharts from "./meetings-people/meeting-people-charts";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import TaskPeopleCharts from "./task-people/task-people";
import ModalRecomendationContainer from "../modal-recomendation/modal-recomendation-container";
import ModalButtonRecomendationContainer from "../modal-button_recommendation/modal-button_recommendation-container";

const BodyFollowUpContainer = () => {
  const { idShowDashboard } = useSelector((state: RootState) => {
    return state.followup;
  });

  const Render = () => {
    switch (idShowDashboard) {
      case 1:
        return <ProcessCharts />;
      case 2:
        return <EventsMeetingsCharts />;
      case 3:
        return <EventsTasksChars />;
      case 4:
        return <MeetingPeopleCharts />;
      case 5:
        return <TaskPeopleCharts />;
      default:
        break;
    }
  };

  return (
    <div className={style.container_body}>
      {Render()}
      <ModalRecomendationContainer />
      <ModalButtonRecomendationContainer />
    </div>
  );
};

export default BodyFollowUpContainer;
