import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useForm } from "react-hook-form";
import { FormDeleteTask } from "pages/collaborator/components/process/model";
import ModalDeleteMeetineg from "./modal-delete-meeting";
import {
  setReloadCalendar,
  setshowModalDeleteMeeting,
  updateMeetingById,
} from "pages/calendar/store/slices/calendar";
import toast from "react-hot-toast";

const ModalDeleteMeetingContainer = () => {
  const dispatch: any = useDispatch();

  const { showModalDeleteMeeting } = useSelector((state: RootState) => {
    return state.calendar.calendar;
  });

  const { register, handleSubmit, reset } = useForm<FormDeleteTask>();

  const handleCloseModal = () => {
    dispatch(setshowModalDeleteMeeting(false));
    reset();
  };

  const onSubmit = async (values: FormDeleteTask) => {
    const res = await dispatch(updateMeetingById(values.description));

    if (!res?.ok) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    dispatch(setshowModalDeleteMeeting(false));
    dispatch(setReloadCalendar());
    toast.success(res?.msg, {duration: 7000, position:"top-center"});
    reset();
  };

  if (!showModalDeleteMeeting) return null;

  return (
    <ModalDeleteMeetineg
      openModal={showModalDeleteMeeting}
      handleCloseModal={handleCloseModal}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};

export default ModalDeleteMeetingContainer;
