import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import { Provider } from "react-redux";
import GlobalRoutes from "routes/routes";
import "./index.css";
import { Toaster } from "react-hot-toast";
import Loading from "components/loading/loading";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <Loading />
    <Toaster
      position="top-right"
      toastOptions={{
        error: {
          duration: 5000,
        },
        success: {
          duration: 7000,
        },
        loading: {
          duration: 2000,
        },
        style: {
          fontSize: "16px",
          background: "#fff",
          color: "#2f0084",
        },
      }}
    />
    <GlobalRoutes />
  </Provider>
);

reportWebVitals();
