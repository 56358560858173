import React from "react";
import style from "../../styles/nav-bar.module.scss";
import { FormSearchProps } from "../../model";
import { PiBroom } from "react-icons/pi";
import { IoSearchOutline } from "react-icons/io5";

const FormSearch = (props: FormSearchProps) => {
  const { handleSubmit, onSubmit, register, handleResetFilter } = props;

  return (
    <form className={style.nav_bar_container} onSubmit={handleSubmit(onSubmit)}>
      <input
        type="text"
        className="fcdt-input"
        placeholder="Términos de búsqueda"
        {...register("searchTerm")}
      />
      <button type="submit" className="fcdt_btn_secondary" title="Buscar">
        <IoSearchOutline size={20} />
      </button>
      <button
        type="button"
        className="btn_cancel"
        title="Limpiar filtros"
        onClick={handleResetFilter}
      >
        <PiBroom size={20} />
      </button>
    </form>
  );
};

export default FormSearch;
