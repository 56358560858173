import Title from "components/fcdt-title/fcdt-title";
import { PieChart } from "react-minimal-pie-chart";
import style from "./style/pie-chart.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { Data } from "react-minimal-pie-chart/types/commonTypes";

type PieChartBarProps = {
  data: Data;
  legend?: string;
  itemLegentRectangular?: boolean;
  heigthBar?: number;
};

const PieChartBar = (props: PieChartBarProps) => {
  return (
    <div className={style.container_principal_pir_chart}>
      <PieChart
        animate={true}
        animationDuration={500}
        children={<div>Hi</div>}
        style={{ height: props.heigthBar ? `${props.heigthBar}px` : "150px" }}
        data={props.data}
        radius={50}
        lineWidth={40}
        label={({ dataEntry }) =>
          dataEntry.value > 0 ? dataEntry.value + "%" : ""
        }
        labelPosition={80}
        labelStyle={{
          fontSize: "9px",
          fontWeight: 700,
          fontFamily: "Poppins",
          fill: "#fff",
        }}
      />

      <section className={style.container_legend}>
        <Title fontSize={14} className={style.title} hidden={!props.legend}>
          {props.legend}
        </Title>

        {props.data.map((item, index) => {
          return (
            <div key={index} className={style.container_circle_point}>
              <div
                className={style.box}
                style={{
                  background: item.color,
                  borderRadius: props.itemLegentRectangular ? "0px" : "100%",
                }}
              ></div>
              <span>
                <Paragraph fontWeight={300} fontSize={13}>
                  {item.title}
                </Paragraph>
              </span>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default PieChartBar;
