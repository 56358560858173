import React from "react";
import style from "./style/indicator.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { IndicatorProps } from "./model";

const Indicators = (props: IndicatorProps) => {
  const { numberUsers, barChartData, circleChartData } = props;

  return (
    <div className={style.container_principal}>
      <Paragraph className={style.paragraph}>
        Total de usuarios registrados: <strong>{numberUsers}</strong>Usuarios
      </Paragraph>

      <section className={style.container_section_graph}>
        <div>
          <div className={style.container_title_graph}>
            <section>
              <div className={style.box_blue}></div> <span>Empresas</span>
            </section>
            <section>
              <div className={style.box_green}></div> <span>Usuarios</span>
            </section>
          </div>
          <BarChart
            yAxis={[{ label: "Registros" }]}
            xAxis={[
              {
                scaleType: "band",
                data: barChartData.xAxis,
                hideTooltip: true,
                label: "Últimos 4 meses",
              },
            ]}
            series={barChartData.data}
            width={500}
            height={300}
          />
        </div>

        <div className={style.container_principal_circle}>
          <h1 className={style.container_title_graph}>Licencias adquiridas</h1>
          <PieChart
            series={circleChartData}
            width={400}
            height={200}
            colors={["#2f0084", "#00de97", "#b593f5"]}
          />
        </div>
      </section>
    </div>
  );
};

export default Indicators;
