import { createSlice } from "@reduxjs/toolkit";
type InitialState = {
  toggleChecked: boolean;
};
const initialState: InitialState = {
  toggleChecked: true,
};
export const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setToggleChecked: (state) => {
      state.toggleChecked = !state.toggleChecked;
    },
  },
});
export const { setToggleChecked } = slice.actions;
export default slice.reducer;
