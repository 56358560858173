import axios from "axios";
// import { APIURL, PERSONS_PORT } from "config/APIURL";
import { APIURL } from "config/APIURL";

export const getUsersFromCompany = async (token: string) => {
    try {
      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        method: "GET",
        url: `${APIURL}/api/usuarios/get_all_users_from_company`,
      });
      return response?.data;
    } catch (error: any) {
      console.log(error?.response?.data);
      return error?.response?.data;
    }
  };