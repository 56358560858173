import style from "../../../../style/landing-page.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Button from "components/buttons/fcdt-button";
import { useDispatch } from "react-redux";
import { setIdRenderDemo, setModalDemo } from "store/slices/landingPage";
import DemoGif from "assets/gifs/leader_process.gif";

const DemoStart = () => {
  const dispatch = useDispatch();

  return (
    <div className={style.container_demo}>
      <Title width="100" className={style.title_demo}>
        Demo Rykwa
      </Title>
      <Paragraph className={style.paragraph_demo}>
        En Rykwa, estamos emocionados de tenerte a bordo y queremos que
        aproveches al máximo todas las funcionalidades y características que
        ofrecemos para simplificar la organización. Te invitamos a conocer cómo
        usar la plataforma.
      </Paragraph>

      <img
        src={DemoGif}
        alt="super administratos Gif"
        className={style.iframe}
      />

      <Button
        className={`fcdt_btn_secondary ${style.button_demo}`}
        onClick={() => {
          dispatch(setIdRenderDemo(2));
          dispatch(setModalDemo(true));
        }}
      >
        Probar Demo
      </Button>
    </div>
  );
};

export default DemoStart;
