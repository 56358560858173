import Demo from "./demo";
import { ComponentsLandingProps } from "pages/landing-page/model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import ModalInformationDemo from "./components/modal-information-demo/modal-information-demo";
import { setModalDemo } from "store/slices/landingPage";
import ModalViewMinuteContainer from "./components/modal-view-document/modal-view-minute-container";
import ModalSpeechTreeContainer from "./components/modal-speech-tree/modal-speech-tree-container";

const DemoContainer = (props: ComponentsLandingProps) => {
  const dispatch = useDispatch();

  const { showModalDemo } = useSelector((state: RootState) => {
    return state.landingPage;
  });

  const { idRenderDemo } = useSelector((state: RootState) => {
    return state.landingPage;
  });

  const handleCloseModal = () => {
    dispatch(setModalDemo(false));
  };

  return (
    <div id={props.id}>
      <Demo renderDemo={idRenderDemo} />
      <ModalInformationDemo
        showModal={showModalDemo}
        handleCloseModal={handleCloseModal}
      />
      <ModalViewMinuteContainer />
      <ModalSpeechTreeContainer />
    </div>
  );
};

export default DemoContainer;
