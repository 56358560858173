import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { setShowModalButtonRecomendations } from "store/slices/follow-up";
import ModalButtonRecomendation from "./modal-button_recommendation";

const ModalButtonRecomendationContainer = () => {
  const dispatch: any = useDispatch();

  const { showModalButtonRecomendations, dataDashboardRecomendationSelected } =
    useSelector((state: RootState) => {
      return state.followup;
    });

  const handleCloseModal = () => {
    dispatch(setShowModalButtonRecomendations(false));
  };

  const handleDownload = () => {
    dispatch(setShowModalButtonRecomendations(false));
    // dispatch(downloadDocument());
  };

  if (!dataDashboardRecomendationSelected) return null;

  return (
    <ModalButtonRecomendation
      open={showModalButtonRecomendations}
      data={dataDashboardRecomendationSelected}
      handleCloseModal={handleCloseModal}
      handleDownload={handleDownload}
    />
  );
};

export default ModalButtonRecomendationContainer;
