import Register from "./register";
import { useForm } from "react-hook-form";
import { FormRegisterType } from "./model";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { registerRequestPost } from "store/slices/register";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "routes/constants";

const RegisterContainer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormRegisterType>();
  const dispatch = useDispatch<any>();
  const params = useParams();
  const navigate = useNavigate();

  const idCompany = params.id_company!;

  const onSubmit = async (values: FormRegisterType) => {
    if (values.password !== values.confirmPassword) {
      toast.error("Las contraseñas no coinciden", {duration: 7000, position:"top-center"});
      return;
    }

    const response: any = await dispatch(
      registerRequestPost(values, idCompany)
    );

    if (!response?.ok) {
      toast.error(response?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    toast.success(response?.msg, {duration: 7000, position:"top-center"});
    navigate(ROUTES.LOGIN);
  };

  return (
    <>
      <Register
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        errors={errors}
      />
    </>
  );
};

export default RegisterContainer;
