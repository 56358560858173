import React from "react";
import { FCDTParagraphProps } from "./model";
import style from "./style/fcdt-paragraph.module.scss";

const Paragraph = (props: FCDTParagraphProps) => {
  return (
    <p
      className={`${style.fcdt_text_paragraph} ${props.className}`}
      style={{
        ...props.style,
        fontWeight: props.fontWeight,
        fontSize: props.fontSize,
        width: `${props.width}%`,
      }}
    >
      {props.children}
    </p>
  );
};

export default Paragraph;
