import React from "react";
import FilterContainer from "components/filter/filter-container";
import style from "../documents/styles/document.module.scss";
import { TableSpeechTreeProps } from "pages/document-management/model";
import { IoCreateOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Title from "components/fcdt-title/fcdt-title";

const SpeechTree = (props: TableSpeechTreeProps) => {
  const { data } = props;

  return (
    <section className={style.container_document_table}>
      <div className={`${style.controls_document_table} fcdt-flex-box`}>
        <div className={style.toggle_document_table}> </div>
        <FilterContainer category={"evento"}/>
      </div>
      <Title fontSize={18} fontWeight={600} className={style.title_table}>
        Lista de eventos asociados al discurso
      </Title>
      <div className={style.content}>
        <table>
          <thead>
            <tr>
              <th>Nombre de reunión asociada al discurso</th>
              <th>Fecha del evento</th>
              <th className={style.th_level_action}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((event) => (
              <tr key={event.id}>
                <td>
                  <div>{event.tree_name}</div>
                </td>
                <td>
                  <div> {event.tree_date}</div>
                </td>

                <td>
                  <Link className="fcdt-link-to" to={`${event.uid}`}>
                    <IoCreateOutline className="fcdt-icon fcdt-gray" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default SpeechTree;
