import { IoMdClose } from "react-icons/io";
import style from "../style/newfunction.module.scss";
import {
  createfunctionality,
  deleteFunctionality,
  getFunctionalities,
  setConfirmationMessage,
  setFunctionalities,
  setOpenDeleteFunction,
  setOpenNewFunction,
  setOpenNewPlan,
  updateFunctionality,
} from "store/slices/superAdmin";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/buttons/fcdt-button";
import { FormNewPlanFunction } from "../model";
import { useForm } from "react-hook-form";
import { IoTrashOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Dialog from "../dialog";
import { ConfirmationMessage } from "./confirmation-message";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";

export const NewFunction = () => {
  const { register, handleSubmit, resetField } = useForm<FormNewPlanFunction>();

  const dispatch = useDispatch<any>();

  const [createSucces, setCreateSuccess] = useState(false);
  const { isOpenDeleteFunction, functionalities, isOpenConfirmationMessage } =
    useSelector((state: any) => state.superadmin);
  const [seletedFunctionality, setSelectedFunctionality] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleCloseDialog = () => {
    dispatch(setOpenNewFunction(false));
    dispatch(setOpenDeleteFunction(false));
    dispatch(setOpenNewPlan(true));
  };
  const handleCloseConfirmationMessage = () => {
    dispatch(setConfirmationMessage(!isOpenConfirmationMessage));
  };

  const handleUpdate = (item: any) => {
    setSelectedOption(item.id === selectedOption ? null : item.id);
    setSelectedFunctionality(true);
    resetField("functionality_name", { defaultValue: item.functionality_name });
    resetField("functionality_description", {
      defaultValue: item.functionality_description,
    });
  };
  const handleRemove = () => {
    resetField("functionality_name", { defaultValue: "" });
    resetField("functionality_description", { defaultValue: "" });
    setSelectedFunctionality(false);
    dispatch(setOpenDeleteFunction(true));
  };

  const onSubmit = async (data: FormNewPlanFunction) => {
    setCreateSuccess(true);
    const response: any = seletedFunctionality
      ? await dispatch(updateFunctionality(data))
      : isOpenDeleteFunction
      ? await dispatch(deleteFunctionality(data))
      : await dispatch(createfunctionality(data));
    if (response?.ok) {
      toast.success(response.msg, {duration: 7000, position:"top-center"});
      resetField("functionality_name", { defaultValue: "" });
      resetField("functionality_description", { defaultValue: "" });
      setSelectedOption(null);
      setCreateSuccess(false);
      dispatch(setConfirmationMessage(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const responseFuncionalities: any = await dispatch(getFunctionalities());
      dispatch(setFunctionalities(responseFuncionalities));
    };

    if (selectedOption === null) {
      setSelectedFunctionality(false);
      resetField("functionality_name", { defaultValue: "" });
      resetField("functionality_description", { defaultValue: "" });
    }
    fetchData();
  }, [createSucces, selectedOption]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.new_plan_container}>
          <div className={style.header}>
            <h2>
              {!seletedFunctionality && !isOpenDeleteFunction
                ? "Nueva"
                : isOpenDeleteFunction
                ? "Eliminar"
                : "Actualizar"}{" "}
              funcionalidad
            </h2>
            <button type="button" onClick={handleCloseDialog}>
              {" "}
              <IoMdClose size={35} color="#2f0084" />{" "}
            </button>
          </div>
          <div className={style.content}>
            <div className={style.content_left}>
              <input
                type="text"
                disabled={isOpenDeleteFunction}
                placeholder="Nombre de la funcionalidad"
                required
                minLength={6}
                maxLength={50}
                pattern={REG_EXP.COMPANY_NAME}
                title={TITLE_REG_EXP.COMPANY_NAME}
                {...register("functionality_name")}
              />

              <textarea
                disabled={isOpenDeleteFunction}
                placeholder="Descripción"
                required
                minLength={6}
                maxLength={250}
                {...register("functionality_description")}
              />
            </div>
            <div className={style.content_right}>
              <ul className={style.content_checkbox}>
                {functionalities?.length > 0 ? (
                  functionalities?.map((item: any, index: any) => (
                    <li key={item.id}>
                      {isOpenDeleteFunction ? (
                        <input
                          type="checkbox"
                          id={`${item.id}`}
                          {...register("functionalities", { required: false })}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          checked={selectedOption === item.id}
                          onClick={() => handleUpdate(item)}
                          {...register("functionalities", { required: false })}
                        />
                      )}
                      {item.functionality_name}
                    </li>
                  ))
                ) : (
                  <li>No hay funcionalidades creadas</li>
                )}
              </ul>
            </div>
          </div>
          <div className={style.content_buttons}>
            {seletedFunctionality ? (
              <>
                <Button
                  className="fcdt-btn_update"
                  children="Guardar"
                  width="40"
                  disabled={isOpenDeleteFunction}
                />
                <button
                  type="button"
                  className="fcdt_btn_primary fcdt-btn_update"
                  onClick={handleRemove}
                >
                  {" "}
                  <IoTrashOutline size={28} color="#2f0084" /> Eliminar
                </button>
              </>
            ) : !isOpenDeleteFunction ? (
              <>
                <Button
                  className="btn_cancel"
                  children="Cancelar"
                  width="40"
                  onClick={handleCloseDialog}
                />
                <Button
                  children="Añadir"
                  width="40"
                  onClick={() => handleSubmit}
                />
              </>
            ) : (
              <>
                <Button
                  className="fcdt-btn_update"
                  children="Guardar"
                  width="40"
                  disabled={isOpenDeleteFunction}
                />
                <button
                  type="button"
                  className="fcdt_btn_primary fcdt-btn_update"
                  onClick={() => dispatch(setConfirmationMessage(true))}
                >
                  {" "}
                  <IoTrashOutline size={28} color="#2f0084" /> Eliminar
                </button>
              </>
            )}
          </div>
        </div>
        <Dialog
          isOpen={isOpenConfirmationMessage}
          onClose={handleCloseConfirmationMessage}
        >
          <ConfirmationMessage message="funcionalidad" />
        </Dialog>
      </form>
    </>
  );
};
