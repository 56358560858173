import { useEffect } from "react";
import style from "./styles/body-knowledge.module.scss";
import NotInformation from "./not-information/not-information";
import Accordion from "components/fcdt-accordion/accordion";
import MeetingsList from "./meeting-list/meetings-list";
import TasksList from "./tasks-list/task-list";
import ReportsList from "./reports-list/reports-list";
import MeetingDescription from "./meeting-description/meeting-description";
import TasksDescription from "./tasks-description/tasks-description";
import ReportsDescription from "./reports-description/reports-description";
import { useDispatch, useSelector } from "react-redux";
import { getInformationKnowledge } from "store/slices/knowledge";
import { RootState } from "store/store";
import GanttDiagram from "./gantt-diagram/gant-diagram";

const BodyKnowledgeContainer = () => {
  const dispatch: any = useDispatch();

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const {
    processes,
    meetingSelected,
    taskSelected,
    reportSelected,
    eventsSelected,
    showGantt,
  } = useSelector((state: RootState) => {
    return state.knowledge;
  });

  useEffect(() => {
    if (dataUser && processes.length > 0) {
      dispatch(getInformationKnowledge());
    }
  }, [dataUser, processes]);

  return (
    <div className={style.container_principal_body_knowledge}>
      {/* <NotInformation /> */}
      <div className={style.container_selector_description}>
        <div className={style.container_accordions}>
          {eventsSelected[0] && (
            <Accordion label="Reuniones">
              <MeetingsList />
            </Accordion>
          )}

          {eventsSelected[1] && (
            <Accordion label="Tareas">
              <TasksList />
            </Accordion>
          )}

          {eventsSelected[2] && (
            <Accordion label="Reportes">
              <ReportsList />
            </Accordion>
          )}
        </div>
        <div className={style.container_accordions}>
          <div className={style.container_description_accordion}>
            {meetingSelected && (
              <MeetingDescription meetingSelected={meetingSelected} />
            )}

            {taskSelected && <TasksDescription taskSelected={taskSelected} />}
            {reportSelected && (
              <>
                <ReportsDescription reportSelected={reportSelected} />
                {showGantt && (
                  <GanttDiagram
                    data={reportSelected.report_data.info_from_tasks}
                  />
                )}
              </>
            )}

            {!meetingSelected && !taskSelected && !reportSelected && (
              <NotInformation hide={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyKnowledgeContainer;
