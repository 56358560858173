import FilterContainer from "components/filter/filter-container";
import style from "./style/user.module.scss";
import { IoPeopleOutline } from "react-icons/io5";
import { BiEnvelope } from "react-icons/bi";
import { UserSuperAdminProps } from "./model";
import Title from "components/fcdt-title/fcdt-title";

const UsersSuperAdmin = (props: UserSuperAdminProps) => {
  const { users } = props;
  return (
    <section className={style.user_container}>
      <div className={style.controls}>
        <div className={style.toggle}></div>
        <FilterContainer />
      </div>

      <Title fontSize={18} fontWeight={600} className={style.title_table}>
        Lista de usuarios registrados
      </Title>

      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            {/* <th>Restablecer contraseña</th>
            <th>Cambiar estado</th> */}
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={user.id}>
              <td>
                <div>
                  <IoPeopleOutline size={31} color="#2f0084" />
                  <p>{user.name}</p>
                </div>
              </td>
              <td>
                <div>
                  <BiEnvelope size={31} color="#2f0084" />
                  <p className="fcdt-title-responsive">{user.email}</p>
                </div>
              </td>
              {/* <td>
                <span>Cambiar</span>
              </td>

              <td>Cambiar estado</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default UsersSuperAdmin;
