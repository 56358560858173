import React, { useEffect } from "react";
import Documents from "./documents";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataDocuments,
  getDocumentById,
  setDataToggle,
  setIsModalLoadDocument,
  setIsModalViewDocument,
} from "pages/document-management/store/slices/documents";
import { RootState } from "store/store";
import UseDataFilter from "common/hooks/use-data-filter";
import usePagination from "common/hooks/use-pagination";
import { NUMBER_ITEMS_PAGINATION } from "common/contants";
import ResponsivePagination from "react-responsive-pagination";
import ModalViewDocumentContainer from "./components/modal-view-document/modal-view-document-container";

const DocumentsContainer = () => {
  const dispatch: any = useDispatch();

  const { isReloadNeeded, isFeching, dataDocumentsTable, dataToggle } =
    useSelector((state: RootState) => {
      return state.documentsManagement.documents;
    });

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });

  const value = filterParams?.search;
  const searchTerm = value ? value : "";

  const handleChangeToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDataToggle());
  };

  const handleOpenModal = (id: number, uid: string) => {
    if (id === 1) {
      dispatch(getDocumentById(uid));
      dispatch(setIsModalViewDocument(true));
      return;
    }
    dispatch(setIsModalLoadDocument(true));
  };

  useEffect(() => {
    if (isReloadNeeded && !isFeching && dataUser) {
      dispatch(getDataDocuments());
    }
  }, [isReloadNeeded, isFeching, dataUser, dispatch]);

  const { filtered } = UseDataFilter({
    data: dataDocumentsTable,
    searchValue: searchTerm,
  });

  const { currentPage, totalPages, itemsToShow, setCurrentPage } =
    usePagination({
      currentPage: 1,
      filterData: filtered,
      numberItems: NUMBER_ITEMS_PAGINATION,
    });

  return (
    <div className="fcdt-center">
      <Documents
        data={itemsToShow}
        dataToggle={dataToggle}
        handleChangeToggle={handleChangeToggle}
        handleOpenModal={handleOpenModal}
      />
      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        maxWidth={100}
      />
      <ModalViewDocumentContainer />
    </div>
  );
};

export default DocumentsContainer;
