import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  getDownloadRecomendation,
  setShowModalRecomendations,
} from "store/slices/follow-up";
import ModalRecomendation from "./modal-recomendation";
import toast from "react-hot-toast";
import { downloadFile } from "helpers/downloadFile";

const ModalRecomendationContainer = () => {
  const dispatch: any = useDispatch();

  const { showModalRecomendations, dataDashboardRecomendation } = useSelector(
    (state: RootState) => {
      return state.followup;
    }
  );

  const handleCloseModal = () => {
    dispatch(setShowModalRecomendations(false));
  };

  const handleDownload = async () => {
    dispatch(setShowModalRecomendations(false));
    const res = await dispatch(getDownloadRecomendation());

    if (!res?.ok) {
      toast.error("Error al descargar pdf, comuníquese con administrador", {duration: 7000, position:"top-center"});
      return;
    }

    downloadFile(res.data, "recomendaciones_Rykwa.pdf");

    // dispatch(downloadDocument());
  };

  if (!dataDashboardRecomendation) return null;

  return (
    <ModalRecomendation
      open={showModalRecomendations}
      data={dataDashboardRecomendation}
      handleCloseModal={handleCloseModal}
      handleDownload={handleDownload}
    />
  );
};

export default ModalRecomendationContainer;
