import style from "../styles/body-follow-up.module.scss";
import TitleCharts from "../title-chars/title-charts";
import Title from "components/fcdt-title/fcdt-title";
import PieChartBar from "../../custom-charts/pie-bar/pie-bar";
import { Data } from "react-minimal-pie-chart/types/commonTypes";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import BarPercentageChart from "../../custom-charts/bar-percentage/bar-percentage";
import NumberPerson from "../number-person/number-person";
import GaugeChartSpeed from "../../custom-charts/gauge-chart/gauge-chart";
import MediaPieChart from "../../custom-charts/media-pie-chart/media-pie-chart";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { formatDate } from "helpers/formatDate";
import ButtonFloatContainer from "../../button_float/button-float-container";

const EventsMeetingsCharts = () => {
  const { dataDashboard, collaborators, dataDashboardRecomendation } =
    useSelector((state: RootState) => {
      return state.followup;
    });

  if (!dataDashboard) return null;

  const dataEmotions: Data = dataDashboard.data_sentiment_statuses_chart
    ? dataDashboard.data_sentiment_statuses_chart.map((item) => {
        return {
          title: item.label,
          value: item.value * 100,
          color: item.color,
        };
      })
    : [];

  const dataIndividualMeeting = dataDashboard.individual_meetings_data
    ? dataDashboard.individual_meetings_data
    : [];

  return (
    <div className={style.container_goal_charts}>
      <TitleCharts
        title="Progreso de reuniones"
        subtitle={`Todos los participantes (${collaborators.length})`}
      />

      <div className={style.container_legend}>
        <section className={style.container_box_text}>
          <div className={style.box} style={{ background: "#FFC21D" }}></div>{" "}
          <span>
            <Paragraph fontSize={14} fontWeight={300}>
              Pausado
            </Paragraph>
          </span>
        </section>
        <section className={style.container_box_text}>
          <div className={style.box} style={{ background: "#EE3537" }}></div>{" "}
          <span>
            <Paragraph fontSize={14} fontWeight={300}>
              Rápido
            </Paragraph>
          </span>
        </section>
        <section className={style.container_box_text}>
          <div className={style.box} style={{ background: "#00DE97" }}></div>{" "}
          <span>
            <Paragraph fontSize={14} fontWeight={300}>
              Normal
            </Paragraph>
          </span>
        </section>
      </div>

      <div
        className={`${style.box_graph_shadown} ${style.box_graph_shadown_100} ${style.box_graph_shadown_100_with_padding}`}
      >
        {dataDashboardRecomendation?.individual_meetings_recommendation && (
          <ButtonFloatContainer
            data={dataDashboardRecomendation.individual_meetings_recommendation}
          />
        )}
        <div className={style.container_scroll_event_task}>
          {dataIndividualMeeting.length > 0 ? (
            dataIndividualMeeting.map((item, index) => (
              <div key={index} className={style.container_events_task}>
                <div className={style.container_name_meeting}>
                  <Title fontSize={16}>{item.meeting_name}</Title>
                  <Paragraph fontSize={14} fontWeight={300}>
                    {formatDate(item.meeting_date)} - {item.meeting_duration}
                  </Paragraph>
                </div>
                <div className={style.container_bar_percentage}>
                  <BarPercentageChart
                    percetage={item.data_attendance_percent_chart.percent}
                    width={100}
                  />
                  <Paragraph fontSize={12} fontWeight={300} width="100">
                    Asistencia promedio
                  </Paragraph>
                </div>
                <NumberPerson numberPeople={item.number_of_attendees} />
                <div className={style.container_gauge}>
                  <GaugeChartSpeed
                    speed={item.data_wpm_chart.wpm}
                    percentage={item.data_wpm_chart.percent}
                    notLegend={true}
                  />
                  <Paragraph
                    fontSize={12}
                    fontWeight={300}
                    className={style.paragraph}
                  >
                    Palabras por minuto promedio
                  </Paragraph>
                </div>
              </div>
            ))
          ) : (
            <Paragraph>No hay datos</Paragraph>
          )}
        </div>
      </div>

      <div className={style.container_event_task_second}>
        <div
          className={`${style.box_graph_shadown} ${style.box_graph_shadown_width_25}`}
        >
          {dataDashboardRecomendation?.wpm_chart_recommendation && (
            <ButtonFloatContainer
              data={dataDashboardRecomendation.wpm_chart_recommendation}
            />
          )}

          <Title fontSize={14} fontWeight={700} className={style.title_box}>
            Palabras por minuto promedio
          </Title>
          {dataDashboard.data_wpm_chart ? (
            <GaugeChartSpeed
              speed={dataDashboard.data_wpm_chart?.wpm}
              percentage={dataDashboard.data_wpm_chart?.percent}
              notLegend={true}
              widthLine={220}
            />
          ) : (
            <Paragraph>Sin datos</Paragraph>
          )}
        </div>

        <div
          className={`${style.box_graph_shadown} ${style.box_graph_shadown_width_25}`}
        >
          {dataDashboardRecomendation?.attendance_percent_chart_recommendation && (
            <ButtonFloatContainer
              data={
                dataDashboardRecomendation.attendance_percent_chart_recommendation
              }
            />
          )}
          <Title fontSize={14} fontWeight={700} className={style.title_box}>
            % de asistencia promedio
          </Title>

          {dataDashboard.data_attendance_percent_chart ? (
            <MediaPieChart
              percentage={dataDashboard.data_attendance_percent_chart.percent}
              widhtLine={220}
            />
          ) : (
            <Paragraph>Sin datos</Paragraph>
          )}
        </div>

        <div
          className={`${style.box_graph_shadown} ${style.box_graph_shadown_width_25}`}
        >
          {dataDashboardRecomendation?.sentiment_statuses_chart_recommendation && (
            <ButtonFloatContainer
              data={
                dataDashboardRecomendation.sentiment_statuses_chart_recommendation
              }
            />
          )}
          <Title fontSize={14} fontWeight={700} className={style.title_box}>
            Estados emocionales intervenciones
          </Title>

          {dataEmotions.length > 0 ? (
            <PieChartBar
              data={dataEmotions}
              itemLegentRectangular={true}
              heigthBar={100}
            />
          ) : (
            <Paragraph>Sin datos</Paragraph>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventsMeetingsCharts;
