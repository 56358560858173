import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import auth from "store/slices/auth";
import login from "store/slices/login";
import filter from "store/slices/filter";
import dashboard from "./slices/dashboard";
import Users from "./slices/users";
import Plans from "./slices/plans";
import Payment from "./slices/payment";
import superAdmin from "./slices/superAdmin";
import SuperAdmin from "pages/superAdmin/store/index";
import admin from "../pages/admin/store/index";
import Loading from "./slices/loading";
import Colaborator from "pages/collaborator/store";
import DocumentsManagement from "pages/document-management/store";
import Calendar from "pages/calendar/store";
import LandingPage from "./slices/landingPage";
import ActionComponents from "./slices/actionComponents";
import Knowledge from "./slices/knowledge";
import Register from "./slices/register";
import FollowUp from "./slices/follow-up";
import DatesFollowUp from "./slices/date-follow-up";

export const store = configureStore({
  reducer: {
    actionComponents: ActionComponents,
    landingPage: LandingPage,
    loading: Loading,
    auth: auth,
    register: Register,
    login: login,
    filter: filter,
    dashboard: dashboard,
    users: Users,
    plans: Plans,
    payment: Payment,
    superadmin: superAdmin,
    superAdmin: SuperAdmin,
    colaborator: Colaborator,
    admin: admin,
    documentsManagement: DocumentsManagement,
    calendar: Calendar,
    knowledge: Knowledge,
    followup: FollowUp,
    datesFollowUp: DatesFollowUp,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Interface thunks
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppThunkTemplate<T> = ThunkAction<
  T,
  RootState,
  unknown,
  Action<string>
>;
