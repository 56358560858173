import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  getProcess,
  setOpenNewGoal,
  setOpenNewLeader,
  setSelectedProcess,
  setUpdateProcess,
  setisOpenNewProcess,
} from "pages/admin/store/slices/process";
import { ProcessData } from "./models";
import { Process } from "./process";

export const ProcessContainer = () => {
  const dispatch = useDispatch<any>();
  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });
  const {
    process,
    isOpenNewGoal,
    isOpenNewLeader,
    isOpenNewProcess,
  } = useSelector((state: RootState) => {
    return state.admin.process;
  });

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const value = filterParams?.search;

  const normalizeAndCompare = (
    text: string | undefined,
    searchTerm: string
  ): boolean => {
    const normalizedText = text
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const normalizedSearchTerm = searchTerm
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return (
      normalizedText?.includes(normalizedSearchTerm) ||
      normalizedText === normalizedSearchTerm
    );
  };

  const filtered =
    process &&
    process?.filter((item) => {
      const searchTerm = value ? value : "";
      const nameMatch = normalizeAndCompare(
        item?.proceso?.process_name,
        searchTerm
      );
      const descriptionMatch = normalizeAndCompare(
        item?.proceso?.descriptive_paragraph,
        searchTerm
      );

      return nameMatch || descriptionMatch;
    });

  useEffect(() => {
    if (dataUser) {
      dispatch(getProcess());
    }
  }, [dataUser]);

  const handleOpenDialogLeader = () => {
    dispatch(setOpenNewLeader(!isOpenNewLeader));
  };
  const handleOpenDialogGoal = () => {
    dispatch(setOpenNewGoal(!isOpenNewGoal));
  };
  const handleOpenDialogProcess = () => {
    dispatch(setisOpenNewProcess(!isOpenNewProcess));
    dispatch(setSelectedProcess(undefined));
  };

  const handleUpdateProcess = (process: ProcessData) => {
    dispatch(setisOpenNewProcess(true));
    dispatch(setSelectedProcess(process));
    dispatch(setUpdateProcess(true));
  };

  return (
    <Process
      handleOpenDialogGoal={handleOpenDialogGoal}
      handleOpenDialogLeader={handleOpenDialogLeader}
      handleOpenDialogProcess={handleOpenDialogProcess}
      handleUpdateProcess={handleUpdateProcess}
      process={filtered}
    />
  );
};
