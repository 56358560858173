import ImageLogo from "assets/logo.webp";
import style from "./style/fcdt-logo.module.scss";
import { FCDTLogoProps } from "./model";
import { useNavigate } from "react-router-dom";

const Logo = (props: FCDTLogoProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={`${style.container_logo} ${props.className}`}
      style={{ width: props.width }}
    >
      <img
        src={props.urlImae ? props.urlImae : ImageLogo}
        alt="Logo Rykwa"
        width="100%"
        onClick={() => (props.urlNavigate ? navigate(props.urlNavigate) : {})}
      />
    </div>
  );
};

export default Logo;
