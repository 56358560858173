import FCDTModal from "components/modal-notification/fcdt-modal";
import style from "./styles/modal-add-task.module.scss";
import React from "react";
import { ModalAddTaskProps } from "./model";
import {
  IoCalendarNumberOutline,
  IoCloseOutline,
  IoCreateOutline,
  IoTrashOutline,
} from "react-icons/io5";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import Button from "components/buttons/fcdt-button";
import Title from "components/fcdt-title/fcdt-title";

const ModalAddTask = (props: ModalAddTaskProps) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    openModal,
    dataAllColaborator,
    dataColaboratorSelected,
    handleChangeAutocompleted,
    handleDeleteColaborator,
    handleShowModalDates,
    handleGeneralKeyPress,
    subTasks,
    handleEditCloseDelete,
    handleChangeTask,
    handleKeyPress,
    handleCloseModal,
  } = props;

  const children = (
    <div className={style.container_principal}>
      <form
        className={style.process_container}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={style.content}>
          <section>
            <h1> Añadir como tarea</h1>
            <div>
              <div className={style.content_left}>
                <textarea
                  placeholder="Nombre de tarea"
                  pattern="^(?!.*(\d){5,})[a-zA-Z0-9áéíóúÁÉÍÓÚüÜ.:%!¡¿'?ñÑ,\s]*$"
                  required
                  {...register("taks_name")}
                  className={`fcdt-input ${style.text_area_name}`}
                />
                <textarea
                  placeholder="Descripción"
                  pattern="^(?!.*(\d){5,})[a-zA-Z0-9áéíóúÁÉÍÓÚüÜ.:%!¡¿'?ñÑ,\s]*$"
                  required
                  className="fcdt-input"
                  {...register("task_description")}
                />

                <select
                  {...register("task_priority")}
                  required
                  className="fcdt-select"
                >
                  <option hidden value="">
                    Nivel de prioridad
                  </option>
                  <option value="1">Urgente</option>
                  <option value="2">Importante</option>
                  <option value="3">Media</option>
                  <option value="4">Baja</option>
                </select>
              </div>
              <div className={style.content_center}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={dataAllColaborator}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Añadir responsables" />
                  )}
                  value={{ label: "", uid: "" }}
                  onSelect={handleChangeAutocompleted}
                  onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "onClick") {
                      e.preventDefault();
                    }
                  }}
                /> */}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={dataAllColaborator}
                  sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Añadir responsables" />
              )}
              onChange={handleChangeAutocompleted} 
              value={{ label: "", uid: "" }} 
            />
                <ul>
                  {dataColaboratorSelected.map((colaborator, index) => {
                    return (
                      <li key={index}>
                        <span>{colaborator.label}</span>{" "}
                        <IoCloseOutline
                          className="fcdt-icon"
                          onClick={() =>
                            handleDeleteColaborator(colaborator.uid, false)
                          }
                        />
                      </li>
                    );
                  })}
                </ul>

                <label htmlFor="calendar" className={style.label_calendar}>
                  Fechas importantes
                </label>
                <button
                  className={style.button_calendar}
                  type="button"
                  onClick={handleShowModalDates}
                >
                  <IoCalendarNumberOutline className={style.icon} /> Calendario
                </button>
              </div>
            </div>

            <div className={style.container_subtask}>
              <Title fontSize={18}>Subtreas</Title>
              <div className={style.container_show_sub_task}>
                {subTasks.map((item) => {
                  return (
                    <div>
                      {item.isEdit ? (
                        <input
                          type="text"
                          className="fcdt-input"
                          placeholder="Subtarea"
                          value={item.name}
                          onChange={(e) => handleChangeTask(e, item)}
                          onKeyDownCapture={(e) => handleGeneralKeyPress(e)}
                        />
                      ) : (
                        <li>{item.name}</li>
                      )}

                      <div className={style.container_icons}>
                        {!item.isEdit ? (
                          <IoCreateOutline
                            size={25}
                            className={style.icon}
                            onClick={() => handleEditCloseDelete(item, 3)}
                          />
                        ) : (
                          <IoCloseOutline
                            size={25}
                            className={style.icon}
                            onClick={() => handleEditCloseDelete(item, 3)}
                          />
                        )}

                        <IoTrashOutline
                          size={25}
                          className={style.icon}
                          onClick={() => handleEditCloseDelete(item, 4)}
                        />
                      </div>
                    </div>
                  );
                })}

                <div>
                  <input
                    type="text"
                    className={`fcdt-input ${style.input_add_sub_task}`}
                    placeholder="Agregar subtarea"
                    {...register("subtask")}
                    onKeyDownCapture={(e) => handleKeyPress(e)}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className={style.content_buttons}>
          <Button
            className="btn_cancel"
            children="Atrás"
            width="40"
            type="button"
            onClick={handleCloseModal}
          />
          <Button children="Guardar" width="40" type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <FCDTModal
      open={openModal}
      children={children}
      handleClose={handleCloseModal}
      width="auto"
    />
  );
};

export default ModalAddTask;
