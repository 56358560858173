import style from "./styles/number-person.module.scss";
import { IoPeopleOutline } from "react-icons/io5";

const NumberPerson = (props: { numberPeople: number }) => {
  return (
    <div className={style.container_principal}>
      <IoPeopleOutline size={25} /> <strong>{props.numberPeople}</strong>
    </div>
  );
};

export default NumberPerson;
