import Button from "components/buttons/fcdt-button";
import { IoWarningOutline } from "react-icons/io5";
import style from "../style/confirmationMessage.module.scss";
import { useDispatch } from "react-redux";
import { setConfirmationMessage } from "store/slices/superAdmin";
import { ConfirmationMessageProps } from "../model";

export const ConfirmationMessage = (props: ConfirmationMessageProps) => {
  const { message } = props;
  const dispatch = useDispatch<any>();

  return (
    <div className={style.container_confirmation_message}>
      <div className={style.content_text}>
        <IoWarningOutline size={100} color="#2f0084" />
        <p>¿Estás seguro(a) que deseas Eliminar esta {message}?</p>
      </div>
      <div className={style.content_buttons}>
        <Button
          className="btn_cancel"
          children="Cancelar"
          width="50"
          onClick={() => dispatch(setConfirmationMessage(false))}
        />
        <Button children="Eliminar" width="50" />
      </div>
    </div>
  );
};
