import { useEffect, useRef, useState } from "react";
import style from "../../styles/nav-bar.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { FormDatesKnownledge } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { setDatesNavbar } from "store/slices/knowledge";
import { RootState } from "store/store";

const SelectDates = () => {
  const dispatch = useDispatch();

  const { isReloadNeeded } = useSelector((state: RootState) => {
    return state.knowledge;
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<FormDatesKnownledge>({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    if (isReloadNeeded) {
      setSelectedDates({ startDate: "", endDate: "" });
    }
  }, [isReloadNeeded]);

  const ref = useRef<any>(null);

  const toggleDate = (date: string, service: number) => {
    const newDate = new Date(date).toISOString();
    if (service === 1) {
      dispatch(setDatesNavbar({ date: newDate, service: 1 }));
      setSelectedDates({ ...selectedDates, startDate: date });
    } else {
      setSelectedDates({ ...selectedDates, endDate: date });
      dispatch(setDatesNavbar({ date: newDate, service: 2 }));
    }
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref} className={style.select_dates}>
      <div
        className={style.container_date_keywords_butn}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="fcdt-title-responsive">Fecha</span>
      </div>
      {isOpen ? (
        <div className={style.options_container}>
          <div>
            <Paragraph className={style.paragraph}>Inicio</Paragraph>
            <input
              type="date"
              className="fcdt-input"
              value={selectedDates.startDate || ""}
              onChange={(e) => toggleDate(e.target.value, 1)}
            />
          </div>
          <div>
            <Paragraph className={style.paragraph}>Finalización</Paragraph>
            <input
              disabled={!selectedDates.startDate}
              type="date"
              className="fcdt-input"
              min={selectedDates.startDate}
              value={selectedDates.endDate || ""}
              onChange={(e) => toggleDate(e.target.value, 2)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelectDates;
