import { FCDTLinkProps } from "./model";
import style from "./style/fcdt-link.module.scss";

const Link = (props: FCDTLinkProps) => {
  return (
    <span
      className={`${style.container_link} ${props.className}`}
      style={{
        ...props.style,
        fontWeight: props.fontWeight ? props.fontWeight : 600,
        fontSize: props.fontSize,
        width: `max-content`,
        borderBottom: `1px solid${props.borderColor}`,
        color: props.color,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </span>
  );
};

export default Link;
