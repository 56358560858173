import FCDTModal from "components/modal-notification/fcdt-modal";
import style from "./style/modal-review-minute.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import ImageCollaborator from "assets/image_collaborator.webp";
import Button from "components/buttons/fcdt-button";
import { ModalReviewProps } from "pages/calendar/model";

const ModalInfoReviwer = (props: ModalReviewProps) => {
  const { openModal, handleCloseModal } = props;

  const children = (
    <div className={style.container_modal_review}>
      <Title fontSize={20} width="80" fontWeight={700}>
        Da click en el participante que será encargado de ser revisor del acta{" "}
      </Title>
      <img src={ImageCollaborator} alt="Collaborator" width={100} />

      <Title fontSize={14} fontWeight={300} width="90">
      Para seleccionar el revisor del acta, haz clic en el usuario correspondiente. Si no seleccionas a ninguno, se te asignará automáticamente como revisor predeterminado.
      </Title>
      <br/><br/>
    </div>
  );

  return (
    <FCDTModal
      open={openModal}
      children={children}
      buttonCloseinfo={true}
      handleClose={handleCloseModal}
      width="40%"
    />
  );
};

export default ModalInfoReviwer;
