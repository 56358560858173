import ModalDates from "./modal-dates";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FormAddDatesNotification } from "./model";
import {
  setShowModalDatesNotification,
  updateDatesTask,
} from "pages/collaborator/store/slices/notification";
import toast from "react-hot-toast";

const ModalDatesContainer = () => {
  const dispatch: any = useDispatch();

  const { showModalDatesNotification } = useSelector((state: RootState) => {
    return state.colaborator.notifications;
  });

  const { register, handleSubmit } = useForm<FormAddDatesNotification>();

  const handleCloseModal = () => {
    dispatch(setShowModalDatesNotification(false));
  };

  const onSubmit = async (values: FormAddDatesNotification) => {    
    const res = await dispatch(updateDatesTask(values));

    if (!res?.ok) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    toast.success(res?.msg, {duration: 7000, position:"top-center"});
    dispatch(setShowModalDatesNotification(false));
  };

  if (!showModalDatesNotification) return null;

  return (
    <ModalDates
      handleCloseModal={handleCloseModal}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};

export default ModalDatesContainer;
