import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";
import * as API from "pages/superAdmin/components/users/api/api";
import { displayLoader, removeProcess } from "store/slices/loading";
import {
  ResponseUserSuperAdmin,
  UserSuperAdmin,
  UserSuperAdminTable,
} from "pages/superAdmin/components/users/model";

type InitialState = {
  dataUsers: UserSuperAdmin[];
  dataUserTable: UserSuperAdminTable[];
  isFeching: boolean;
  isReloadNeeded: boolean;
};
const initialState: InitialState = {
  dataUsers: [],
  dataUserTable: [],
  isFeching: false,
  isReloadNeeded: true,
};
export const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersInit: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = true;
    },
    setUsersSuccess: (state) => {
      state.isFeching = false;
      state.isReloadNeeded = false;
    },
    setReloadUsers: (state) => {
      state.isReloadNeeded = true;
    },
    setDataUsers: (state, actions: PayloadAction<UserSuperAdmin[]>) => {
      state.dataUsers = actions.payload;
      const newData: UserSuperAdminTable[] = [];
      actions.payload.forEach((item) => {
        newData.push({
          id: item.uid,
          name: item.id_person.name,
          email: item.email,
        });
      });
      state.dataUserTable = newData;
    },
  },
});
export const { setReloadUsers } = slice.actions;
export default slice.reducer;

export const getUsers = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    dispatch(slice.actions.setUsersInit());
    const { token } = getState().auth.dataSuperAdmin!;
    const response: ResponseUserSuperAdmin = await API.getUsers(token);
    dispatch(slice.actions.setDataUsers(response.users));
    dispatch(slice.actions.setUsersSuccess());
    dispatch(removeProcess(idProcess));
    return true;
  } catch (error: any) {
    dispatch(removeProcess(idProcess));
    return false;
  }
};
