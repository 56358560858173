import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import style from "./style/funnel-chart.module.scss";

type FunnelChartProps = {
  data: { label: string; value: number }[];
};

const FunnelChart = (props: FunnelChartProps) => {
  const maxValue = props.data.reduce(
    (max, item) => (item.value > max ? item.value : max),
    0
  );

  return (
    <section className={style.container_principal_funnel}>
      {props.data.map((item, index) => {
        return (
          <div key={index} className={style.container_name_bar}>
            <div className={style.container_name}>
              <Paragraph fontWeight={300} fontSize={14}>{item.label}</Paragraph>
            </div>
            <div className={style.container_bar}>
              <div className={style.bar_funnel} style={{ width: `${(item.value * 100) / maxValue}%` }}>
                <span>{item.value}</span>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default FunnelChart;
