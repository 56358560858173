import React from "react";
import { PaymentProps } from "./model";
import style from "./style/payment.module.scss";
import PaymentBG from "assets/payment-bg.svg";
import Button from "components/buttons/fcdt-button";
import { Link } from "react-router-dom";
import Basic from "assets/basico.svg";
import Company from "assets/empresas.svg";
import Premiun from "assets/profesional.svg";
const Payment = (props: PaymentProps) => {
  const { plan, register, handleSubmit, handleChange, onSubmit } = props;

  return (
    <div className={style.payment_container}>
      <img className={style.decorator} src={PaymentBG} alt="Imagen de fondo" />
      <article>
        <header>
          <img
            src={
              plan.plan.id_plan === 1
                ? Basic
                : plan.plan.id_plan === 2
                ? Premiun
                : Company
            }
            alt="Ilustración del plan"
          />
          <h1>{plan.plan.plan_name}</h1>
          <p>
            {plan.plan.plan_price}
            <span>/MES</span>
          </p>
        </header>
        <ul>
          {plan.functionalities.map((fucionality, index) => (
            <li key={index}>
              {fucionality.id_functionality.functionality_name}
            </li>
          ))}
        </ul>
      </article>
      <aside>
        <h2>Tus compras</h2>
        <p>Tienes un item en tus compras</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <span> Valor por mes</span>
            <input
              type="text"
              className="fcdt-input"
              placeholder="0"
              disabled
              required
              {...register("priceMonth")}
            />
          </div>
          <div>
            <span>Número de meses</span>
            <input
              type="number"
              className="fcdt-input"
              placeholder="0"
              min={1}
              required
              {...register("numberMonth")}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div>
            <span>Subtotal</span>
            <input
              type="text"
              className="fcdt-input"
              placeholder="0"
              disabled
              required
              {...register("subPrice")}
            />
          </div>
          <div>
            <span>Descuento</span>
            <input
              type="text"
              className="fcdt-input"
              placeholder="0"
              required
              disabled
              {...register("discounts")}
            />
          </div>
          <div className={style.container_total_price}>
            <h2>Total a pagar</h2>
            <input
              type="text"
              className="fcdt-input"
              placeholder="0"
              required
              disabled
              {...register("totalPrice")}
            />
          </div>
          <Button>Comprar</Button>
        </form>
        <Link to={"/"}>Cancelar compra</Link>
      </aside>
    </div>
  );
};

export default Payment;
