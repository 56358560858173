import React, { useEffect, useState } from "react";
import Selectors from "./selectors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { DataMultiSelect } from "components/fcdt-multiselect/model";
import {
  getDataFollowUp,
  getDependencesFollowUpFilter,
  setDataColaboratorSelected,
  setDataEventFolowUpSelected,
  setDataGoalSelected,
  setDataProcessSelected,
  setIdShowDashboard,
} from "store/slices/follow-up";
import { setSelectdDateRange } from "store/slices/date-follow-up";

const SelectorsContainer = () => {
  const dispatch: any = useDispatch();

  const [isFeching, setIsFeching] = useState(true);

  const {
    processes,
    processSelected,
    goals,
    goalSelected,
    collaborators,
    colaboratorSelected,
    events,
    eventsSelected,
  } = useSelector((state: RootState) => {
    return state.followup;
  });

  useEffect(() => {
    setTimeout(() => {
      if (processes.length > 0 && isFeching) {
        dispatch(getDependencesFollowUpFilter(processes[0].uid, null));
        dispatch(getDataFollowUp(processes[0].uid, null, null, false));
        dispatch(setDataProcessSelected(processes[0]));
        dispatch(setDataEventFolowUpSelected({ label: "", uid: "1" }));
        setIsFeching(false);
      }
    }, 300);
  }, [processes, isFeching]);

  const { dateDatesSelector } = useSelector((state: RootState) => {
    return state.datesFollowUp;
  });

  const handleChangeDates = (values: DataMultiSelect[]) => {
    dispatch(setSelectdDateRange(values[0]));

    if (goalSelected && colaboratorSelected) {
      dispatch(
        getDataFollowUp(null, goalSelected.uid, colaboratorSelected.uid, false)
      );
      dispatch(setIdShowDashboard(values[0].uid === "1" ? 4 : 5));
    } else if (goalSelected && !colaboratorSelected) {
      dispatch(getDataFollowUp(null, goalSelected.uid, null, false));
      dispatch(setIdShowDashboard(values[0].uid === "1" ? 2 : 3));
    } else if (!goalSelected && !colaboratorSelected && processSelected) {
      dispatch(getDataFollowUp(processSelected.uid, null, null, false));
      dispatch(setIdShowDashboard(1));
    }
  };

  const handleChangeProcesses = (values: DataMultiSelect[]) => {
    if (values.length === 0) {
      dispatch(setDataProcessSelected(undefined));
      return;
    }
    if (processSelected?.uid === values[0].uid) {
      return;
    }
    dispatch(setIdShowDashboard(1));
    dispatch(setDataProcessSelected(values[0]));
    dispatch(getDependencesFollowUpFilter(values[0].uid, null));
    dispatch(getDataFollowUp(values[0].uid, null, null, false));
  };

  const handleChangeGoals = (values: DataMultiSelect[]) => {
    if (values.length === 0) {
      dispatch(setDataGoalSelected(undefined));
      return;
    }
    dispatch(setIdShowDashboard(eventsSelected[0] ? 2 : 3));
    dispatch(setDataGoalSelected(values[0]));
    dispatch(getDependencesFollowUpFilter(null, values[0].uid));
    dispatch(getDataFollowUp(null, values[0].uid, null, false));
  };

  const handleChangeCollaborator = (value: DataMultiSelect[]) => {
    dispatch(
      setDataColaboratorSelected(value.length > 0 ? value[0] : undefined)
    );
    if (value.length > 0 && goalSelected) {
      dispatch(setIdShowDashboard(eventsSelected[0] ? 4 : 5));
      dispatch(getDataFollowUp(null, goalSelected.uid, value[0].uid, true));
    }
  };

  const handleChangeEvents = (values: DataMultiSelect[]) => {
    dispatch(
      setDataEventFolowUpSelected(values.length > 0 ? values[0] : undefined)
    );
    if (goalSelected && colaboratorSelected) {
      dispatch(
        getDataFollowUp(null, goalSelected.uid, colaboratorSelected.uid, false)
      );
      dispatch(setIdShowDashboard(values[0].uid === "1" ? 4 : 5));
    } else if (goalSelected && !colaboratorSelected) {
      dispatch(getDataFollowUp(null, goalSelected.uid, null, false));
      dispatch(setIdShowDashboard(values[0].uid === "1" ? 2 : 3));
    }
  };

  return (
    <Selectors
      processes={processes}
      goals={goals}
      collaborators={collaborators}
      events={events}
      dates={dateDatesSelector}
      handleChangeDates={handleChangeDates}
      handleChangeProcess={handleChangeProcesses}
      handleChangeGoals={handleChangeGoals}
      handleChangeCollaborator={handleChangeCollaborator}
      handleChangeEvents={handleChangeEvents}
    />
  );
};

export default SelectorsContainer;
