import React from "react";
import ModalDeleteTask from "./modal-delete-task";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  setDescriptionDeleteTask,
  setIsEditTask,
  setIsModalDeleteUpdateTask,
  setStatusToggle,
} from "pages/collaborator/store/slices/process";
import { useForm } from "react-hook-form";
import { FormDeleteTask } from "../../model";

const ModalDeleteTaskContainer = () => {
  const dispatch = useDispatch();

  const { isModalDeleteTask, isEditTask } = useSelector((state: RootState) => {
    return state.colaborator.process;
  });

  const { register, handleSubmit, reset } = useForm<FormDeleteTask>();

  const handleCloseModal = () => {
    dispatch(setIsModalDeleteUpdateTask(false));
    dispatch(setIsEditTask(false));
    reset();
  };

  const onSubmit = (values: FormDeleteTask) => {
    dispatch(setDescriptionDeleteTask(values.description));
    dispatch(setIsModalDeleteUpdateTask(false));

    if (!isEditTask) {
      dispatch(setStatusToggle(false));
    }

    reset();
  };

  if (!isModalDeleteTask) return null;

  return (
    <ModalDeleteTask
      handleCloseModal={handleCloseModal}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isEditTask={isEditTask}
    />
  );
};

export default ModalDeleteTaskContainer;
