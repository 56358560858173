import React from "react";
import ModalShowMeeting from "./modal-show-meeting";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  setshowModalDeleteMeeting,
  setshowModalDescriptionMeeting,
} from "pages/calendar/store/slices/calendar";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { setAutocompleteInformationToEdit } from "pages/calendar/store/slices/create-event";

const ModalShowMeetingContainer = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { showModalDescriptionMeeting, dataDetailsMeeting } = useSelector(
    (state: RootState) => {
      return state.calendar.calendar;
    }
  );

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const handleCloseModal = () => {
    dispatch(setshowModalDescriptionMeeting(false));
  };

  const handleNavigate = () => {
    if (dataDetailsMeeting) {
      dispatch(setAutocompleteInformationToEdit(dataDetailsMeeting));
      navigate(
        ROUTES.CALENDAR.CHILDRENS.EDIT_EVENT.replace(
          ":id_meeting",
          dataDetailsMeeting.meeting.uid
        )
      );
      handleCloseModal();
    }
  };

  const handleRejectMeeting = () => {
    dispatch(setshowModalDescriptionMeeting(false));
    dispatch(setshowModalDeleteMeeting(true));
  };

  if (!dataDetailsMeeting || !dataUser) return null;

  return (
    <ModalShowMeeting
      openModal={showModalDescriptionMeeting}
      handleCloseModal={handleCloseModal}
      dataMeeting={dataDetailsMeeting}
      handleNavigate={handleNavigate}
      handleRejectMeeting={handleRejectMeeting}
      isLeader={dataUser.collaborator.uid === dataDetailsMeeting.created_by}
    />
  );
};

export default ModalShowMeetingContainer;
