import FCDTModal from "components/modal-notification/fcdt-modal";
import React from "react";
import style from "./style/modal-view-minute.module.scss";
import Button from "components/buttons/fcdt-button";
import Title from "components/fcdt-title/fcdt-title";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { ModalViewMinuteProps } from "../../model";
// import toast from "react-hot-toast";

const ModalViewMinute = (props: ModalViewMinuteProps) => {
  const { open, handleCloseModal, data } = props;
  
  // toast.success("Recuerde que la información proporcionada es limitada, ya que se trata únicamente de una demostración del archivo cargado.", {duration: 8000, position:"top-center"});

  // const formatText = (text: string) => {
  //   // Divide el texto en partes donde encuentra el símbolo "-"
  //   const parts = text.split("-");
  //   return parts;
  // };
  // const formattedText = formatText(data?.commitments || "");

  const children = (
    <div className={style.container_principal}>
      <Title fontSize={18} fontWeight={600}>
        Acta de transcripción
      </Title>

      <section className={style.section_paragraph}>
        <Paragraph className={style.paragrahp}>
          <strong>Resumen: </strong> <span>{data?.summary}</span>
        </Paragraph>
      </section>

      <section className={style.section_paragraph}>
        <Paragraph className={style.paragrahp}>
          <strong>Compromiso: </strong>
          <span>{data?.commitments}</span>
          {/* <span>
            {formattedText.map((part, index) => (
              <React.Fragment key={index}>
                -{part}
                {index < formattedText.length - 1 && <br />}
              </React.Fragment>
            ))}
          </span> */}
        </Paragraph>
      </section>

      <section className={style.section_paragraph}>
        <Paragraph className={style.paragrahp}>
          <strong>Conclusiones: </strong> <span>{data?.conclusions}</span>
        </Paragraph>
      </section>

      <section className={style.section_paragraph}>
        <Paragraph className={style.paragrahp}>
          <strong>Tema de discusión: </strong>{" "}
          <span>{data?.subject_discussion}</span>
        </Paragraph>
      </section>

      <section className={style.section_btn}>
        <Button onClick={handleCloseModal}>Cerrar</Button>
      </section>
    </div>
  );

  return (
    <FCDTModal
      open={open}
      children={children}
      handleClose={handleCloseModal}
      width="40%"
    />
  );
};

export default ModalViewMinute;
