import FCDTModal from "components/modal-notification/fcdt-modal";
import style from "./style/modal-button_recommendation.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { ModalButtonRecomendationsProps } from "./model";

const ModalButtonRecomendation = (props: ModalButtonRecomendationsProps) => {
  const { open, handleCloseModal, data } = props;

  const children = (
    <div className={style.container_principal}>
      <Title fontWeight={800} className={style.title} width="90">{data.title}</Title>
      <Paragraph fontWeight={300}>{data.recommendation_text}</Paragraph>
      <div></div>

      <div className={style.container_link}>
        <a href={data.link} target="_blank" rel="noreferrer">
          Ver artículo de referencia
        </a>
      </div>
    </div>
  );

  return (
    <FCDTModal
      open={open}
      children={children}
      handleClose={handleCloseModal}
      width="40%"
    />
  );
};

export default ModalButtonRecomendation;
