import { mappingRoute } from "helpers/formatRoute";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { getStatusPayment } from "store/slices/payment";

const StatusPayment = () => {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const searchParams = new URLSearchParams(location.search);

  const idStatus = param.id_payment!;
  const idPayment: string = JSON.parse(localStorage.getItem("@idPayment")!);
  const ePaycoRef = searchParams.get("ref_payco");

  useEffect(() => {
    const handleRequest = async (
      idPayment: string,
      idStatus: string,
      ePaycoRef: string
    ) => {
      const res: any = await dispatch(
        getStatusPayment(idPayment, idStatus, ePaycoRef)
      );
      if (!res?.ok) {
        toast.error(res?.msg, {duration: 7000, position:"top-center"});
        return;
      }

      navigate(
        mappingRoute(ROUTES.COLLABORATOR.PARENT, ROUTES.DASHBOARD.NOTIFICATION)
      );

      if (idStatus === "2") {
        toast.error("Su compra fue rechazada, intentalo nuevamente", {duration: 7000, position:"top-center"});
        return;
      }

      toast.success(res?.msg, {duration: 7000, position:"top-center"});

      //   In this place, you have the logic to redirect home and show a modal. If status is 1, show message congratulation, else other message thar show a bad response
    };

    if (idPayment && ePaycoRef) {
      handleRequest(idPayment, idStatus, ePaycoRef);
    }
  }, [idPayment, idStatus, ePaycoRef, dispatch, navigate]);

  return <div></div>;
};

export default StatusPayment;
