import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ResponseNotification } from "pages/notifications/model";
import { AppThunk } from "store/store";
import * as API from "../../../notifications/api/api";
import { displayLoader, removeProcess } from "store/slices/loading";
import { FormAddDatesNotification } from "pages/notifications/components/modal-dates/model";

type InitialState = {
  isReloadNeed: boolean;
  isFeching: boolean;
  notifications?: ResponseNotification;
  showModalDatesNotification: boolean;
  idTaskSelected: string;
  idDateSelected: string;
};
const initialState: InitialState = {
  isReloadNeed: true,
  isFeching: false,
  notifications: undefined,
  showModalDatesNotification: false,
  idTaskSelected: "",
  idDateSelected: "",
};

export const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationsInit: (state) => {
      state.isReloadNeed = true;
      state.isFeching = true;
    },
    setNotificationsSucess: (
      state,
      actions: PayloadAction<ResponseNotification>
    ) => {
      state.notifications = actions.payload;
      state.isReloadNeed = false;
      state.isFeching = false;
    },
    setNotificationsError: (state) => {
      state.isReloadNeed = false;
      state.isFeching = false;
    },
    setShowModalDatesNotification: (state, actions: PayloadAction<boolean>) => {
      state.showModalDatesNotification = actions.payload;
    },
    setIdTaskSelected: (
      state,
      actions: PayloadAction<{ idTask: string; idDate: string }>
    ) => {
      state.idTaskSelected = actions.payload.idTask;
      state.idDateSelected = actions.payload.idDate;
    },
  },
});
export const { setShowModalDatesNotification, setIdTaskSelected } =
  slice.actions;
export default slice.reducer;

export const getNotifications = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    dispatch(slice.actions.setNotificationsInit());
    const { token } = getState().auth.dataUser!;
    const idUser = getState().auth.dataUser?.collaborator.uid!;
    const response: ResponseNotification = await API.getNotifications(
      idUser,
      token
    );
    dispatch(slice.actions.setNotificationsSucess(response));
    dispatch(removeProcess(idProcess));
  } catch (error: any) {
    dispatch(slice.actions.setNotificationsError());
    dispatch(removeProcess(idProcess));
    console.log(error);
  }
};

export const updateDatesTask =
  (form: FormAddDatesNotification): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      dispatch(slice.actions.setNotificationsInit());
      const { token } = getState().auth.dataUser!;
      const { idTaskSelected, idDateSelected } =
        getState().colaborator.notifications;
      const response = await API.updateDatesTask(
        idDateSelected,
        idTaskSelected,
        form.date_optimistic,
        form.date_likely,
        token
      );
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.setNotificationsError());
      dispatch(removeProcess(idProcess));
      console.log(error);
    }
  };
