import React from "react";
import { useForm } from "react-hook-form";
import { FormSearchType } from "../../model";
import FormSearch from "./form-search";
import { useDispatch, useSelector } from "react-redux";
import {
  getInformationKnowledge,
  resetFilterKnowledge,
} from "store/slices/knowledge";
import { RootState } from "store/store";
import toast from "react-hot-toast";

const FormSearchContainer = () => {
  const dispatch: any = useDispatch();

  const { register, handleSubmit, reset } = useForm<FormSearchType>();

  const { eventsSelected } = useSelector((state: RootState) => {
    return state.knowledge;
  });

  const onSubmit = (values: FormSearchType) => {
    const findTrue = eventsSelected.find((e) => e);
    if (!findTrue) {
      toast.error("Seleccione al menos un evento para la busqueda", {duration: 7000, position:"top-center"});
      return;
    }

    if (values.searchTerm.length === 0) {
      dispatch(getInformationKnowledge());
    } else {
      dispatch(getInformationKnowledge(values.searchTerm));
    }
  };

  const handleResetFilter = () => {
    reset();
    dispatch(resetFilterKnowledge());
  };

  return (
    <FormSearch
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      register={register}
      handleResetFilter={handleResetFilter}
    />
  );
};

export default FormSearchContainer;
