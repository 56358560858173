import { normalizeAndCompare } from "common/helpers/pagination";

type UseFilterProps = {
  data: any;
  searchValue: string;
};

const UseDataFilter = (props: UseFilterProps) => {
  const { data, searchValue } = props;

  if (data.length === 0 || searchValue === "") return { filtered: data };

  const keys = Object.keys(data[0]);

  const filtered = data.filter((item: any) => {
    for (let i = 0; i <= keys.length; i++) {
      const currentKey = keys[i];

      const text = eval(`item.${currentKey}`);

      if (typeof text === "string") {
        const res = normalizeAndCompare(text, searchValue);

        if (res) {
          return true;
        }
      }
    }
  });

  return { filtered };
};

export default UseDataFilter;
