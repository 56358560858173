import { Route, Routes } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { SIDEBAR_LINKS_COLABORATOR } from "../constants";
import Layout from "components/layout/Layout";
import { NotificationContainer } from "pages/notifications/notification-container";
import { UserContainer } from "pages/users/user-container";
import TableProcessContainer from "../components/process/components/table-process/table-process-container";
import TableObjetivesContainer from "../components/process/components/table-objetives/table-objetives-container";
import AddTaksContainer from "../components/process/components/add-objetive/add-taks-container";
import CalendarRoutes from "pages/calendar/routes/calendar.routes";
import DocumentManagementRoutes from "pages/document-management/routes/document-management-routes";
import KnowLedgeContainer from "pages/knowledge/knowledge-container";
import FollowUpContainer from "pages/follow-up/follow-up-container";

const CollaboratorRoutes = () => {
  const { NOTIFICATION, USERS, PROCESS, KNOWLEDGE, FOLLOWUP } =
    ROUTES.DASHBOARD;
  const { CHILDRENS } = ROUTES.COLLABORATOR;
  const { GOALS, TAKS } = CHILDRENS;

  return (
    <Routes>
      <Route
        path={NOTIFICATION}
        element={
          <Layout routes={SIDEBAR_LINKS_COLABORATOR}>
            <NotificationContainer />
          </Layout>
        }
      />

      <Route
        path={USERS}
        element={
          <Layout routes={SIDEBAR_LINKS_COLABORATOR}>
            <UserContainer />
          </Layout>
        }
      />

      <Route
        path={PROCESS}
        element={
          <Layout routes={SIDEBAR_LINKS_COLABORATOR}>
            <TableProcessContainer />
          </Layout>
        }
      />

      <Route
        path={PROCESS + GOALS}
        element={
          <Layout routes={SIDEBAR_LINKS_COLABORATOR}>
            <TableObjetivesContainer />
          </Layout>
        }
      />

      <Route
        path={PROCESS + GOALS + TAKS}
        element={
          <Layout routes={SIDEBAR_LINKS_COLABORATOR}>
            <AddTaksContainer />
          </Layout>
        }
      />

      <Route
        path={ROUTES.CALENDAR.PARENT}
        element={<CalendarRoutes routes={SIDEBAR_LINKS_COLABORATOR} />}
      ></Route>

      <Route
        path={ROUTES.DOCUMENT_MANAGEMENT.PARENT}
        element={
          <DocumentManagementRoutes routes={SIDEBAR_LINKS_COLABORATOR} />
        }
      ></Route>

      <Route
        path={KNOWLEDGE}
        element={
          <Layout routes={SIDEBAR_LINKS_COLABORATOR}>
            <KnowLedgeContainer />
          </Layout>
        }
      />

      <Route
        path={FOLLOWUP}
        element={
          <Layout routes={SIDEBAR_LINKS_COLABORATOR}>
            <FollowUpContainer />
          </Layout>
        }
      />
    </Routes>
  );
};

export default CollaboratorRoutes;
