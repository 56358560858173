import React, { useEffect } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import UseDataFilter from "common/hooks/use-data-filter";
import { NUMBER_ITEMS_PAGINATION } from "common/contants";
import usePagination from "common/hooks/use-pagination";
import { getProcessesName } from "pages/collaborator/store/slices/process";
import TableProcess from "./table-process";

const TableProcessContainer = () => {
  const dispatch: any = useDispatch();

  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });

  const { dataTableProcess, isFeching, isReloadNeeded } = useSelector(
    (state: RootState) => {
      return state.colaborator.process;
    }
  );

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const value = filterParams?.search;
  const searchTerm = value ? value : "";

  useEffect(() => {
    if (isReloadNeeded && !isFeching && dataUser) {
      dispatch(getProcessesName());
    }
  }, [isReloadNeeded, isFeching, dataUser, dispatch]);

  const { filtered } = UseDataFilter({
    data: dataTableProcess,
    searchValue: searchTerm,
  });

  const { currentPage, totalPages, itemsToShow, setCurrentPage } =
    usePagination({
      currentPage: 1,
      filterData: filtered,
      numberItems: NUMBER_ITEMS_PAGINATION,
    });

  return (
    <div className="fcdt-center">
      <TableProcess data={itemsToShow} />
      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        maxWidth={100}
      />
    </div>
  );
};

export default TableProcessContainer;
