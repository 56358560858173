export const notificationCategory = (id: number) => {
  switch (id) {
    case 1:
      return "Urgente";
    case 2:
      return "Importante";
    case 3:
      return "Media";
    case 4:
      return "Baja";
  }
};
