import React from "react";
import style from "../../style/landing-page.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import { ComponentsLandingProps } from "pages/landing-page/model";
import PlansContainer from "pages/plans/plans-container";

const Plans = (props: ComponentsLandingProps) => {
  return (
    <div id={props.id} className={style.container_plans}>
      <Title>Elige tu plan</Title>
      <PlansContainer />
    </div>
  );
};

export default Plans;
