import React from "react";
import ModalReport from "./modal-report";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { setshowModalReport } from "pages/document-management/store/slices/reports";

const ModalReportContainer = () => {
  const dispatch: any = useDispatch();

  const { showModalReport, dataReport } = useSelector((state: RootState) => {
    return state.documentsManagement.reports;
  });

  const handleCloseModal = () => {
    dispatch(setshowModalReport(false));
  };

  if (!dataReport) return null;

  return (
    <ModalReport
      openModal={showModalReport}
      handleCloseModal={handleCloseModal}
      dataReport={dataReport}
    />
  );
};

export default ModalReportContainer;
