export const peso = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 0,
});
export const reverseFormat = (currency: string) => {
  const firstStep = currency.split("").slice(2, currency.split("").length);
  const deletePoint = firstStep.filter((number: any) => number !== ".");
  const finalResult = deletePoint.join("");
  return finalResult;
};

export const currencyFormatter = (currency: string, value: number) => {
  const formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    minimumFractionDigits: 0,
    currency,
  });
  return formatter.format(value);
};
