import { FormAddTaks } from "pages/collaborator/components/process/model";
import { ChangeEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import ModalAddTask from "./modal-add-task";
import { SubTaskts } from "./model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  setDataColaboratorSelected,
  setDeleteColaborator,
} from "pages/calendar/store/slices/create-event";
import {
  createdTask,
  setAddEditCloseDeleteSubTask,
  setIsModalDates,
} from "pages/collaborator/store/slices/process";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { setServiceRecomendedAction } from "pages/document-management/store/slices/recomendations";

const ModalAddTaskContainer = () => {
  const dispatch: any = useDispatch();

  const idGoal = useParams().id_process_goal!;

  const { register, handleSubmit, resetField, setValue } =
    useForm<FormAddTaks>();

  const { showModalAddTask, actionSelected } = useSelector(
    (state: RootState) => {
      return state.documentsManagement.recomendations;
    }
  );

  const { dataAllColaborator, dataColaboratorSelected } = useSelector(
    (state: RootState) => {
      return state.calendar.createEvent;
    }
  );

  const { subtasks, datesCalendar } = useSelector((state: RootState) => {
    return state.colaborator.process;
  });

  useEffect(() => {
    if (actionSelected) {
      setValue("task_description", actionSelected.task_name);
    }
  }, [actionSelected]);

  const handleCloseModal = () => {
    dispatch(setServiceRecomendedAction({ service: 2, idAction: NaN }));
  };

  // Send form

  const onSubmit = async (values: FormAddTaks) => {
    if (!datesCalendar) {
      toast.error("Completar las fechas en el calendario", { duration: 7000, position: "top-center" });
      return;
    }
    if (dataColaboratorSelected.length === 0) {
      toast.error("Agregar al menos un participante a la tarea", { duration: 7000, position: "top-center" });
      return;
    }

    const colaboratos = dataColaboratorSelected.map((item) => {
      return item.uid;
    });

    const res: any = await dispatch(createdTask(values, idGoal, colaboratos));
    if (!res?.ok) {
      toast.error(res?.msg, { duration: 7000, position: "top-center" });
      return;
    }
    dispatch(
      setServiceRecomendedAction({
        service: 1,
        idAction: actionSelected ? actionSelected.id : NaN,
      })
    );
    handleCloseModal();
    toast.success(`¡Tarea creada exitosamente!`, { duration: 7000, position: "top-center" });
  };

  // Collaborator

  // const handleChangeAutocompleted = (e: ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;

  const handleChangeAutocompleted = (e: any, newData: any) => {
    const value = e.target.innerText;
    
    if (newData) {
      let id = newData.uid
      const filterFilms = dataAllColaborator.find((item) => item.uid === id);
      if (!filterFilms) return;
      if (value) {
        let newData = [...dataColaboratorSelected];
        const filterRes = newData.find((item) => item.label === value);
        if (filterRes) return;
        newData.push({
          label: filterFilms.label,
          uid: filterFilms.uid,
          isReviwer: false,
        });
        dispatch(setDataColaboratorSelected(newData));
      }
    }
  };

  const handleDeleteColaborator = (value: string, isExternal: boolean) => {
    dispatch(setDeleteColaborator({ value, isExternal }));
  };

  // Calendar

  const handleShowModalDates = () => {
    dispatch(setIsModalDates(true));
  };

  // Subtask

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      dispatch(
        setAddEditCloseDeleteSubTask({
          service: 1,
          value: e.target.value,
          task: undefined,
        })
      );

      resetField("subtask");
    }
  };

  const handleGeneralKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChangeTask = (
    e: ChangeEvent<HTMLInputElement>,
    task: SubTaskts
  ) => {
    e.preventDefault();

    dispatch(
      setAddEditCloseDeleteSubTask({
        service: 2,
        value: e.target.value,
        task,
      })
    );
  };

  const handleEditCloseDelete = (task: SubTaskts, service: number) => {
    dispatch(setAddEditCloseDeleteSubTask({ service, value: "", task }));
  };

  return (
    <ModalAddTask
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      openModal={showModalAddTask}
      handleCloseModal={handleCloseModal}
      handleShowModalDates={handleShowModalDates}
      dataAllColaborator={dataAllColaborator}
      dataColaboratorSelected={dataColaboratorSelected}
      handleChangeAutocompleted={handleChangeAutocompleted}
      handleDeleteColaborator={handleDeleteColaborator}
      subTasks={subtasks}
      handleEditCloseDelete={handleEditCloseDelete}
      handleKeyPress={handleKeyPress}
      handleChangeTask={handleChangeTask}
      handleGeneralKeyPress={handleGeneralKeyPress}
    />
  );
};

export default ModalAddTaskContainer;
