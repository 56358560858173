import axios from "axios";
// import { APIURL, PERSONS_PORT } from "config/APIURL";
import { APIURL } from "config/APIURL";

import { EmailReset } from "../model";

export const passwordReset = async (form: EmailReset) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/usuarios/password_recovery`,
      data: form,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
export const updatePassword = async (token: string, password: string) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/usuarios/password_recovery/${token}`,
      data: { password: password },
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response?.data);
    return error.response?.data;
  }
};
