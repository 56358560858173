import React from "react";
import { useNavigate } from "react-router-dom";
import Home from "./home";

const HomeContainer = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return <Home handleNavigate={handleNavigate} />;
};

export default HomeContainer;
