import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { DataCompaniesTable } from "./model";
import Companies from "./companies";
import UseDataFilter from "common/hooks/use-data-filter";
import usePagination from "common/hooks/use-pagination";
import { NUMBER_ITEMS_PAGINATION } from "common/contants";
import ResponsivePagination from "react-responsive-pagination";
import {
  getCompanies,
  setCompanySelected,
  setIsModalMoreInformation,
} from "pages/superAdmin/store/slices/companies";
import ModalInformationCompaniesContainer from "./components/modal-information-container";

const CompaniesContainer = () => {
  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });

  const { dataCompaniesTable, isFeching, isReloadNeeded } = useSelector(
    (state: RootState) => {
      return state.superAdmin.companies;
    }
  );

  const dispatch: any = useDispatch();

  const value = filterParams?.search;
  const searchTerm = value ? value : "";

  const { dataUser, dataSuperAdmin } = useSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    if (!isFeching && isReloadNeeded && (dataUser || dataSuperAdmin)) {
      dispatch(getCompanies());
    }
  }, [isFeching, isReloadNeeded, dataUser, dataSuperAdmin, dispatch]);

  const handleOpenModal = (company: DataCompaniesTable) => {
    dispatch(setIsModalMoreInformation(true));
    dispatch(setCompanySelected(company));
  };

  const { filtered } = UseDataFilter({
    data: dataCompaniesTable,
    searchValue: searchTerm,
  });

  const { currentPage, totalPages, itemsToShow, setCurrentPage } =
    usePagination({
      currentPage: 1,
      filterData: filtered,
      numberItems: NUMBER_ITEMS_PAGINATION,
    });

  return (
    <div className="fcdt-center">
      <Companies data={itemsToShow} handleOpenModal={handleOpenModal} />
      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        maxWidth={100}
      />
      <ModalInformationCompaniesContainer />
    </div>
  );
};

export default CompaniesContainer;
