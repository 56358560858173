import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  PieLabelRenderProps,
} from "recharts";
import style from "./styles/media-pie-chart.module.scss";
import Title from "components/fcdt-title/fcdt-title";

// Definición del tipo de datos
interface DataItem {
  name: string;
  value: number;
}

type MediaPieChartProps = {
  percentage: number;
  widhtLine?: number;
  legend?: boolean;
};

const MediaPieChart = (props: MediaPieChartProps) => {
  const percentage = props.percentage;

  const data: DataItem[] = [
    { name: "Completed", value: percentage },
    { name: "Remaining", value: 100 - percentage },
  ];

  const COLORS = ["#00DE97", "#DDDDDD"];

  const renderCustomizedLabel = (props: PieLabelRenderProps) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, name } = props;
    const RADIAN = Math.PI / 180;

    if (name === "Remaining") {
      return null; // No renderizar etiqueta para el segmento 'Remaining'
    }

    const safeCx = Number(cx ?? 0);
    const safeCy = Number(cy ?? 0);
    const safeInnerRadius = Number(innerRadius ?? 0);
    const safeOuterRadius = Number(outerRadius ?? 0);

    const radius = safeInnerRadius + (safeOuterRadius - safeInnerRadius) * 0.3;
    const x = safeCx + radius * Math.cos(-midAngle! * RADIAN);
    const y = safeCy + radius * Math.sin(-midAngle! * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > safeCx ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontSize: "14px", fontWeight: "bold" }}
      >
        {`${(percent! * 100).toFixed(0)}%`}
      </text>
    );
  };

  const children = (
    <ResponsiveContainer width="100%" height={150}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="100%"
          startAngle={180}
          endAngle={0}
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );

  return (
    <div className={style.container_principal}>
      {children}

      <div
        className={style.line_botton}
        style={{ width: props.widhtLine ? props.widhtLine : "" }}
      ></div>

      {props.legend && (
        <section className={style.container_legend}>
          <div className={style.box}></div>{" "}
          <Title fontSize={12}>Participación en eventos</Title>
        </section>
      )}
    </div>
  );
};

export default MediaPieChart;
