import FCDTModal from "components/modal-notification/fcdt-modal";
import style from "./style/modal-information.module.scss";
import { ModalInformationCompaniesProps } from "../model";
import FCDTToggle from "components/fcdy-toggle/fcdt-toggle";

const ModalInformationCompanies = (props: ModalInformationCompaniesProps) => {
  const { data, dataToggle, handleChangeToggle, handleCloseModal } = props;

  const children = (
    <section className={style.payments_container}>
      <section className={style.container_title_toggle}>
        <h2>{data.company_name}</h2>
        <FCDTToggle data={dataToggle} handleChange={handleChangeToggle} />
      </section>

      <div className={style.content}>
        <table>
          <thead>
            <tr>
              <th>Fecha de compra de licencia</th>
              <th>ID de la empresa</th>
              <th>Número de procesos realizados</th>
              <th>Tipo de plan</th>
            </tr>
          </thead>
          <tbody>
            <tr key={data.id}>
              <td>{data.company_date_expiration}</td>
              <td>{data.company_name}</td>
              <td>{data.company_number_process}</td>
              <td>{data.company_plan}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={`${style.content} ${style.content_price_table}`}>
        <table className={style.container_th}>
          <thead>
            <tr>
              <th>Valor pagado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>$ {data.company_amount}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );

  return (
    <FCDTModal open={true} children={children} handleClose={handleCloseModal} />
  );
};

export default ModalInformationCompanies;
