import axios from "axios";

// import { APIURL, COMPANIES_PORT } from "config/APIURL";
import { APIURL } from "config/APIURL";

import { FormRegisterCompany } from "../model";

export const registerCompany = async (form: FormRegisterCompany) => {
  const data = {
    company_name: form.name,
    company_contact_email: form.email,
    company_contact_number: form.phone_number,
    id_meeting_platform: parseInt(form.platform),
  };
  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/empresa/crear`,
      data,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response);
    return error.response?.data;
  }
};
