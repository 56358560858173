import { DataMultiSelect } from "components/fcdt-multiselect/model";

export const handleMappinValuesKnowledge = (
  values: DataMultiSelect[]
): string[] => {
  const newValues: string[] = [];

  values.forEach((item) => {
    if (item.uid !== "-1") {
      newValues.push(item.uid);
    }
  });

  return newValues;
};
