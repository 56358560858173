import { PropsErrors } from "./model";


export const Errors = (props: PropsErrors) => {
    const { name } = props;
    return (
        <>
            {name && name.type === "required" && <p className="message_error">El campo es requerido</p>}
            {name && name.type === "minLength" && <p className="message_error">Debe de tener al menos 6 caracteres</p>}
            {name && name.type === "maxLength" && <p className="message_error">Debe de tener máximo 100 caracteres</p>}
            {name && name.type === "pattern" && <p className="message_error">El nombre debe contener al menos una letra y puede incluir letras, números y los siguientes caracteres especiales: . , - : á é í ó ú Á É Í Ó Ú ( )</p>}
        </>
    )
}
