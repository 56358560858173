import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  isToggle: boolean;
};
const initialState: InitialState = {
  isToggle: true,
};
export const slice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setIsToggle: (state, action: PayloadAction<boolean>) => {
      state.isToggle = action.payload;
    },
  },
});
export const { setIsToggle } = slice.actions;

export default slice.reducer;
