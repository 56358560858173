import React from "react";
import style from "../../styles/nav-bar.module.scss";
import MultiSelect from "components/fcdt-multiselect/fcdt-multiselect";
import { SelectorsProps } from "../../model";

const Selectors = (props: SelectorsProps) => {
  const {
    processes,
    goals,
    collaborators,
    events,
    dates,
    handleChangeDates,
    handleChangeProcess,
    handleChangeGoals,
    handleChangeCollaborator,
    handleChangeEvents,
  } = props;

  return (
    <div className={style.selectors}>
      <div className={style.container_dates}>
        <MultiSelect
          label={"Intervalo de seguimiento"}
          data={dates}
          onChange={handleChangeDates}
          notMultipleSelecton={true}
          selectFirstItem={true}
        />
      </div>
      <div>
        <MultiSelect
          label={"Proceso"}
          data={processes}
          onChange={handleChangeProcess}
          notMultipleSelecton={true}
          selectFirstItem={true}
        />
        <MultiSelect
          label={"Actividades"}
          data={goals}
          onChange={handleChangeGoals}
          notMultipleSelecton={true}
        />
        <MultiSelect
          label={"Colaborador"}
          data={collaborators}
          onChange={handleChangeCollaborator}
          notMultipleSelecton={true}
        />
        <MultiSelect
          label={"Monitor"}
          data={events}
          onChange={handleChangeEvents}
          notMultipleSelecton={true}
          selectFirstItem={true}
        />
      </div>

      {/* <SelectDates /> */}
    </div>
  );
};

export default Selectors;
