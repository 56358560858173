import Title from "components/fcdt-title/fcdt-title";
import style from "./style/register.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Button from "components/buttons/fcdt-button";
import { RegisterProps } from "./model";
import Link from "components/fcdt-link/fcdt-link";
import { Errors } from "components/errors/errors";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { APIKEY_RECAPCHA } from "pages/login/constants";
import { useState } from "react";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const Register = (props: RegisterProps) => {
  const { register, handleSubmit, onSubmit, errors } = props;

  const [isCaptcha, setIsCaptcha] = useState<any>("");
  const navigate = useNavigate();

  const [showPassword, setshowPassword] = useState(false);
  const [showRepeatPassword, setshowRepeatPassword] = useState(false);

  return (
    <div className={style.container_register}>
      <section className={style.container_title_form}>
        <Title
          children="Debes registrarte para unirte"
          fontWeight={700}
          fontSize={25}
        />
        <Paragraph
          children="Somos un equipo que nos guiamos mutuamente"
          className={style.paragrap}
          fontWeight={300}
        />

        <form
          className={style.container_form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="text"
            className="fcdt-input"
            placeholder="Nombre y apellidos"
            required
            minLength={3}
            maxLength={50}
            pattern={REG_EXP.FULL_NAME}
            title={TITLE_REG_EXP.FULL_NAME}
            {...register("name")}
          />
          <Errors name={errors.name} />

          <input
            type="email"
            className="fcdt-input"
            placeholder="Correo"
            required
            maxLength={50}
            minLength={6}
            pattern={REG_EXP.EMAIL}
            title={TITLE_REG_EXP.EMAIL}
            {...register("email")}
          />
          <Errors name={errors.email} />

          <input
            type="text"
            className="fcdt-input"
            placeholder="Usuario en plataforma de reuniones"
            required
            minLength={3}
            maxLength={50}
            pattern={REG_EXP.FULL_NAME}
            title={TITLE_REG_EXP.FULL_NAME}
            {...register("meetingName")}
          />

          <div className="fcdt-input-box-password">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Contraseña"
              required
              minLength={8}
              maxLength={50}
              pattern={REG_EXP.PASSWORD}
              title={TITLE_REG_EXP.PASSWORD}
              {...register("password")}
            />
            <div onClick={() => setshowPassword(!showPassword)}>
              {showPassword ? (
                <IoEyeOutline size={27} className="fcdt-icon-gray" />
              ) : (
                <IoEyeOffOutline size={27} className="fcdt-icon-gray" />
              )}
            </div>
          </div>
          <Errors name={errors.password} />

          <div className="fcdt-input-box-password">
            <input
              type={showRepeatPassword ? "text" : "password"}
              placeholder="Repetir contraseña"
              required
              minLength={8}
              maxLength={50}
              pattern={REG_EXP.PASSWORD}
              title={TITLE_REG_EXP.PASSWORD}
              {...register("confirmPassword")}
            />
            <div onClick={() => setshowRepeatPassword(!showRepeatPassword)}>
              {showRepeatPassword ? (
                <IoEyeOutline size={27} className="fcdt-icon-gray" />
              ) : (
                <IoEyeOffOutline size={27} className="fcdt-icon-gray" />
              )}
            </div>
          </div>
          <Errors name={errors.confirmPassword} />

          <ReCAPTCHA
            sitekey={APIKEY_RECAPCHA}
            onChange={(value) => setIsCaptcha(value)}
          />
          {isCaptcha === "" && (
            <p className="message_error">El captcha es requerido</p>
          )}
          <Button
            children="Continuar"
            type="submit"
            disabled={isCaptcha === "" ? false : false}
          />

          <Paragraph className={style.text_cancel_process}>
            Desea{" "}
            <Link
              children="Cancelar"
              onClick={() => navigate("/home")}
              color="#606263"
              fontWeight={500}
            />{" "}
            el registro
          </Paragraph>
        </form>
      </section>
    </div>
  );
};

export default Register;
