import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DataSpeechTreeByID,
  MinutesContent,
  ResponseSpeechTree,
  SpeechTreeTable,
} from "pages/document-management/model";
import { displayLoader, removeProcess } from "store/slices/loading";
import { AppThunk } from "store/store";
import * as Api from "pages/document-management/api/api";
import { downloadFile } from "helpers/downloadFile";

type InitialState = {
  isReloadNeeded: boolean;
  isFeching: boolean;
  dataTree: MinutesContent[];
  dataTreeTable: SpeechTreeTable[];
  treeSelected?: SpeechTreeTable;
  openSpeechTree: boolean;
  speechTreeSelected?: DataSpeechTreeByID;
};
const initialState: InitialState = {
  isReloadNeeded: true,
  isFeching: false,
  dataTree: [],
  dataTreeTable: [],
  treeSelected: undefined,
  openSpeechTree: false,
  speechTreeSelected: undefined,
};
export const slice = createSlice({
  name: "speechTree",
  initialState,
  reducers: {
    setSpeechTreeInit: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = true;
    },
    setSpeechTreeSucess: (state, actions: PayloadAction<MinutesContent[]>) => {
      state.dataTree = actions.payload;

      const newDataTable: SpeechTreeTable[] = [];

      actions.payload.forEach((item, index) => {
        const newDate = new Date(item.associated_meeting.meeting_start_date);

        newDataTable.push({
          id: index + 1,
          tree_name: item.associated_meeting.meeting_name,
          tree_date: newDate.toISOString().split("T")[0],
          uid: item.associated_meeting.uid,
        });
      });

      state.dataTreeTable = newDataTable;
      state.isReloadNeeded = false;
      state.isFeching = false;
    },
    setSpeechTreeByIdSucess: (
      state,
      action: PayloadAction<SpeechTreeTable | undefined>
    ) => {
      state.treeSelected = action.payload;
    },
    setSpeechTree: (state, action: PayloadAction<boolean>) => {
      state.openSpeechTree = action.payload;
    },
    setSpeechTreeSelectedSucess: (
      state,
      action: PayloadAction<DataSpeechTreeByID>
    ) => {
      state.speechTreeSelected = action.payload;
    },
    setReloadDocument: (state) => {
      state.isReloadNeeded = true;
    },
  },
});
export const { setSpeechTree, setSpeechTreeByIdSucess, setReloadDocument } =
  slice.actions;

export default slice.reducer;

export const getDataSpeechTree = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    const { token } = getState().auth.dataUser!;
    const response = await Api.getAllDocuments(token);
    dispatch(slice.actions.setSpeechTreeSucess(response.minutesContents));
    dispatch(removeProcess(idProcess));
    return true;
  } catch (error) {
    dispatch(removeProcess(idProcess));
    return false;
  }
};

export const getSpeechTreeByID =
  (idSpeechTree: string): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const response: ResponseSpeechTree = await Api.getSpeechTreeByID(
        idSpeechTree,
        token
      );
      dispatch(slice.actions.setSpeechTreeSelectedSucess(response.meeting));
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getImageSpeechTree =
  (download?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const { speechTreeSelected } = getState().documentsManagement.speechTree;
      const response = await Api.getImageSpeechTree(
        speechTreeSelected?.meeting.uid!,
        token
      );
      if (download && response.data.file_name) {
        downloadFile(response.data.file_name, `speech_tree.png`);
      }
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
