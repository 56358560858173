import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataMultiSelect } from "components/fcdt-multiselect/model";
import { dataEventFollowUp } from "pages/knowledge/constants";
import {
  ResponseKnowledgeSelectors,
  ResultsSelectorsKnowledge,
} from "pages/knowledge/model";
import { displayLoader, removeProcess } from "./loading";
import { AppThunk } from "store/store";
import * as ApiKnowledge from "pages/knowledge/api/api";
import * as Api from "pages/follow-up/api/api";
import { formatDate } from "helpers/formatDate";
import {
  ChartRecommendation,
  DataDashboard,
  DataDashboardRecomendation,
  ResponseDataDashboard,
  ResponseDataDashboardData,
} from "pages/follow-up/models";

type InitialState = {
  isReloadNeeded: boolean;
  processes: DataMultiSelect[];
  goals: DataMultiSelect[];
  collaborators: DataMultiSelect[];
  events: DataMultiSelect[];
  processSelected?: DataMultiSelect;
  goalSelected?: DataMultiSelect;
  colaboratorSelected?: DataMultiSelect;
  eventsSelected: boolean[];
  dataDashboard?: DataDashboard;
  asymtotic: number;
  idShowDashboard: number;
  showModalRecomendations: boolean;
  showModalButtonRecomendations: boolean;
  dataDashboardRecomendation?: DataDashboardRecomendation;
  dataDashboardRecomendationSelected?: ChartRecommendation;
};
const initialState: InitialState = {
  isReloadNeeded: true,
  processes: [],
  goals: [],
  collaborators: [],
  events: dataEventFollowUp,
  colaboratorSelected: undefined,
  goalSelected: undefined,
  eventsSelected: [false, false, false],
  dataDashboard: undefined,
  asymtotic: NaN,
  idShowDashboard: 1,
  showModalRecomendations: false,
  showModalButtonRecomendations: true,
  dataDashboardRecomendation: undefined,
  dataDashboardRecomendationSelected: undefined,
};
export const slice = createSlice({
  name: "follow-up",
  initialState,
  reducers: {
    setDependenceKnowInit: (state) => {
      state.isReloadNeeded = true;
    },
    setDependenceKnowSucess: (
      state,
      action: PayloadAction<ResultsSelectorsKnowledge | undefined>
    ) => {
      if (action.payload) {
        if (action.payload.processes) {
          state.processes = [];
          state.processes = action.payload.processes.map((item) => {
            return { uid: item.id_process, label: item.process_name };
          });
        }
        if (action.payload.processes_goals) {
          if (action.payload.processes_goals.length > 0) {
            state.goals = [];

            state.goals = action.payload.processes_goals.map((item) => {
              return { uid: item.id_process_goal, label: item.goal_title };
            });
          }
        }

        state.collaborators = action.payload.goal_collaborators.map((item) => {
          return {
            uid: item.id_collaborator,
            label: item.name_in_meeting_platform,
          };
        });
      }

      state.isReloadNeeded = false;
    },
    setDependenceKnowError: (state) => {
      state.isReloadNeeded = false;
    },
    setDataProcessSelected: (
      state,
      action: PayloadAction<DataMultiSelect | undefined>
    ) => {
      state.processSelected = action.payload;
    },
    setDataColaboratorSelected: (
      state,
      action: PayloadAction<DataMultiSelect | undefined>
    ) => {
      state.colaboratorSelected = action.payload;
    },
    setDataGoalSelected: (
      state,
      action: PayloadAction<DataMultiSelect | undefined>
    ) => {
      state.goalSelected = action.payload;
    },
    setDataEventFolowUpSelected: (
      state,
      action: PayloadAction<DataMultiSelect | undefined>
    ) => {
      if (!action.payload) {
        state.eventsSelected = state.eventsSelected.map(() => false);
        return;
      }
      state.eventsSelected[0] = action.payload.uid === "1" ? true : false;
      state.eventsSelected[1] = action.payload.uid === "2" ? true : false;
    },
    setDataDashboard: (
      state,
      action: PayloadAction<ResponseDataDashboardData | undefined>
    ) => {
      if (!action.payload) return;
      state.dataDashboard = action.payload.data;
      state.dataDashboardRecomendation = action.payload.recommendations;
    },
    setAsymtotic: (state, action: PayloadAction<number>) => {
      state.asymtotic = action.payload;
    },
    setIdShowDashboard: (state, action: PayloadAction<number>) => {
      state.idShowDashboard = action.payload;
    },
    setShowModalRecomendations: (state, action: PayloadAction<boolean>) => {
      state.showModalRecomendations = action.payload;
    },
    setShowModalButtonRecomendations: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showModalButtonRecomendations = action.payload;
    },
    setDataDashboardRecomendationSelected: (
      state,
      action: PayloadAction<ChartRecommendation>
    ) => {
      state.dataDashboardRecomendationSelected = action.payload;
    },
  },
});
export const {
  setDataProcessSelected,
  setDataGoalSelected,
  setDataColaboratorSelected,
  setDataEventFolowUpSelected,
  setIdShowDashboard,
  setShowModalRecomendations,
  setShowModalButtonRecomendations,
  setDataDashboardRecomendationSelected,
} = slice.actions;

export default slice.reducer;

export const getDependencesSelectors =
  (): AppThunk => async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token, collaborator } = getstate().auth.dataUser!;
      const { id_company } = collaborator;

      dispatch(slice.actions.setDependenceKnowInit());
      const response: ResponseKnowledgeSelectors =
        await ApiKnowledge.getDependencesKnowledge(
          id_company,
          null,
          null,
          token
        );
      dispatch(
        slice.actions.setDependenceKnowSucess(
          response.ok ? response.data.results : undefined
        )
      );
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      dispatch(slice.actions.setDependenceKnowError());
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getDependencesFollowUpFilter =
  (procesess: string | null, goals: string | null): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;

      const response: ResponseKnowledgeSelectors =
        await ApiKnowledge.getDependencesKnowledge(
          null,
          procesess ? [procesess] : null,
          goals ? [goals] : null,
          token
        );
      dispatch(
        slice.actions.setDependenceKnowSucess(
          response.ok ? response.data.results : undefined
        )
      );
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getDataFollowUp =
  (
    idProcesses: string | null,
    idGoal: string | null,
    idCollaborator: string | null,
    idTaskByPriority: boolean
  ): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;
      const { eventsSelected } = getstate().followup;
      const { start_date, end_date } =
        getstate().datesFollowUp.dataDateSelected!;

      const response: ResponseDataDashboard = await Api.getDataFollowUp(
        idProcesses,
        idGoal,
        idCollaborator,
        eventsSelected,
        idTaskByPriority,
        formatDate(start_date) + "T00:00:00",
        formatDate(end_date) + "T00:00:00",
        token
      );
      dispatch(
        slice.actions.setDataDashboard(response.ok ? response.data : undefined)
      );
      dispatch(
        slice.actions.setAsymtotic(
          response.ok ? response.data.asymptotic_value : NaN
        )
      );
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error: any) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getDownloadRecomendation =
  (): AppThunk => async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;
      const {
        processSelected,
        goalSelected,
        colaboratorSelected,
        eventsSelected,
        dataDashboard,
        dataDashboardRecomendation,
      } = getstate().followup;
      const { start_date, end_date } =
        getstate().datesFollowUp.dataDateSelected!;

      const response: ResponseDataDashboard =
        await Api.generatePdfRecomendation(
          processSelected ? processSelected.uid : null,
          goalSelected ? goalSelected.uid : null,
          colaboratorSelected ? colaboratorSelected.uid : null,
          eventsSelected,
          true,
          formatDate(start_date) + "T00:00:00",
          formatDate(end_date) + "T00:00:00",
          token,
          dataDashboard,
          dataDashboardRecomendation
        );

      dispatch(removeProcess(idProcess));
      return response;
    } catch (error: any) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
