import React, { useEffect, useState } from "react";
import style from "./style/report.module.scss";
import { BiSolidRectangle } from "react-icons/bi";
import {
  IoCalendarNumberOutline,
  IoCheckmarkCircle,
  IoDownloadOutline,
} from "react-icons/io5";
import Button from "components/buttons/fcdt-button";
import Title from "components/fcdt-title/fcdt-title";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { ReportsProps } from "./model";
import { formatDate } from "helpers/formatDate";  // Función para formatear fechas
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { get_generate_monitoring_report } from "pages/document-management/api/api";
import { setshowModalReport, setDataReport } from "pages/document-management/store/slices/reports";

const Reports = (props: ReportsProps) => {
  const dispatch: any = useDispatch();
  const { data, register, handleSubmit, onSubmit, handleNavigate } = props;

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });
  const { showModalReport } = useSelector((state: RootState) => {
    return state.documentsManagement.reports;
  });

  const [datainfo, setdatainfo] = useState<any>([]);
  const [datainfoStatus, setDatainfoStatus] = useState<boolean>(true);

  const fetchData = async (): Promise<any> => {
    if (dataUser) {
      try {
        const auxi_data_info: any[] = await get_generate_monitoring_report(
          dataUser?.collaborator.id_person,
          dataUser?.token
        );
        setdatainfo(auxi_data_info ? auxi_data_info : []);
        setDatainfoStatus(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setdatainfo([]);
      }
    }
  };

  useEffect(() => {
    if (datainfoStatus||showModalReport) {
      fetchData();
    }
  }, [datainfo]);

  return (
    <section  className={style.speech_tree_container}>
      <Title className={style.title} typeTitle={true} >Generación de reportes</Title>
      <form onSubmit={handleSubmit(onSubmit)} className={style.container_content}>
        <p>
          <BiSolidRectangle size={30} color="#00de97" />
          {data.goal_title}
        </p>
        <p>
          <IoCalendarNumberOutline size={30} />
          Fecha de cumplimiento de la actividad:{" "}
          <span>{formatDate(data.start_date)}</span>
        </p>
        <hr />
        <h3>Lista de reportes generados</h3>
        {datainfo?.dataResponse?.map((report: any, index: any) => (
          <div
            key={index}
            onClick={() => {
              dispatch(setshowModalReport(true));
              dispatch(
                setDataReport(
                  datainfo?.dataResponse
                    ? {
                        ...datainfo?.dataResponse[index]?.report_data,
                        start_date: datainfo?.dataResponse[index]?.report_data.startDate,
                        end_date: datainfo?.dataResponse[index]?.report_data.endDate,
                      }
                    : undefined
                )
              );
            }}
            className={`${style.content_collaborators} ${style.container_collaborators_block}`}
          >
            <div className={style.container_generated_minutes}>
              <IoCheckmarkCircle className={style.icon_checkmark} size={22} />
              <Paragraph fontWeight={300} className={style.paragraph_list_minutes}>
                Reporte de {formatDate(report.start_date)} al {formatDate(report.end_date)}
              </Paragraph>
            </div>
          </div>
        ))}
        <hr />
        <h3>Selecciona las fechas de interés</h3>
        <div className={style.content_collaborators}>
          <div>
            <Paragraph fontWeight={300}>Inicio</Paragraph>
            <input
              type="date"
              className="fcdt-input"
              max={formatDate(data.expiration_date)}
              required
              {...register("start_date")}
            />
          </div>
          <div>
            <Paragraph fontWeight={300}>Finalización</Paragraph>
            <input
              type="date"
              className="fcdt-input"
              max={formatDate(data.expiration_date)}
              required
              {...register("end_date")}
            />
          </div>
        </div>
        <hr />
        <div className={style.actions}>
          <button onClick={() => fetchData()} type="submit">
            Ver informe
          </button>
        </div>
      </form>

      <div className={style.action_step}>
        <Button className="btn_cancel" children="Atrás" onClick={() => handleNavigate(1)} />
        <Button type="button" onClick={() => handleNavigate(2)}>
          Aceptar
        </Button>
      </div>
    </section>
  );
};

export default Reports;
