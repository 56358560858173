import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import UseDataFilter from "common/hooks/use-data-filter";
import usePagination from "common/hooks/use-pagination";
import { NUMBER_ITEMS_PAGINATION } from "common/contants";
import ResponsivePagination from "react-responsive-pagination";
import SpeechTree from "./speech-tree";
import { getDataSpeechTree } from "pages/document-management/store/slices/speech-tree";

const SpeechTreeContainer = () => {
  const dispatch: any = useDispatch();

  const { isReloadNeeded, isFeching, dataTreeTable } = useSelector(
    (state: RootState) => {
      return state.documentsManagement.speechTree;
    }
  );

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });

  const value = filterParams?.search;
  const searchTerm = value ? value : "";

  useEffect(() => {
    if (isReloadNeeded && !isFeching && dataUser) {
      dispatch(getDataSpeechTree());
    }
  }, [isReloadNeeded, isFeching, dataUser, dispatch]);

  const { filtered } = UseDataFilter({
    data: dataTreeTable,
    searchValue: searchTerm,
  });

  const { currentPage, totalPages, itemsToShow, setCurrentPage } =
    usePagination({
      currentPage: 1,
      filterData: filtered,
      numberItems: NUMBER_ITEMS_PAGINATION,
    });

  return (
    <div className="fcdt-center">
      <SpeechTree data={itemsToShow} />
      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        maxWidth={100}
      />
    </div>
  );
};

export default SpeechTreeContainer;
