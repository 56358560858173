import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterParams } from "components/filter/model";
import { displayLoader, removeProcess } from "store/slices/loading";
import { AppThunk } from "store/store";

import * as Api from "pages/document-management/api/api";
import {
  ResponseTaskRecomended,
  TaskRecomended,
} from "pages/document-management/components/documents/components/recommended-task/model";

type InitialState = {
  taskRecomended: TaskRecomended[];
  showModalAddTask: boolean;
  showModalAddSubTask: boolean;
  actionSelected?: TaskRecomended;
};

const initialState: InitialState = {
  taskRecomended: [],
  showModalAddTask: false,
  showModalAddSubTask: false,
  actionSelected: undefined,
};
export const slice = createSlice({
  name: "recomendation",
  initialState,
  reducers: {
    setTaskRecomended: (state, action: PayloadAction<string[]>) => {
      state.taskRecomended = action.payload.map((item, index) => {
        return { id: index + 1, task_name: item };
      });
    },
    setServiceRecomendedAction: (
      state,
      action: PayloadAction<{ service: number; idAction: number }>
    ) => {
      const findAction = state.taskRecomended.find(
        (item) => item.id === action.payload.idAction
      );

      if (action.payload.service === 1) {
        state.taskRecomended = state.taskRecomended.filter(
          (item) => item.id !== action.payload.idAction
        );
      } else if (action.payload.service === 2) {
        if (state.showModalAddTask) {
          state.actionSelected = undefined;
        } else {
          state.actionSelected = findAction;
        }
        state.showModalAddTask = !state.showModalAddTask;
      } else if (action.payload.service === 3) {
        if (state.showModalAddSubTask) {
          state.actionSelected = undefined;
        } else {
          state.actionSelected = findAction;
        }
        state.showModalAddSubTask = !state.showModalAddSubTask;
      }
    },
  },
});

export const { setServiceRecomendedAction } = slice.actions;

export default slice.reducer;

export const getTaskRecomendation =
  (idMeeting: string): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;
      const response: ResponseTaskRecomended = await Api.getTaskRecomendation(
        idMeeting,
        token
      );
      dispatch(slice.actions.setTaskRecomended(response.data.commitments));
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const setSubtaskIntoTask =
  (): AppThunk => async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;
      const { taskSelected } = getstate().colaborator.process;
      const { actionSelected } = getstate().documentsManagement.recomendations;

      const response = await Api.setSubtaskIntoTask(
        taskSelected?.uid!,
        actionSelected?.task_name!,
        token
      );
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
