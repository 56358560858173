import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Payment from "./payment";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormPayment } from "./model";
import { currencyFormatter, reverseFormat } from "helpers/formatCurrency";
import { getPayment, getPlanByID } from "store/slices/plans";
import toast from "react-hot-toast";

declare let ePayco: any;

const PaymentContainer = () => {
  const params = useParams();
  const dispatch: any = useDispatch();
  const idPlan = params.id_plan!;
  const idCompany = params.id_company!;

  const { planSelected } = useSelector((state: RootState) => {
    return state.plans;
  });

  const { register, handleSubmit, setValue, watch } = useForm<FormPayment>();

  useEffect(() => {
    if (planSelected) {
      setValue(
        "priceMonth",
        currencyFormatter("COP", parseInt(planSelected.plan.plan_price))
      );
      setValue(
        "subPrice",
        currencyFormatter("COP", parseInt(planSelected.plan.plan_price))
      );
      setValue("numberMonth", "1");
      setValue(
        "totalPrice",
        currencyFormatter("COP", parseInt(planSelected.plan.plan_price))
      );
    }
  }, [planSelected, idPlan, dispatch, setValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numbersMonth = e.target.value;
    const priceMonth = reverseFormat(watch().priceMonth);
    setValue(
      "totalPrice",
      currencyFormatter(
        "COP",
        parseInt(`${parseInt(numbersMonth) * parseInt(priceMonth)}`)
      )
    );
  };

  useEffect(() => {
    if (idPlan && !planSelected) {
      dispatch(getPlanByID(idPlan));
    }
  }, [idPlan, planSelected, dispatch]);

  const onSubmit = async (values: FormPayment) => {
    const totalPrice = reverseFormat(values.totalPrice);
    const numbersMonth = values.numberMonth;
    const res: any = await dispatch(
      getPayment(idCompany, idPlan, numbersMonth, totalPrice)
    );

    if (!res.session_id) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    localStorage.setItem("@idPayment", JSON.stringify(res.id_payment));

    const handler = ePayco.checkout.configure({
      sessionId: res.session_id,
      test: false,
    });

    handler.openNew();
  };

  if (!planSelected) return null;

  return (
    <Payment
      plan={planSelected}
      register={register}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      onSubmit={onSubmit}
    />
  );
};

export default PaymentContainer;
