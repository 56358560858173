import React from "react";
import { SlClose } from "react-icons/sl";
import { DialogProps } from "./model";
import style from "./style/dialog.module.scss";
const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  children,
  styleClose = true,
  customClass = "",
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className={`${style.dialog_overlay} ${customClass}`}>
          <div
            className={`${customClass} ${
              styleClose ? style.content_dialog : style.content_second
            }`}
          >
            <button
              className={
                styleClose
                  ? style.close_button_gray
                  : style.close_button_primary
              }
              onClick={handleClose}
            >
              <SlClose size={25} />
            </button>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Dialog;
