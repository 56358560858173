import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import UseDataFilter from "common/hooks/use-data-filter";
import usePagination from "common/hooks/use-pagination";
import { NUMBER_ITEMS_PAGINATION } from "common/contants";
import ResponsivePagination from "react-responsive-pagination";
import { useEffect } from "react";
import {
  getUserFromCompany,
  setIdActionUser,
  setShowModalNewUsers,
  setUserSelected,
} from "store/slices/users";
import Users from "./users";
import Dialog from "components/modals/dialog";
import { NewLeader } from "components/modals/components/new-leader";
import { DataToggle } from "components/fcdy-toggle/model";
import { setIsToggle } from "store/slices/actionComponents";
import { UsersTable } from "./model";

export const UserContainer = () => {
  const dispatch: any = useDispatch();

  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });

  const {
    isReloadNeeded,
    tableUsers,
    showModalNewUsers,
    userSelected,
    idActionUser,
  } = useSelector((state: RootState) => {
    return state.users;
  });

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const searchTerm = filterParams ? filterParams.search : "";

  useEffect(() => {
    if (isReloadNeeded && dataUser) {
      dispatch(getUserFromCompany());
    }
  }, [isReloadNeeded, dataUser, dispatch]);

  const { filtered } = UseDataFilter({
    data: tableUsers,
    searchValue: searchTerm,
  });

  const { currentPage, totalPages, itemsToShow, setCurrentPage } =
    usePagination({
      currentPage: 1,
      filterData: filtered,
      numberItems: NUMBER_ITEMS_PAGINATION,
    });

  const handleNewUser = () => {
    dispatch(setShowModalNewUsers(true));
  };

  const handleClose = () => {
    dispatch(setShowModalNewUsers(false));
  };

  const data: DataToggle = {
    active: userSelected?.status!,
    title: "inactivo",
    titleActive: "Activo",
  };

  const handleChangeToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsToggle(e.target.checked));
  };

  const handleSelectUser = (user: UsersTable, idAction: number) => {
    handleNewUser();
    dispatch(setUserSelected(user));
    dispatch(setIdActionUser(idAction));
  };

  if (!dataUser) return null;

  return (
    <div className="fcdt-center">
      <Users
        data={itemsToShow}
        handleNewUser={handleNewUser}
        handleSelectUser={handleSelectUser}
        isAdmin={dataUser.collaborator.is_admin}
      />
      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        maxWidth={100}
      />
      <Dialog
        isOpen={showModalNewUsers}
        onClose={handleNewUser}
        styleClose={false}
      >
        <NewLeader
          handleClose={handleClose}
          dataToggle={data}
          handleChangeToggle={handleChangeToggle}
          user={userSelected}
          idAction={idActionUser}
        />
      </Dialog>
    </div>
  );
};
