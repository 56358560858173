import React from "react";
import IconSearch from "assets/icons/icon_search.webp";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import style from "../styles/body-knowledge.module.scss";
import { NotInformationProps } from "../../model";

const NotInformation = (props: NotInformationProps) => {
  return (
    <div className={style.container_not_information}>
      <img src={IconSearch} alt="Icono de busqueda" width={100} />
      {!props.hide && (
        <Paragraph width="60">Seleciona la categoría de tú búsqueda</Paragraph>
      )}
    </div>
  );
};

export default NotInformation;
