import React from "react";
import GanttChart from "./gantt-charts";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const GanttChartContainer = () => {
  const { dataReport } = useSelector((state: RootState) => {
    return state.documentsManagement.reports;
  });

  const rows: any =
    dataReport &&
    dataReport.info_from_tasks.map((item) => {
      const data = [
        item.task_name.replace(/\s/g, ""),
        item.task_name,
        null,
        new Date(item.task_start_date),
        new Date(item.task_due_date),
        null,
        100,
        null,
      ];

      return data;
    });

  return <GanttChart rows={rows} />;
};

export default GanttChartContainer;
