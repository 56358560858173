import React, { useState } from "react";
import styles from "./styles/fcdt-floating-button.module.scss";
import iconRykwa from "assets/icon_rykwa.webp";
import { useDispatch } from "react-redux";
import { setShowModalRecomendations } from "store/slices/follow-up";

const FloatingButton: React.FC = () => {
  const dispatch = useDispatch();

  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    dispatch(setShowModalRecomendations(true));
  };

  return (
    <div
      className={styles.floatingButtonContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`${styles.floatingButton} ${
          isHovered ? styles.hovered : ""
        }`}
      >
        {!isHovered && (
          <img src={iconRykwa} alt="" width={50} className={styles.icon} />
        )}
        <span
          className={`${styles.tooltipText} ${isHovered ? styles.visible : ""}`}
        >
          <button onClick={handleClick}>Recomendaciones Rykwa</button>
        </span>
      </div>
    </div>
  );
};

export default FloatingButton;
