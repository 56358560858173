import FCDTModal from "components/modal-notification/fcdt-modal";
import React from "react";
import style from "../../../../style/landing-page.module.scss";
import Title from "components/fcdt-title/fcdt-title";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Button from "components/buttons/fcdt-button";
import { ModalInformationDemoProps } from "../../model";

const ModalInformationDemo = (props: ModalInformationDemoProps) => {
  const children = (
    <div className={style.container_modal_demo}>
      <Title fontSize={22}>Explora Nuestras Funciones</Title>
      <Paragraph className={style.paragraph}>
        Te invitamos a explorar todas las funciones y herramientas que Rykwa
        tiene para ofrecer. Desde la generación de actas hasta la creación de
        árboles de conversaciones, seguimiento de tareas y más.
      </Paragraph>
      <Button className={style.button} onClick={props.handleCloseModal}>
        Aceptar
      </Button>
    </div>
  );

  return (
    <FCDTModal
      open={props.showModal}
      children={children}
      handleClose={props.handleCloseModal}
      width="40%"
    />
  );
};

export default ModalInformationDemo;
