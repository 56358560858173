import { ChangeEvent, useEffect } from "react";
import AddEvent from "./add-event";
import { useForm } from "react-hook-form";
import { FormAddEvent } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import {
  getColaborators,
  getGoalsFromProcess,
  getProcessesCollaborator,
  setdataformAddEvent,
} from "pages/calendar/store/slices/create-event";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store/store";
import { setCurrentIdAddEvent } from "pages/calendar/store/slices/calendar";
import toast from "react-hot-toast";

const AddEventContainer = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const {
    process,
    goalsFromProcess,
    isFeching,
    isReloadNeeded,
    dataformAddEvent,
  } = useSelector((state: RootState) => {
    return state.calendar.createEvent;
  });

  const { dataDetailsMeeting } = useSelector((state: RootState) => {
    return state.calendar.calendar;
  });

  const { register, handleSubmit, getValues } = useForm<FormAddEvent>({
    defaultValues: dataformAddEvent,
  });

  const { dataUser, currentDate } = useSelector((state: RootState) => {
    return state.auth;
  });

  const loaction = useLocation().pathname.split("/");

  useEffect(() => {
    if (!dataDetailsMeeting && loaction.length > 5) {
      navigate(-1);
    }
  }, [dataDetailsMeeting, navigate]);

  useEffect(() => {
    if (isReloadNeeded && !isFeching && dataUser) {
      dispatch(getProcessesCollaborator());
      if (dataDetailsMeeting) {
        dispatch(getGoalsFromProcess(dataDetailsMeeting?.id_process!));
      }
    }
  }, [isReloadNeeded, isFeching, dataUser, dataDetailsMeeting, dispatch]);

  const handleNavigate = () => {
    navigate(-1);
  };

  const onSubmit = (values: FormAddEvent) => {
    if (
      Number(values.hour_start.replaceAll(":", "")) >
      Number(values.hour_end.replaceAll(":", ""))
    ) {
      toast.error(
        "La hora de inico debe ser menor a la hora de finalización de la reunión"
        , {duration: 7000, position:"top-center"} );
      return;
    }

    dispatch(getColaborators(values.idGoal));
    dispatch(setdataformAddEvent(values));
    dispatch(setCurrentIdAddEvent(2));
  };

  const handleChangeIdProcess = (e: ChangeEvent<HTMLInputElement> | any) => {
    dispatch(
      setdataformAddEvent({ ...getValues(), idProcess: e.target.value })
    );
    dispatch(getGoalsFromProcess(e.target.value));
  };

  return (
    <AddEvent
      handleNavigate={handleNavigate}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      register={register}
      processes={process}
      handleChangeIdProcess={handleChangeIdProcess}
      goalsFromProcess={goalsFromProcess}
      currentDate={currentDate}
    />
  );
};

export default AddEventContainer;
