import Title from "components/fcdt-title/fcdt-title";
import style from "./style/bar-percentage.module.scss";

type BarPercentageChartProps = {
  percetage: number;
  width?: number;
  legend?: boolean;
};

const BarPercentageChart = (props: BarPercentageChartProps) => {
  return (
    <section className={style.container_principal}>
      <section
        className={style.container_bar_percentage}
        style={{ width: `${props.width}%` }}
      >
        <div
          className={style.progresive_box}
          style={{ width: `${props.percetage}%` }}
        >
          <span className={style.span}>
            <Title fontSize={20} fontWeight={600}>
              {props.percetage}%
            </Title>
          </span>
        </div>
      </section>
      {props.legend && (
        <section className={style.container_legend}>
          <div className={style.box}></div>{" "}
          <Title fontSize={12}>Asistencia del participante a eventos</Title>
        </section>
      )}
    </section>
  );
};

export default BarPercentageChart;
