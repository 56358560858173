import React from "react";
import DemoStart from "./components/demo-start/demo-start";
import { DemoProps } from "./model";
import DemoLoadDocument from "./components/demo-load-document/demo-load-document";
import DemoGenerate from "./components/demo-generate/demo-generate";

const Demo = (props: DemoProps) => {
  const { renderDemo = 1 } = props;

  const Render = () => {
    switch (renderDemo) {
      case 1:
        return <DemoStart />;
      case 2:
        return <DemoLoadDocument />;
      case 3:
        return <DemoGenerate />;
      default:
        return <DemoStart />;
    }
  };

  return <Render />;
};

export default Demo;
