import style from "../../../../style/landing-page.module.scss";
import Logo from "components/logo/fcdt-logo";
import LogoText from "assets/logo-text.webp";
import Title from "components/fcdt-title/fcdt-title";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Button from "components/buttons/fcdt-button";
import { useDispatch, useSelector } from "react-redux";
import {
  setdataFileTranscription,
  setIdRenderDemo,
} from "store/slices/landingPage";
import toast from "react-hot-toast";
import { formatFile } from "helpers/formatFile";
import { RootState } from "store/store";
import { BsUpload } from "react-icons/bs";

const DemoLoadDocument = () => {
  const dispatch = useDispatch();

  const { dataFileTranscription } = useSelector((state: RootState) => {
    return state.landingPage;
  });

  const handleChangeLoadImage = (event: any) => {
    if (event.target.files[0].size < 3000000) {
      if (formatFile(event.target.files[0].name) === "vtt") {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e: any) => {
          e.preventDefault();
        };

        dispatch(setdataFileTranscription(event.target.files));
      } else {
        toast.error(
          "Formato no permitido, cargue una transcripción en formato .vtt"
          , {duration: 7000, position:"top-center"} );
      }
    } else {
      toast.error("El archivo cargado pesa más de 3Mb", {duration: 7000, position:"top-center"});
    }
  };

  const handleLoadDocument = () => {
    if (!dataFileTranscription) {
      toast.error("No se ha cargado ningun documento", {duration: 7000, position:"top-center"});
      return;
    }
    dispatch(setIdRenderDemo(3));
  };

  return (
    <div className={style.container_demo_start_generated}>
      <Logo urlImae={LogoText} />

      <section className={style.section_input}>
        <Title fontSize={20} fontWeight={700}>
          Cargar archivo de la transcripción de la reunión
        </Title>

        <div className={style.container_demo_input}>
          <p className="fcdt-title-responsive">
            {dataFileTranscription
              ? dataFileTranscription[0].name
              : "Seleccionar Archivo VTT..."}
          </p>
          <BsUpload color="var(--secondary_color)" />

          <input
            type="file"
            accept=".vtt"
            name="load-legal-document-pdf"
            required
            onChange={(e) => handleChangeLoadImage(e)}
          />
        </div>

        <section className={style.section_play_msg}>
          {/* <img src={IconPlay} alt="Icon play" /> */}
          <Paragraph className={style.paragraph} fontSize={15}>
            El archivo VTT lo puede descargar desde Teams y Meet
          </Paragraph>
        </section>

        <Button
          className={`fcdt_btn_secondary ${style.button}`}
          onClick={handleLoadDocument}
        >
          Cargar documento de la reunión
        </Button>
      </section>
    </div>
  );
};

export default DemoLoadDocument;
