import React, { ReactNode, useEffect } from "react";
import Sidebar from "../sidebar/sidebar";
import style from "./style/layout.module.scss";
import ProfileNav from "components/profile-nav/profile-nav";
import ImageDasboard from "assets/image-dashboard.webp";
import { SIDEBAR_LINKS } from "components/sidebar/constants";
import { SideBarLink } from "components/sidebar/model";
import { useDispatch, useSelector } from "react-redux";
import { checkTokenExpiration, setCurrentPath } from "store/slices/auth";
import { RootState } from "store/store";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  children: ReactNode;
  routes?: SideBarLink[];
}
const Layout: React.FC<LayoutProps> = ({ children, routes }) => {
  const dispatch: any = useDispatch();

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const loaction = useLocation().pathname.split("/");

  useEffect(() => {
    if (!dataUser) {
      dispatch(setCurrentPath(loaction[1]));
      dispatch(checkTokenExpiration());
    }
  }, [dataUser, dispatch]);

  const tokenRefreshInterval = 5 * 60 * 1000; // 15 minutos

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkTokenExpiration());
    }, tokenRefreshInterval);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={style.container}>
      <Sidebar sideBarLink={routes ? routes : SIDEBAR_LINKS} />
      <main>
        <ProfileNav />
        {children}
        <img className={style.bars} src={ImageDasboard} alt="Detalles" />
      </main>
    </div>
  );
};

export default Layout;
