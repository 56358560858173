import NavBarKnowledge from "./components/nav-bar/nav-bar-knowledge";
import BodyKnowledgeContainer from "./components/body/body-knowledge-container";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useEffect } from "react";
import { getDependencesKnowledge } from "store/slices/knowledge";

const KnowLedgeContainer = () => {
  const dispatch: any = useDispatch();

  const { isReloadNeeded } = useSelector((state: RootState) => {
    return state.knowledge;
  });

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    if (isReloadNeeded && dataUser) {
      dispatch(getDependencesKnowledge());
    }
  }, [isReloadNeeded, dataUser]);

  return (
    <>
      <NavBarKnowledge />
      <BodyKnowledgeContainer />
    </>
  );
};

export default KnowLedgeContainer;
