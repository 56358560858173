import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Title from "components/fcdt-title/fcdt-title";
import {
  IoCalendarNumberOutline,
  IoEllipseSharp,
  IoPersonOutline,
} from "react-icons/io5";
import style from "../styles/body-knowledge.module.scss";
import { TaskKnowledge } from "pages/knowledge/model";
import { formatDate } from "helpers/formatDate";

const TasksDescription = (props: { taskSelected: TaskKnowledge }) => {
  const { taskSelected } = props;


  return (
    <div className={style.container_details_accordion}>
      <Title fontSize={18} fontWeight={600}>
        Actividad:
      </Title>
      <Paragraph fontWeight={400}>{taskSelected.goal_title}</Paragraph>
      <div className={style.container_dashed}></div>
      <Title fontSize={16} fontWeight={600}>
        Tarea:
      </Title>
      <Paragraph fontWeight={400}>{taskSelected.task_name}</Paragraph>
      <div className={style.container_dashed}></div>
      <Title fontSize={16} fontWeight={600}>
        Descripción:
      </Title>
      <Paragraph fontWeight={400}>{taskSelected.task_description}</Paragraph>
      <div className={style.container_dashed}></div>
      <Paragraph
        className={style.paragrah_container}
        fontSize={14}
        fontWeight={500}
      >
        <IoPersonOutline size={20} /> Creada por:{" "}{taskSelected.assignated_by}{" "}
      </Paragraph>

      <div className={style.container_dashed}></div>
      <Paragraph
        className={`${style.paragrah_container} ${style.paragrah_containe_start}`}
        fontSize={14}
        fontWeight={500}
      >
        <IoCalendarNumberOutline size={22} />
        <div className={style.box_dates_status}>
          <div>
            <Title fontSize={14}>
              <strong> Fecha de inicio: </strong>
              <span>{formatDate(taskSelected.task_start_date)}</span>
            </Title>
            <Title fontSize={14}>
              <strong> Fecha de vencimiento: </strong>
              <span>{formatDate(taskSelected.task_due_date)}</span>
            </Title>
            <Title fontSize={14}>
              <strong> Fecha optimista: </strong>
              <span>{formatDate(taskSelected.task_optimistic_date)}</span>
            </Title>
          </div>
          <div>
            <Title fontSize={14}>
              <strong> Estado: </strong>
              <span className={style.span_status}>
                {taskSelected.execution_status}
              </span>
            </Title>
            <Title fontSize={14}>
              <strong> Prioridad: </strong>
              <span className={style.span_priority}>
                {taskSelected.task_priority}
              </span>
            </Title>
          </div>
        </div>
      </Paragraph>



      <div className={style.container_dashed}></div>

      <Paragraph
        className={style.paragrah_container}
        fontSize={14}
        fontWeight={500}
      >
        <IoPersonOutline size={20} />
        <Title fontSize={16} fontWeight={600}>
          Subtareas
        </Title>
      </Paragraph>

      <div>
        {taskSelected.checklist ?
          taskSelected.checklist.map((item) => (
            <div className={style.list_collaborator}>
              <IoEllipseSharp className={style.icon_elipce} size={5} />
              <Paragraph
                fontWeight={300}
                fontSize={14}
                className={style.text_list_colaborator}
              >
                {item}
              </Paragraph>
            </div>
          )) : ""
        }
      </div>
      <div className={style.container_dashed}></div>
      <Paragraph
        className={style.paragrah_container}
        fontSize={14}
        fontWeight={500}
      >
        <IoPersonOutline size={20} />
        <Title fontSize={16} fontWeight={600}>
          Lista de responsables:
        </Title>
      </Paragraph>

      <div>
        {taskSelected.responsibles.map((item) => (
          <div className={style.list_collaborator}>
            <IoEllipseSharp className={style.icon_elipce} size={5} />
            <Paragraph fontWeight={300} fontSize={14}>
              {item}
            </Paragraph>
          </div>
        ))}
      </div>

    </div>
  );
};

export default TasksDescription;
