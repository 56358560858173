// CalendarComponent.tsx
import React from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import FCDTModal from "components/modal-notification/fcdt-modal";
import {
  getDataMetetingsById,
  setOpenModalHours,
  setSelectEvent,
  setshowModalDescriptionMeeting,
} from "pages/calendar/store/slices/calendar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { MyEvent } from "../calendar/model";

import { es } from "date-fns/locale";

const locales = {
  es: es,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1, locale: es }),
  getDay,
  locales,
});

const CalendarComponent: React.FC = () => {
  const dispatch: any = useDispatch();

  const { showModalHours, events, dateSelected } = useSelector(
    (state: RootState) => {
      return state.calendar.calendar;
    }
  );

  const handleSelectEvent = (event: MyEvent) => {
    dispatch(setSelectEvent(event));
    dispatch(setOpenModalHours(false));
    dispatch(setshowModalDescriptionMeeting(true));
    dispatch(getDataMetetingsById());
  };

  const handleCloseModal = () => {
    dispatch(setOpenModalHours(false));
  };
  const messages = {
    today: "Hoy",
    previous: "Atrás",
    next: "Siguiente",
    month: "Mes",
    week: "Semana",
    day: "Día",
    agenda: "Agenda",
    date: "Fecha",
    time: "Hora",
    event: "Evento",
    noEventsInRange: "No hay eventos en este rango.",
  };

  const children = (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        selectable
        onSelectEvent={handleSelectEvent}
        defaultView={Views.DAY}
        date={dateSelected}
        style={{
          height: 500,
          width: 900,
          fontFamily: "Poppins",
          marginInline: "20px",
          fontSize: "16px",
        }}
        eventPropGetter={() => {
          return {
            style: {

              fontFamily: "Poppins",
              backgroundColor: "#2F0084",  // Cambia el color de fondo para todos los eventos
              borderRadius: '5px',       // Bordes redondeados
              opacity: 0.8,              // Opacidad del evento
              color: 'white',            // Color del texto del evento
              border: '1px solid black'  // Borde de los eventos
            }
          };
        }}
        messages={messages}  // Aquí aplicas los mensajes personalizados
        formats={{
          dateFormat: 'dd MMMM', // Formato de día y mes (e.g. 13 septiembre)
          dayFormat: 'eeee, dd MMMM', // Formato para el encabezado con día de la semana (e.g. viernes, 13 septiembre)
          dayHeaderFormat: (
            (date, culture, localizer) => {
              // Comprueba si localizer está definido antes de formatear la fecha
              if (localizer) {
                return localizer.format(date, 'eeee dd MMMM', culture); // Muestra día con formato (e.g. viernes 13 septiembre)
              }
              return ''; // Retorna una cadena vacía si localizer es indefinido
            })
        }}
      />
    </div>
  );

  return (
    <FCDTModal
      open={showModalHours}
      children={children}
      handleClose={handleCloseModal}
    />
  );
};

export default CalendarComponent;
