// src/MultiSelect.js
import { useEffect, useRef, useState } from "react";
import { DataMultiSelect, MultiSelectProps } from "./model";
import style from "./style/fcdt-multiselect.module.scss";

const MultiSelect = (props: MultiSelectProps) => {
  const { data, label, onChange, notMultipleSelecton, selectFirstItem } = props;

  const dataBeggining = selectFirstItem ? [data[0]] : [];

  const [selectedOptions, setSelectedOptions] =
    useState<DataMultiSelect[]>(dataBeggining);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (data.length > 0 && selectFirstItem) {
      setSelectedOptions([data[0]]);
    }
  }, [data, selectFirstItem]);

  const toggleOption = (option: DataMultiSelect) => {
    if (option.uid === "-1") {
      if (selectedOptions.length === data.length) {
        setSelectedOptions([]);
        onChange && onChange([]);
        return;
      }
      setSelectedOptions(data);
      onChange && onChange(data);
      return;
    }
    if (selectedOptions.includes(option)) {
      if (notMultipleSelecton) {
        onChange && onChange([option]);
        setSelectedOptions([option]);
      } else {
        onChange && onChange(selectedOptions.filter((item) => item !== option));
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      }
    } else {
      if (notMultipleSelecton) {
        onChange && onChange([option]);
        setSelectedOptions([option]);
      } else {
        onChange && onChange([...selectedOptions, option]);
        setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Position

  return (
    <div ref={ref} className={style.multi_select}>
      <div className={style.select_box} onClick={() => setIsOpen(!isOpen)}>
        <span className="fcdt-title-responsive">{label}</span>
        <span className={`${style.arrow} ${isOpen ? style.open : ""}`}></span>
      </div>
      {isOpen && (
        <div className={style.options_container}>
          {data.length > 0 ? (
            data.map((option) => (
              <label key={option.uid} className={style.option}>
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => toggleOption(option)}
                />
                <span className={style.checkmark}></span>
                {option.label}
              </label>
            ))
          ) : (
            <span>Sin datos</span>
          )}
        </div>
      )}
    </div>
  );
};
export default MultiSelect;
