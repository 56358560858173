import React from "react";
import Contact from "./contact";
import { ComponentsLandingProps, FormContact } from "pages/landing-page/model";
import { useForm } from "react-hook-form";
import { sendEmailContact } from "store/slices/landingPage";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const ContactContainer = (props: ComponentsLandingProps) => {
  const dispatch: any = useDispatch();

  const { register, handleSubmit, reset } = useForm<FormContact>();

  const onSubmit = async (values: FormContact) => {
    const res = await dispatch(sendEmailContact(values));
    if (!res?.ok) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    reset();
    toast.success(res?.msg, {duration: 7000, position:"top-center"});
  };

  return (
    <div id={props.id}>
      <Contact
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default ContactContainer;
