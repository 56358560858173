import { useForm } from "react-hook-form";
import Button from "components/buttons/fcdt-button";
import ImagePasswordReset from "assets/img-password-reset.webp";
import style from "../style/passwordreset.module.scss";
import { Errors } from "components/errors/errors";
import { EmailReset } from "../model";
import { useDispatch } from "react-redux";
import { passwordReset, setOpenPasswordReset } from "store/slices/login";
import toast from "react-hot-toast";
export const PasswordReset = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailReset>();
  const dispatch = useDispatch<any>();
  const onSubmit = async (data: EmailReset) => {
    const response = await dispatch(passwordReset(data));
    if (!response?.ok) {
      toast.error(response.msg, {duration: 7000, position:"top-center"});
      return;
    }
    toast.loading("Enviando correo electrónico...");
    setTimeout(() => {
      dispatch(setOpenPasswordReset(false));
      toast.success(response?.msg, {duration: 7000, position:"top-center"});
    }, 2500);
  };
  return (
    <div className={style.password_reset_container}>
      <img src={ImagePasswordReset} alt="Restablecimiento de contraseña" />
      <label>
        Introduce tu correo electrónico registrado a continuación para recibir
        las instrucciones de restablecimiento de contraseña.
      </label>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          className="fcdt-input"
          type="email"
          placeholder="Correo electrónico"
          {...register("email", { required: true })}
        />
        <Errors name={errors.email} />
        <Button children="Enviar" type="submit" />
      </form>
    </div>
  );
};
