import axios from "axios";
// import { APIURL, PERSONS_PORT } from "config/APIURL";
import { APIURL } from "config/APIURL";


export const getUsers = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/usuarios/get_all_users`,
      headers: {
        "x-token": token,
      },
    });
    return response?.data;
  } catch (error: any) {
    return error.response.data;
  }
};
