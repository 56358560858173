import React, { useEffect } from "react";
import UsersSuperAdmin from "./users";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { getUsers } from "pages/superAdmin/store/slices/users";
import UseDataFilter from "common/hooks/use-data-filter";
import usePagination from "common/hooks/use-pagination";
import { NUMBER_ITEMS_PAGINATION } from "common/contants";
import ResponsivePagination from "react-responsive-pagination";

const UsersSuperAdminContainer = () => {
  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });
  const value = filterParams?.search;
  const searchTerm = value ? value : "";

  const dispatch: any = useDispatch();

  const { dataUserTable, isReloadNeeded, isFeching } = useSelector(
    (state: RootState) => {
      return state.superAdmin.users;
    }
  );

  const { dataUser, dataSuperAdmin } = useSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    if (!isFeching && isReloadNeeded && (dataUser || dataSuperAdmin)) {
      dispatch(getUsers());
    }
  }, [isFeching, isReloadNeeded, dataUser, dataSuperAdmin, dispatch]);

  const { filtered } = UseDataFilter({
    data: dataUserTable,
    searchValue: searchTerm,
  });

  const { currentPage, totalPages, itemsToShow, setCurrentPage } =
    usePagination({
      currentPage: 1,
      filterData: filtered,
      numberItems: NUMBER_ITEMS_PAGINATION,
    });

  return (
    <div className="fcdt-center">
      <UsersSuperAdmin users={itemsToShow} />
      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        maxWidth={100}
      />
    </div>
  );
};

export default UsersSuperAdminContainer;
