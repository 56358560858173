import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Title from "components/fcdt-title/fcdt-title";
import {
  IoCalendarNumberOutline,
  IoCheckmarkCircle,
  IoDocumentOutline,
  IoPersonOutline,
} from "react-icons/io5";
import style from "../styles/body-knowledge.module.scss";
import Button from "components/buttons/fcdt-button";
import { MeetingKnowledge } from "pages/knowledge/model";
import { formatDate } from "helpers/formatDate";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentById,
  setIsModalViewDocument,
} from "pages/document-management/store/slices/documents";
import ModalViewMinute from "pages/landing-page/components/demo/components/modal-view-document/modal-view-minute";
import { RootState } from "store/store";
import toast from "react-hot-toast";
import { setIsModalSpeechTree } from "store/slices/landingPage";
import ModalSpeechTreeContainer from "pages/landing-page/components/demo/components/modal-speech-tree/modal-speech-tree-container";
import { getSpeechTreeByIdMeeting } from "store/slices/knowledge";

const MeetingDescription = (props: { meetingSelected: MeetingKnowledge }) => {
  const { meetingSelected } = props;

  const dispatch: any = useDispatch();

  const handleClickGeneratedMinute = async (idMeeting: string) => {
    const res = await dispatch(getDocumentById(idMeeting));
    if (!res) {
      toast.error("No se pudo generar el acta, intentelo más tarde", { duration: 7000, position: "top-center" });
      return;
    }
    dispatch(setIsModalViewDocument(true));
  };

  if (!meetingSelected.id_minute) {
    toast.error("Aún no se cuenta con recursos de la reunión.", { duration: 4000, position: "top-center" });
  }

  const handleCloseModal = (service: number) => {
    if (service === 1) {
      dispatch(setIsModalViewDocument(false));
    } else {
      dispatch(setIsModalSpeechTree(false));
    }
  };

  const { minutesContentSelected, isModalViewDocument } = useSelector(
    (state: RootState) => {
      return state.documentsManagement.documents;
    }
  );

  const handleClickGeneratedSpeechTree = async (idMeeting: string) => {
    const res = await dispatch(getSpeechTreeByIdMeeting(idMeeting));
    if (!res) return;

    dispatch(setIsModalSpeechTree(true));
  };

  return (
    <div className={style.container_details_accordion}>
      <Title fontSize={18}>{meetingSelected.meeting_name}</Title>
      <Paragraph
        className={style.paragrah_container}
        fontSize={14}
        fontWeight={300}
      >
        <IoCalendarNumberOutline size={20} />{" "}
        {formatDate(meetingSelected.meeting_start_date)} a{" "}
        {formatDate(meetingSelected.meeting_end_date)}
      </Paragraph>
      <Paragraph
        className={style.paragrah_container}
        fontSize={14}
        fontWeight={500}
      >
        <IoPersonOutline size={20} /> Creada por {meetingSelected.created_by}{" "}
      </Paragraph>

      <Paragraph fontSize={14} fontWeight={300}>
        {meetingSelected.meeting_description}
      </Paragraph>
      {/* <div className={style.container_dashed}></div>
      <Paragraph
        className={style.paragrah_container}
        fontSize={14}
        fontWeight={500}
      >
        <IoPersonOutline size={20} />
        <Title fontSize={16} fontWeight={600}>
          Lista de asistentes
        </Title>
      </Paragraph>

      <div>
        {meetingSelected.collaborators ?
          meetingSelected.collaborators.map((item, index) => (
            <div key={index} className={style.list_collaborator}>
              ///* {item.accept ? (
              //<IoCheckmarkCircle className={style.icon_checkmark} size={22} />
            //) : (
              //<IoCloseCircle className={style.icon_close} size={22} />
            //)} *}

              <IoCheckmarkCircle className={style.icon_checkmark} size={22} />
              <Paragraph fontWeight={300} fontSize={14}>
                {item}
              </Paragraph>
            </div>
          )) : ""
        }
      </div> */}
      {/* <div className={style.container_dashed}></div> */}

      <Paragraph
        className={style.paragrah_container}
        fontSize={14}
        fontWeight={500}
      >
        <IoPersonOutline size={20} />

        <Title fontSize={16} fontWeight={600}>
          Revisor de acta:
        </Title>
        <Paragraph fontWeight={600}>
          {meetingSelected.minute_reviewer}
        </Paragraph>
      </Paragraph>

      <Paragraph
        className={`${style.paragrah_container} ${style.paragrah_containe_start}`}
        fontSize={14}
        fontWeight={500}
      >
        <IoDocumentOutline size={20} />
        <div>
          <Title fontSize={16} fontWeight={600}>
            Reunión perteneciente a la actividad:
          </Title>
          <Paragraph fontWeight={400}>{meetingSelected.goal_title}</Paragraph>
        </div>
      </Paragraph>

      <div className={style.container_buttons}>
        <Button
          className="fcdt-btn_update"
          onClick={() => handleClickGeneratedMinute(meetingSelected.id_minute)}
          disabled={meetingSelected.id_minute ? false : true}
        >
          Ver acta de reunión
        </Button>
        <Button
          className="fcdt-btn_update"
          onClick={() =>
            handleClickGeneratedSpeechTree(meetingSelected.id_meeting)

          }
          disabled={meetingSelected.id_minute ? false : true}
        >
          Ver árbol de discurso
        </Button>
      </div>

      <ModalViewMinute
        open={isModalViewDocument}
        handleCloseModal={() => handleCloseModal(1)}
        data={minutesContentSelected}
      />

      <ModalSpeechTreeContainer />
    </div>
  );
};

export default MeetingDescription;
