import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { PlanManager } from "./plan-manager";
import {
  getPlansFuncionalities,
  setOpenNewPlan,
  setSelectedPlan,
  setUpdatePlan,
} from "store/slices/superAdmin";
import { useEffect } from "react";
import { Plan } from "./model";

export const PlanManagerContainer = () => {
  const dispatch = useDispatch<any>();

  const { filterParams } = useSelector((state: RootState) => {
    return state.filter;
  });
  const { plans, isReloadNeeded } = useSelector((state: RootState) => {
    return state.superadmin;
  });

  const { dataSuperAdmin } = useSelector((state: RootState) => {
    return state.auth;
  });

  const value = filterParams?.search;

  useEffect(() => {
    if (dataSuperAdmin && isReloadNeeded) {
      dispatch(getPlansFuncionalities());
    }
  }, [dataSuperAdmin, isReloadNeeded]);

  const handleUpdatePlan = (plan: Plan) => {
    dispatch(setOpenNewPlan(true));
    dispatch(setSelectedPlan(plan));
    dispatch(setUpdatePlan(true));
  };

  const normalizeAndCompare = (
    text: string | undefined,
    searchTerm: string
  ): boolean => {
    const normalizedText = text
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const normalizedSearchTerm = searchTerm
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return (
      normalizedText?.includes(normalizedSearchTerm) ||
      normalizedText === normalizedSearchTerm
    );
  };

  const filtered =
    plans && plans?.length > 0
      ? plans?.filter((item) => {
          const searchTerm = value ? value : "";
          const planMatch = normalizeAndCompare(
            item?.plan.plan_name,
            searchTerm
          );
          const stateMatch = normalizeAndCompare(
            item?.plan?.plan_status ? "Activo" : "Desactivado",
            searchTerm
          );
          // const dateMatch = normalizeAndCompare(item?.date, searchTerm);
          const priceMatch = normalizeAndCompare(
            item?.plan?.plan_price,
            searchTerm
          );

          return planMatch || stateMatch || priceMatch;
        })
      : [];

  return <PlanManager updatePlan={handleUpdatePlan} plans={filtered} />;
};
