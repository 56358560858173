import { useState } from "react";
import Title from "components/fcdt-title/fcdt-title";
import style from "./style/login.module.scss";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Button from "components/buttons/fcdt-button";
import { LoginProps } from "./model";
import Link from "components/fcdt-link/fcdt-link";
import Dialog from "components/modals/dialog";
import { PasswordReset } from "components/modals/components/password-reset";
import ReCAPTCHA from "react-google-recaptcha";
import { APIKEY_RECAPCHA } from "./constants";
import { Errors } from "components/errors/errors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { setOpenPasswordReset } from "store/slices/login";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";

const Login = (props: LoginProps) => {
  const navigate = useNavigate();

  const { register, handleSubmit, onSubmit, errors } = props;
  const dispatch = useDispatch<any>();
  const { isOpenPasswordReset } = useSelector((state: RootState) => {
    return state.login;
  });
  const [isCaptcha, setIsCaptcha] = useState<any>("");

  const [showPassword, setshowPassword] = useState(false);

  const handleOpenDialog = () => {
    dispatch(setOpenPasswordReset(true));
  };

  const handleCloseDialog = () => {
    dispatch(setOpenPasswordReset(false));
  };

  return (
    <>
      <Dialog isOpen={isOpenPasswordReset} onClose={handleCloseDialog}>
        <PasswordReset />
      </Dialog>

      <div className={style.container_login}>
        <section className={style.container_title_form}>
          <Title
            children="Debes registrarte para unirte"
            fontWeight={700}
            fontSize={25}
          />
          <Paragraph
            children="Somos un equipo que nos guiamos mutuamente"
            className={style.paragrap}
            fontWeight={300}
          />

          <br />

          <form
            className={style.container_form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="email"
              className="fcdt-input"
              placeholder="Correo"
              required
              maxLength={90}
              minLength={6}
              pattern={REG_EXP.EMAIL}
              title={TITLE_REG_EXP.EMAIL}
              {...register("email")}
            />
            <Errors name={errors.email} />
            <div className="fcdt-input-box-password">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Contraseña"
                required
                minLength={8}
                maxLength={50}
                pattern={REG_EXP.PASSWORD}
                title={TITLE_REG_EXP.PASSWORD}
                {...register("password")}
              />
              <div onClick={() => setshowPassword(!showPassword)}>
                {showPassword ? (
                  <IoEyeOutline size={27} className="fcdt-icon-gray" />
                ) : (
                  <IoEyeOffOutline size={27} className="fcdt-icon-gray" />
                )}
              </div>
            </div>
            <Errors name={errors.password} />

            <div className={style.container_link}>
              <Link
                children="Recuperar contraseña"
                color="#606263"
                fontWeight={300}
                fontSize={15}
                onClick={handleOpenDialog}
              />
            </div>
            <ReCAPTCHA
              sitekey={APIKEY_RECAPCHA}
              onChange={(value) => setIsCaptcha(value)}
            />
            {/* {isCaptcha === '' && <p className="message_error">El captcha es requerido</p>} */}
            <div className={style.button_container}>
              <Button
                children="Atrás"
                type="button"
                className="fcdt_btn_without_background_primary"
                onClick={() => navigate(ROUTES.HOME)}
              />
              <Button
                children="Continuar"
                type="submit"
                disabled={isCaptcha === "" ? false : false}
              />
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default Login;
