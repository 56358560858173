import FCDTModal from "components/modal-notification/fcdt-modal";
import style from "./style/modal-recomendation.module.scss";
import Title from "components/fcdt-title/fcdt-title";

import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import { ModalRecomendations } from "pages/follow-up/models";
import Logo from "components/logo/fcdt-logo";
import { IoDownloadOutline } from "react-icons/io5";

const ModalRecomendation = (props: ModalRecomendations) => {
  const { open, data, handleCloseModal, handleDownload } = props;

  const arrayKeys = Object.keys(data);

  const children = (
    <div className={style.container_principal}>
      <Paragraph className={style.paragrahp_download}>
        <div className={style.div} onClick={handleDownload}>
          <IoDownloadOutline size={25} /> Descargar
        </div>
      </Paragraph>

      <Logo className={style.container_logo} />

      <Title className={style.title_recomendation}>Recomendaciones</Title>

      {arrayKeys.map((item: string) => {
        return (
          <section className={style.section_paragraph}>
            <Paragraph className={style.paragrahp}>
              <strong>{eval(`data.${item}.section_title`)}</strong>{" "}
              <p>{eval(`data.${item}.recommendation_text`)}</p>
            </Paragraph>
          </section>
        );
      })}

      <section className={style.section_paragraph}>
        <Paragraph className={style.color_paragraph}>
          ¿Quieres tomar decisiones rápidamente acompañada con expertos? En
          Expertic, te ayudamos con la incertidumbre de tus decisiones. Haz clic
          en Ingresa{" "}
          <a href="http://creatic-expertic.com" target="_blank">
            aquí
          </a>{" "}
          para descubrir más.
        </Paragraph>
      </section>
      {/*       
      <section className={style.section_btn}>
        <Button
          className="fcdt_btn_without_background_primary"
          onClick={handleCloseModal}
        >
          Atrás
        </Button>
        <Button onClick={handleCloseModal}>Continuar</Button>
      </section> */}
    </div>
  );

  return (
    <FCDTModal
      open={open}
      children={children}
      handleClose={handleCloseModal}
      width="47%"
      heigth="90%"
    />
  );
};

export default ModalRecomendation;
