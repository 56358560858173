import { ModalDeleteTaskProps } from "../../model";
import FCDTModal from "components/modal-notification/fcdt-modal";
import Button from "components/buttons/fcdt-button";
import style from "./style/modal-delete-task.module.scss";

const ModalDeleteTask = (props: ModalDeleteTaskProps) => {
  const { handleCloseModal, register, handleSubmit, onSubmit, isEditTask } =
    props;

  const children = (
    <form className={style.process_container} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.content}>
        <h1> {`Motivo de ${isEditTask ? "actualización" : "cancelación"}`}</h1>

        <div className={style.content_left}>
          <textarea
            placeholder={`Describa el motivo de ${
              isEditTask ? "actualización" : "cancelación"
            }`}
            pattern="^(?!.*(\d){5,})[a-zA-Z0-9áéíóúÁÉÍÓÚüÜ.:%!¡¿'?ñÑ,\s]*$"
            required
            {...register("description")}
          ></textarea>
        </div>
      </div>
      <div className={style.content_buttons}>
        <Button
          className="btn_cancel"
          children="Cancelar"
          width="40"
          type="button"
          onClick={handleCloseModal}
        />
        <Button children="Guardar" width="40" type="submit" />
      </div>
    </form>
  );

  return (
    <FCDTModal open={true} children={children} handleClose={handleCloseModal} />
  );
};

export default ModalDeleteTask;
