import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceArea,
  ReferenceLine,
} from "recharts";
import style from "./styles/scatter-chart.module.scss";
import Title from "components/fcdt-title/fcdt-title";

interface DataPoint {
  date: number;
  days: number;
}

export interface DataScatter {
  date: string;
  days: number;
}

type ScatterChartProps = {
  data: DataScatter[];
  asymptote: number;
};

const CustomDot = (props: any) => {
  const { cx, cy } = props;
  const dotSize = 3; // Tamaño del punto, puedes ajustarlo

  return (
    <circle
      cx={cx}
      cy={cy}
      r={dotSize}
      stroke="#2F0084"
      strokeWidth={1}
      fill="#2F0084"
    />
  );
};

const CustomScatterChart = (props: ScatterChartProps) => {
  const data: DataPoint[] = props.data.map((item) => {
    return { date: new Date(item.date).getTime(), days: item.days };
  });

  const maxDays = Math.max(...data.map((d) => d.days));

  const xAxisDomain: [number, number] = [
    new Date("2024-07-19").getTime(),
    new Date("2024-07-23").getTime(),
  ];
  const yAxisDomain: [number, number] = [0, maxDays + 2];

  const asymptote = props.asymptote;

  const formatDate = (date: number): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const children = (
    <ResponsiveContainer width="100%" height={200}>
      <ScatterChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          name="Fecha"
          tickFormatter={formatDate}
          type="number"
          domain={xAxisDomain}
          tick={{ fontSize: 12, fill: "#898989" }}
          axisLine={{ stroke: "#898989" }}
        />
        <YAxis
          dataKey="days"
          name="Días"
          domain={yAxisDomain}
          tick={{ fontSize: 12, fill: "#898989" }}
          axisLine={{ stroke: "#898989" }}
        />

        <ReferenceArea y1={0} y2={asymptote} fill="rgba(0, 222, 151, 0.2)" />

        <ReferenceArea
          y1={asymptote}
          y2={maxDays + 2}
          fill="rgba(238, 53, 55, 0.2)"
        />

        <ReferenceLine
          y={asymptote}
          stroke="rgba(137, 137, 137, 1)"
          strokeDasharray="4 4"
          strokeWidth={2}
        />

        <Scatter data={data} shape={CustomDot} />
      </ScatterChart>
    </ResponsiveContainer>
  );

  return (
    <div className={style.container_principal}>
      <Paragraph fontSize={13} fontWeight={400}>
        Comparación entre tiempos iniciales de entrega y tiempos reales (riesgos
        de incumplimieto)
      </Paragraph>
      <div className={style.container_legend}>
        <section className={style.container_box_text}>
          <div
            className={style.box}
            style={{ background: "#2F0084", borderRadius: "100%" }}
          ></div>{" "}
          <span>
            <Title fontSize={12} fontWeight={300}>
              Tareas
            </Title>
          </span>
        </section>
        <section className={style.container_box_text}>
          <div className={style.box} style={{ background: "#FCD7D7" }}></div>{" "}
          <span>
            <Title fontSize={12} fontWeight={300}>
              Entrega fuera de tiempo
            </Title>
          </span>
        </section>
        <section className={style.container_box_text}>
          <div className={style.box} style={{ background: "#CCF8EA" }}></div>{" "}
          <span>
            <Title fontSize={12} fontWeight={300}>
              Entrega en tiempo esperado
            </Title>
          </span>
        </section>
      </div>

      <div className={style.container_chart}>
        <Title fontSize={12} className={style.text_y_axis}>
          Número de días de entrega
        </Title>

        <div className={style.box_chart}>
          {children}
          <Title fontSize={12} className={style.text_x_axis}>
            Fecha de asignación
          </Title>
        </div>
      </div>
    </div>
  );
};

export default CustomScatterChart;
