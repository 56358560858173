import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActualIdFormDocument,
  setDataFirstFormDocument,
} from "pages/document-management/store/slices/documents";
import { useForm } from "react-hook-form";
import { ValuesFirstFormDocument } from "../model";
import FirstFormDocument from "./first-form-document";
import { RootState } from "store/store";

const FirstFormDocumentContainer = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const { dataFirstFormDocument } = useSelector((state: RootState) => {
    return state.documentsManagement.documents;
  });

  const { register, handleSubmit, setValue } =
    useForm<ValuesFirstFormDocument>();

  useEffect(() => {
    if (dataFirstFormDocument) {
      setValue("minuteName", dataFirstFormDocument.minuteName);
      setValue("date", dataFirstFormDocument.date);
      setValue("minuteCreated", dataFirstFormDocument.minuteCreated);
      setValue("minuteDiscution", dataFirstFormDocument.minuteDiscution);
    }
  }, [dataFirstFormDocument, setValue]);

  const handleNavigate = (id: number) => {
    if (id === 1) {
      navigate(-1);
      return;
    }

    dispatch(setActualIdFormDocument(2));
  };

  const onSubmit = (values: ValuesFirstFormDocument) => {
    dispatch(setDataFirstFormDocument(values));
  };

  return (
    <FirstFormDocument
      handleNavigate={handleNavigate}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};

export default FirstFormDocumentContainer;
