import { createSlice } from "@reduxjs/toolkit";
import { Datauser, FormLoginType } from "pages/login/model";
import { AppThunk } from "store/store";
import * as API from "pages/login/api/api";
import {
  passwordReset as passwordResetAPI,
  updatePassword as updatePasswordAPI,
} from "components/modals/api/api";
import { EmailReset } from "components/modals/model";
import { displayLoader, removeProcess } from "./loading";
const authenticatedUser = localStorage.getItem("authenticatedUser");
type InitialState = {
  dataUser?: Datauser;
  isOpenPasswordReset?: boolean;
  isOpenNewPassword?: boolean;
};
const initialState: InitialState = {
  dataUser: authenticatedUser ? JSON.parse(authenticatedUser) : "",
  isOpenPasswordReset: false,
  isOpenNewPassword: false,
};

export const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setDataUser: (state, action) => {
      state.dataUser = action.payload;
    },
    setOpenPasswordReset: (state, action) => {
      state.isOpenPasswordReset = action.payload;
    },
    setOpenNewPassword: (state, action) => {
      state.isOpenNewPassword = action.payload;
    },
  },
});
export const { setOpenPasswordReset, setOpenNewPassword } = slice.actions;

export const loginRequestPost =
  (values: FormLoginType): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response: any = await API.loginRequestPost(values);
      localStorage.setItem("token", response.token);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
    }
  };

export const passwordReset =
  (email: EmailReset): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response: any = await passwordResetAPI(email);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      console.log({ error });
    }
  };

export const updatePassword =
  (token: string, password: string): AppThunk =>
  async (dispatch) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const response: any = await updatePasswordAPI(token, password);
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error: any) {
      console.log(error);
      dispatch(removeProcess(idProcess));
      return error.response.data;
    }
  };

export default slice.reducer;
