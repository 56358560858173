import ModalViewDocument from "./modal-view-document";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadDocument,
  setDocumentByIdSucess,
  setIsModalViewDocument,
} from "pages/document-management/store/slices/documents";
import { RootState } from "store/store";

const ModalViewDocumentContainer = () => {
  const dispatch: any = useDispatch();

  const { isModalViewDocument, minutesContentSelected } = useSelector(
    (state: RootState) => {
      return state.documentsManagement.documents;
    }
  );

  const handleCloseModal = () => {
    dispatch(setIsModalViewDocument(false));
    dispatch(setDocumentByIdSucess(undefined));
  };

  const handleDownload = () => {
    dispatch(setIsModalViewDocument(false));
    dispatch(downloadDocument());
  };

  if (!minutesContentSelected) return null;

  if (!minutesContentSelected.associated_meeting) return null;

  return (
    <ModalViewDocument
      open={isModalViewDocument}
      handleCloseModal={handleCloseModal}
      handleDownload={handleDownload}
      data={minutesContentSelected}
    />
  );
};

export default ModalViewDocumentContainer;
