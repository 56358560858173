import React from "react";
import Layout from "components/layout/Layout";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "routes/constants";
import CalendarContainer from "../components/calendar/calendar-container";
import CreateEventContainer from "../components/create-event/create-event-container";
import { SideBarLink } from "components/sidebar/model";

const CalendarRoutes = (props: { routes: SideBarLink[] }) => {
  const { EVENTS, CREATE_EVENT, EDIT_EVENT } = ROUTES.CALENDAR.CHILDRENS;

  return (
    <Routes>
      <Route
        path={EVENTS}
        element={
          <Layout routes={props.routes}>
            <CalendarContainer />
          </Layout>
        }
      />

      <Route
        path={EVENTS + "/" + CREATE_EVENT}
        element={
          <Layout routes={props.routes}>
            <CreateEventContainer />
          </Layout>
        }
      />

      <Route
        path={EVENTS + "/" + EDIT_EVENT}
        element={
          <Layout routes={props.routes}>
            <CreateEventContainer />
          </Layout>
        }
      />
    </Routes>
  );
};

export default CalendarRoutes;
