import React from "react";
import style from "./style/card-suscription.module.scss";
import Button from "components/buttons/fcdt-button";
import { CardSuscriptionProps } from "./model";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Basic from "assets/basico.svg";
import Company from "assets/empresas.svg";
import Premiun from "assets/profesional.svg";
import { ROUTES } from "routes/constants";
const CardSuscription = ({ plan }: CardSuscriptionProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  let planPrice = parseFloat(plan.plan.plan_price).toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 0 });


  return (
    <article className={style.card_sucription}>
      <header>
        {plan?.plan.id_plan === 2 && (
          <span className={style.recommendation}>Plan recomendado</span>
        )}

        <img
          src={
            plan.plan.id_plan === 1
              ? Basic
              : plan.plan.id_plan === 2
              ? Premiun
              : Company
          }
          alt="Ilustración del plan"
        />
      </header>
      <div className={style.suscription_content}>
        <h1>{plan.plan.plan_name}</h1>
        <p>
          ${planPrice} 
          <span> COP /MES</span>
        </p>
        <ul>
          {plan.functionalitiesPlan?.map((funcionality, index) => (
            <li key={index}>{funcionality.credit_quantities} {funcionality.functionality_name}</li>
            
          ))}
        </ul>
      </div>
      <footer>
        {location.pathname === "/" ? (
          <Button
            onClick={() =>
              navigate(ROUTES.REGISTER_COMPANY)
            }
          >
            Seleccionar
          </Button>
        ) : (
          <Link
            className="fcdt-link-to"
            to={`${plan.plan.id_plan}/pago`}
          >
            <Button onClick={() => navigate(ROUTES.REGISTER_COMPANY)}>
              Seleccionar
            </Button>
          </Link>
        )}
      </footer>
    </article>
  );
};

export default CardSuscription;
